
.content-tabs {
    margin: rem-calc(20) 0;
    // Nav
    .tabs {
        display: block;
        ul {
            display: flex;
            border-bottom: rem-calc(3) solid $c-ielts-red;
            padding: 0;
            flex-direction: column;
        }
        
        li {
            display: flex;
            position: relative;
            justify-content: space-between;
            flex: 1 1 auto;
            a {
                display: block;
                background-color: $c-ielts-black;
                width: 100%;
                font-size: rem-calc(14);
                text-align: left;
                color: $c-white;
                padding: 0.5rem 1rem;

                &.is-active {
                    background-color: $c-ielts-red;
                    &:hover {
                        cursor: default;
                    }
                }

                &:hover,
                &:focus {
                    background-color: $c-ielts-red;
                }
            }

            &:last-child {
                border-right-width: 0;
            }
        }
    }

    // Content
    .item-content {
        opacity: 0;
        visibility: hidden;
        height: 0;
        overflow: hidden;
    }

    .item {
        min-height: 0;
        &:before,
        &:after {
            display: none;
        }

        &.is-active {
            .item-content {
                opacity: 1;
                visibility: visible;
                height: auto;
                transition: all .5s linear;
            }
        }
    }
    
    &.mobile-accordion {
        // Nav
        .tabs {
            display: none;
        }

        //content
        .item {
            min-height: 2rem;
            position: relative;
            &:before {
                content: attr(data-title);
                background-color: $c-ielts-black;
                color: $c-white;
                width: 100%;
                display: block;
                padding: 0.5rem 1rem;
            }

            &:after {
                content: '\f078';
                font-family: FontAwesome;
                position: absolute;
                top: rem-calc(6);
                right: rem-calc(6);
                width: rem-calc(20);
                height: rem-calc(18);
                display: block;
                color: $c-white;
            }
            
            &.is-active {
                &:before {
                    background-color: $c-ielts-red;
                    margin-bottom: rem-calc(12);
                }
            }
        }
    }

    @include media-breakpoint-up(sm) {
        // Nav
        .tabs {
            li {
                border-right: rem-calc(1) solid $c-white;
                a {
                    padding: 0.5rem;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .tabs {
            display: block;
            ul {
                display: flex;
                border-bottom: rem-calc(3) solid $c-ielts-red;
                padding: 0;
                flex-direction: row;
            }
            
            li {
                display: flex;
                position: relative;
                justify-content: space-between;
                border-right: rem-calc(1) solid $c-white;
                flex: 1 1 auto;
                a {
                    display: block;
                    background-color: $c-ielts-black;
                    width: 100%;
                    font-size: rem-calc(14);
                    text-align: left;
                    color: $c-white;
                    padding: 0.5rem 1rem;

                    &.is-active {
                        background-color: $c-ielts-red;
                        &:hover {
                            cursor: default;
                        }
                    }

                    &:hover,
                    &:focus {
                        background-color: $c-ielts-red;
                    }
                }

                &:last-child {
                    border-right-width: 0;
                }
            }
        }

        // Content
        .item {
            min-height: 0;
            &:before,
            &:after {
                display: none;
            }
        } 

        &.mobile-accordion {
            // Nav
            .tabs {
                display: block;
            }

            .item {
                min-height: 0;
                &:before,
                &:after {
                    display: none;
                }
            } 
            
        }
    }

    @include media-breakpoint-up(lg) {
       
    }
}