.find-test-location__breadcrumb {
    margin-bottom: rem-calc(25);
    ul {
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0;
        @include media-breakpoint-down(sm) {
            position: relative;
        }
    }

    li {
        font-size: rem-calc(14);
        background-color: $c-ielts-grey2;
        font-weight: bold;
        position: relative;
        border: 1px solid $c-ielts-grey;

        @include media-breakpoint-down(sm) {
            flex: 1;
            margin-top: 30px;
        }
        
        a {
            color: $c-ielts-grey;
            padding: rem-calc(6) rem-calc(12) rem-calc(6) rem-calc(30) ;
            display: block;
        }

        &:first-child {
            a {
                padding-left: rem-calc(12);
            }
        }
        
        &:before,
        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            z-index: 1;
        }
        // forth
        &:before {
            border-width: rem-calc(18) 0 rem-calc(18) rem-calc(18);
            border-color: transparent transparent transparent $c-ielts-grey;
            right: rem-calc(-23);
            top: rem-calc(-2);
        }

        // first
        &:after {
            border-width: rem-calc(16) 0 rem-calc(16) rem-calc(16);
            border-color: transparent transparent transparent $c-ielts-grey2;
            right: rem-calc(-16);
            top: 0;
        }

        @include media-breakpoint-down(sm) {
            &:before,
            &:after {
                border-width: 0;
            }
        }

        a {
            &:before,
            &:after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                z-index: 1;
            }
            // third
            &:before {
                border-width: rem-calc(22) 0 rem-calc(22) rem-calc(22);
                border-color: transparent transparent transparent $c-white;
                right: rem-calc(-22);
                top: rem-calc(-6);
            }

            // second
            &:after {
                border-width: rem-calc(18) 0 rem-calc(18) rem-calc(18);
                border-color: transparent transparent transparent $c-ielts-grey;
                right: rem-calc(-18);
                top: rem-calc(-2);
            }

            @include media-breakpoint-down(sm) {
                font-size: 0;
                color: transparent;
                &:before,
                &:after {
                    display: none;
                }
            }
        }

        &:last-child {
            &:before,
            &:after {
                display: none;
            }

            a {
                &:before,
                &:after {
                    display: none
                }
            }
        }

        &.is-active {
            background-color: $c-ielts-red;
            @include media-breakpoint-down(sm) {
                position: static;
                border-color: $c-ielts-red;
                &:before {
                    content: attr(data-mobile-text);
                    position: absolute;
                    display: flex;
                    width: 100%;
                    left: 0;
                }
            }    

            a {
                color: $c-white;
            }

            &:after {
                border-color: transparent transparent transparent $c-ielts-red;
            }
        }
    }

}