.formatted-table {
    table {
        border: 1px solid $c-ielts-black;
        widows: 100%;

        th,td {
            border-bottom: 1px solid $c-ielts-black;
            padding: rem-calc(20);
        }

        th {
            border-right: 1px solid $c-ielts-black;
        }
        
        @include media-breakpoint-down(sm) {
            border-bottom: 0;
            
            th, td {
                width: 100%;
                display: block;
                border-right: 0;
            }

            th {
                border-bottom: 1px solid $c-ielts-black;
            }
        }
    }
}