.two-col-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }

    .column {
        flex: 1;
        margin-bottom: rem-calc(25);
        position: relative;

        &:first-child {
            margin-right: rem-calc(15);
        }

        &:last-child {
            margin-left: rem-calc(15);
        }

        @include media-breakpoint-down(sm) {
            &:first-child {
                margin-right: 0;
            }

            &:last-child {
                margin-left: 0;
            }
        }
    }
}