.loader {
    display: none;
}

.is-loading {
    position: relative; 
    min-height: rem-calc(200);

    .loader {
        display: block;
        min-height: rem-calc(200);
        position: absolute;
        text-align: center;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 20;

        .loader-icon,
        .loader-icon:after {
          border-radius: 50%;
          width: rem-calc(100);
          height: rem-calc(100);
          position: relative;
          z-index: 4;
        }
        .loader-icon {
          font-size: rem-calc(10);
          position: relative;
          text-indent: rem-calc(-9999);
          border-top: rem-calc(11) solid rgba(100, 100, 100, 0.2);
          border-right: rem-calc(11) solid rgba(100, 100, 100, 0.2);
          border-bottom: rem-calc(11) solid rgba(100, 100, 100, 0.2);
          border-left: rem-calc(11) solid rgba(51, 51, 51, 1);
          -webkit-transform: translateZ(0);
          -ms-transform: translateZ(0);
          transform: translateZ(0);
          -webkit-animation: load8 1.1s infinite linear;
          animation: load8 1.1s infinite linear;
          position: absolute;
          top: 30%;
          left: 50%;
          transform: translate(-30%, -50%);
        }
        @-webkit-keyframes load8 {
          0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @keyframes load8 {
          0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }

        &:before {
            content: '';
            background-color: $c-white;
            bottom: 0;
            display: block;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }
    }
}

