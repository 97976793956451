.ot-tutorial-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem-calc(20);
    h4 {
        font-size: rem-calc(16);
        margin-bottom: rem-calc(12);
        line-height: normal;
    }
    label {
        margin-right: rem-calc(15);
    }

    input {
        margin-right: rem-calc(5);
    }
    .quiz-answer {
        display: none;
        background: #f0f0f0;
        padding: 5px 10px;
        margin: 0;
        color: #222;
        vertical-align: top;
        &.active {
            display: inline-block;
        }
        &.error {
            color: $c-ielts-red;
        }
    }
}
