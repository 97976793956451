.results-table {
    margin-top: rem-calc(60);
    h3 {
        margin-bottom: rem-calc(20);
    }

    .result-item-header {
        display: flex;
        flex-direction: row;
        background-color: #f1f1ed;
        font-family: 'PFCentroSlabPro_Medium';
        font-size: rem-calc(17);
        padding: rem-calc(15) rem-calc(10);

        @include media-breakpoint-down(sm) {
            display: none;
        }

        .header-text {
            flex: 1;
            margin-right: rem-calc(10);

            &:first-child {
                flex: 2;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .filter-arrow {
            &:after {
                content:'\f078';
                font-family: FontAwesome;
                color: $c-ielts-red;
                font-size: rem-calc(12);
                text-align: center;
                position: relative;
                height: rem-calc(20);
                width: rem-calc(20);
                display: block;
                border: rem-calc(1) solid $c-ielts-border-grey;
                display: inline-block;
                top: rem-calc(0);
                margin-left: rem-calc(10);
            }

            &.up {
                &:after {
                    content:'\f077';
                }
            }
        }
    }

    .result-item {
        display: flex;
        flex-direction: row;
        padding: rem-calc(15) rem-calc(10);
        border-bottom: rem-calc(1) solid $c-ielts-border-grey;

        @include media-breakpoint-down(sm) {
            background-color: $c-ielts-bg-grey;
            border: rem-calc(1) solid $c-ielts-border-grey;
            flex-direction: column;
            margin-bottom: rem-calc(20);
            padding: rem-calc(15);
        }

        .item-text {
            flex: 1;
            align-self: center;
            margin-right: rem-calc(10);
            
            &:first-child {
                flex: 2;
            }
            
            &.item-text--action {
                display: flex;
                justify-content: flex-end;
            }

            @include media-breakpoint-down(sm) {
                align-self: left;
                margin-bottom: rem-calc(10); 
                &.item-text--name {
                    border-bottom: rem-calc(2) solid $c-ielts-border-grey;
                    padding-bottom: rem-calc(10);
                    width: 100%;
                }
                &.item-text--location {
                    &:before {
                        content: attr(data-item);
                        display: block;
                        font-weight: bold;
                        padding: rem-calc(5) 0;
                        width: 100%;
                    }
                }

                &.item-text--band {
                    &:before {
                        content: attr(data-item);
                        display: block;
                        font-weight: bold;
                        padding: rem-calc(5) 0;
                        width: 100%;
                    }
                }
            }
            
            &:last-child {
                margin-right: 0;
            }

            
            a {
                color: $c-ielts-red;
                font-weight: bold;

                &:hover,
                &:focus {
                    color: $c-ielts-black;
                }

                &.btn.btn-default {
                    color: $c-white;
                    font-weight: normal;

                    &:hover,
                    &:focus {
                        color: $c-ielts-red;
                    }
                }
            }
        }
    }
}