.spotlight-panel {
    display: flex;
    flex-direction: row;
    // flex: 1 1 auto;
    margin: rem-calc(10) 0;

    .accordion {
        margin-bottom: 0;
    }

    &__content {
        background-color: $c-ielts-purple;
        color: $c-white;
        border-left: rem-calc(2) solid $c-ielts-red;
        
        h2 {
            margin: rem-calc(20) rem-calc(20) 0;
            color: $c-white;
            border-bottom: rem-calc(1) solid $c-white;
            padding-bottom: rem-calc(12);
            margin-bottom: rem-calc(12);
        }

        p {
            margin: rem-calc(20);
        }

        .btn {
            &:first-child {
                margin-left:rem-calc(20);
            }
        }
    }
    &__content,
    &__item {
        flex: 1;
    }

    &__item {
        overflow: hidden;
        position: relative;
        img {
            height: 100%;
            width: 100%;
            max-width: 100%;
            display: block;
        }
    }

    &--right {
        flex-direction: row-reverse;

        .spotlight-panel__content {
            border-left-width: 0;
            border-right: rem-calc(2) solid $c-ielts-red;
        }
    }

    &--small {
        // flex: auto;
        .spotlight-panel {
            &__content {
                background-color: transparent;
                color: $c-ielts-black;
                h2 {
                    margin-top: 0;
                    margin-right: 0;
                    margin-left: 0;
                    color: $c-ielts-black;
                    a {
                        margin: 0;
                        padding: 0;
                        color: $c-ielts-red;
                        font-family: 'PFCentroSlabPro_Medium';
                        
                        &:hover,
                        &:focus {
                            color: $c-ielts-black;
                        }
                    }
                }
                p {
                    margin-left: 0;
                }
                .btn-transparent {
                    color: $c-ielts-red;
                }
            }

            &__content {
                flex: 2;
                border-width: 0;
            }

            &__item {
                margin-right: rem-calc(20);

                img {
                    height: auto;
                    width: 100%;
                }
            }
        }

        &--right {
            flex-direction: row-reverse;

            .spotlight-panel {
                &__content {
                    h4, p {
                        margin-left: 0;
                    }
                }

                &__item {
                    margin-right: 0;
                }
            }
        }

        .blockquote-il {
            width: auto;
        }
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;

        &__content {
            padding-bottom: rem-calc(20);
            border-width: 0;
        }

        &--right {
            .spotlight-panel__content {
                border-width: 0;
            }
        }

        &--small {
            .spotlight-panel {
                &__item {
                    margin: 0 0 rem-calc(20);

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
.spotlight-panel + .spotlight-panel--small {
    margin-top: 40px;
    padding-top: 40px;
}
.spotlight-panel--small + .spotlight-panel--small {
    margin-top: 50px;
    padding-top: 50px;
    border-top: solid 1px $c-ielts-border-grey;
}
