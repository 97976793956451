.ot-pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex: 1;
    width: 100%;
    .btn {
        &-next {
            .fa {
                padding-left: rem-calc(5);
                padding-right: 0;
            }
        }
    }
}