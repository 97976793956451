.toggle-action {
    @include media-breakpoint-down(sm) {
        .toggle-heading {
            position: relative;

            &:after {
                content: '\f078';
                color: $c-ielts-red;
                display: block;
                font-family: FontAwesome;
                height: rem-calc(18);
                margin-top: rem-calc(-5);
                position: absolute;
                right: rem-calc(10);
                top: 50%;
                transform: translateY(-50%);
                width: rem-calc(20);
            }
        }

        .toggle-content {
            display: none;
        }

        &.is-visible {
            .toggle-heading {
                background-color: $c-ielts-red;

                h3 {
                    color: $c-white;
                }

                &:after {
                    color: $c-white;
                }
            }
        }
    }
}