.icon-block {
    display: flex;
    justify-content: space-around;
    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }

    h2 {
        font-size: rem-calc(16);
        color: $c-ielts-red;
        a {
            color: $c-ielts-red;

            &:hover,
            &:focus {
                color: $c-ielts-black;
            }
        }
    }

    p {
        font-size: rem-calc(15);
    }

    &__item {
        display: flex;
        flex-direction: row;
        margin-right: rem-calc(20);
        flex: 1;

        @include media-breakpoint-down(sm) {
            margin-right: 0;
            margin-bottom: rem-calc(12);
        }

        &:last-child {
            margin-right: 0;
        }

        .item-image {
            margin-right: rem-calc(20);
            
            img {
                width: rem-calc(60);
                // width: 100%;
            }
        }
    }
}