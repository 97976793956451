.ot-numbered-list {
    ol {
        margin: 0;
        padding: 0;
        counter-reset: item; 

        li {
            position: relative;
            min-height: rem-calc(50);
            padding-left: rem-calc(65); 
            display: flex;
            align-items: center;
            margin-bottom: rem-calc(20);
            font-weight: bold;
            &:before {
                left: 0;
                top: 0;
                position: absolute;
                counter-increment: item;  
                content: counter(item);
                color: $c-ielts-black;
                height: rem-calc(50);
                width: rem-calc(50);
                background-color: $c-ielts-purple;
                border-radius: 50%;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $c-white;
            }

            a {
                color: $c-ielts-red;

                &:hover {
                    color: $c-ielts-black;
                }
            }
        }
    }
}