.info-search {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: rem-calc(30);

    label {
        text-align: right;
        margin-right: rem-calc(20);
        font-size: rem-calc(22);
        font-family: 'PFCentroSlabPro_Medium';
        margin-top: rem-calc(5);
    }
    
    &__form {
        position: relative;
    }

    .search-button {
        position: absolute;
        padding: 0;
        right: rem-calc(8);
        top: 0;
        input {
            height: rem-calc(36);
            width: rem-calc(40);
        }
        &:after {
            content: '\f002';
            font-family: FontAwesome;
            position: absolute;
            font-size: rem-calc(18);
            padding-top: rem-calc(7);
            top: 0;
            right: 0;
            width: rem-calc(40);
            height: rem-calc(36);
            display: block;
            background-color: $c-white;
            color: $c-ielts-red;
            pointer-events: none;
        }
        @include media-breakpoint-down(md) {
            top: 0;
        }
    }
    
    @include media-breakpoint-down(sm) {
        flex-direction: column;

        &__heading,
        &__form {
            flex: 0 0 100%;
        }

        label {
            text-align: center;
            width: 100%;
        }

        .search-button {
            margin: 0;
        }
    }

    &.research-db-search {
        padding-top: rem-calc(20);
        .form-inline {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }

        .info-search__form {
            display: flex;
            flex-direction: row;
            width: 100%;
            
            .form-control {
                flex: 0 0 40%;
            }
        }
    }
}

.required-field-marker {
    color: $c-ielts-red;
}
.p-30 {
    padding: rem-calc(30) 0;
}
.radio-container {
    display: flex;
    
}

.generic-form-label {
    width: 200px;
    min-width: 200px;

    & + div {
        margin-left: 6.9px;
    }

    @include media-breakpoint-down(sm){
        display: block;
        width: auto;

        & + div {
            margin-left: 0;
        }
    }
}

.generic-textarea-container {
    display: inline-flex;
}

.generic-form-div {
    margin-bottom: rem-calc(30);
    display: flex;
    @include media-breakpoint-down(sm){
        flex-direction: column;
    }
    input[type="text"],
    textarea,
    select {
        width: 400px;
        min-height: 35px;
        line-height: 30px;
        padding: 0 10px;
        border: 1px solid #ccc;
        background: $c-white;
        @include media-breakpoint-down(sm){
        width: 100%;
        }
    }

    input[type="text"],
    select {
        height: 35px;
    }

    .generic-filter-dropdown-wrapper {
        position: relative;
        select {
            padding-right: rem-calc(35);
        }
        &:after {
            content: '';
            position: absolute;
            height: 35px;
            right: 0;
            top: 0;
            width: 35px;
            background-color: $c-ielts-black;
            pointer-events: none;
        }
        &:before {
            content: '\f078';
            font-family: 'FontAwesome';
            right: 8px;
            top: 5px;
            position: absolute;
            color: $c-white;
            z-index: 999;
            pointer-events: none;
        }
    }
}

.generic-error-message {
    display: none;
    &.active {
        display: block;
    }
}

.field-error {
    input,
    select,
    textarea {
        border-color: $c-ielts-red!important;
        background-color: rgba(220,20,60, 0.3)!important;
    }
    label {
        color: $c-ielts-red;
    }
    .generic-error-message {
        display: block!important;
        color: $c-ielts-red;
        visibility: visible!important;
    }
}

body.ielts-online-training {
    .carousel-container {
        border: 0;
    }

    .hero-statement {
        margin-bottom: rem-calc(0);
        padding: 0;
        border: 0;
    }

    .slick-dots {
        top: -40px;
        position: relative;
        z-index: 101;
    }
    
    .carousel-wrapper-single .slide-text-container:before {
        z-index : 99;
    }
    .carousel-wrapper .slide-text-container {
        bottom: -1px;
        z-index: 104;
    }

    .boxed {
        background-color: #fff;
        box-shadow: 0 0 0.75rem -0.125rem rgba(0, 0, 0, 0.75);
        padding: 0 rem-calc(20);
        max-height: calc(100vh - 20px);
        min-height: calc(100vh - 20px);
    }

    .slick-dotted.slick-slider,
    .slick-dots {
        margin-bottom: 10px;
    }
 
    .clearfix:after {
        content: "";
        display: table;
        clear: both;
    }
    hr {
        color: #cecece;
        border-width: 3px;
        margin: 10px 0;
    }
    img {
        width: 100%;
    }
    .tutorial-image {
        position: relative;
        img {
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%; 
            border-bottom-left-radius: 50%; 
        }
        .image-snippet {
            position: absolute;
            right: -10px;
            bottom: -10px;
            background-color: $c-ielts-red;
            color: $c-white;
            width: 140px;
            height: 140px;
            border-radius: 50%;
            border: 3px solid $c-white;
            font-size: rem-calc(13);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            text-align: center;
        }
    }
       
    .sub-content-nav {
        ul {
            display: flex;
            padding: 0;
            flex-wrap: wrap;
            border: 1px solid $c-ielts-red;
            border-bottom-width: 0;
            border-right-width: 0;
            li {
                border-bottom: 1px solid $c-ielts-red;
                display: inline-block;
                border-bottom: 1px solid $c-ielts-red;
                border-right: 1px solid $c-ielts-red;
                width: 33.33334%;
                a {
                    display: block;
                    padding: rem-calc(10);
                    color: $c-ielts-red;
                    height: 100%;
                    transition: all 200ms ease-out;
                    &:hover {
                        background: $c-ielts-red;
                        color: $c-white;
                    }
                }
            }
        }
    }

}

.bandscore-form {
   
    .generic-form-div {
        align-items: center;
        @include media-breakpoint-down(sm) {
            align-items: flex-start;
        }
        div{
            margin-right: rem-calc(20);
        }
       
        select,
        input {
            width: 100%!important;
            display: block;
        }
    }
    
}
.btn-action {
    background-color: $c-ielts-black;
    padding-top: 7px;
    padding-left: 7px;
    padding-bottom: 5px;
    padding-right: 3px;
}

.ml-10 {
    margin-left: rem-calc(10);
}

.forms-widget-page {
    margin-top: rem-calc(30);
}

.receive-updates {
    display: flex;
    @include media-breakpoint-down(sm) {
        flex-direction: column;    
    }
    > div:first-child() {
        margin-right: rem-calc(30);
    }
    ul {
        padding-left: 0;
        li {
            position: relative;
            &:before {
                content: "• ";
                color: red; /* or whatever color you prefer */
              }
        }
    }
    .receive-updates-checkbox {
       
        span {
            font-weight: bold;
        }
    }
}

table.last-step-results {
    width: 100%; 
    border-collapse: collapse; 
    thead {
        background-color: #efefef;
        font-weight: bold;  
    }
    tr {
         border: 1px solid #ccc; 
        td {
            padding: rem-calc(15); 
            border-right: 1px solid #ccc;
            &:last-child(){
                border: 0;
            }
        }    
    }
    
    @media only screen and (max-width: 760px),
        (min-device-width: 768px) and (max-device-width: 1024px)  {

            /* Force table to not be like tables anymore */
            table, thead, tbody, th, td, tr { 
                display: block; 
            }
            
            /* Hide table headers (but not display: none;, for accessibility) */
            thead tr { 
                position: absolute;
                top: -9999px;
                left: -9999px;
            }
            
            tr { border: 1px solid #ccc; }
            
            td { 
                /* Behave  like a "row" */
                border: none;
                border-bottom: 1px solid #eee; 
                position: relative;
                padding-left: 50%; 
            }
            
            td:before { 
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 6px;
                left: 6px;
                width: 45%; 
                padding-right: 10px; 
                white-space: nowrap;
            }
            
            /*
            Label the data
            */
            td:nth-of-type(1):before { content: "Organisation"; }
            td:nth-of-type(2):before { content: "Band score requirements"; }
            td:nth-of-type(3):before { content: "Results preference"; }
            td:nth-of-type(4):before { content: "Nominated representative"; }
           
        }
}

// Styles for WebFormsForMarketeers
form[data-wffm] {
    .form-group {
        width: 610px;
        position: relative;
        padding-left: 210px;

        @media (max-width: 760px) {
            width: 100%;
            padding-left: 0;
        }

        .control-label {
            position: absolute;
            left: 0;
            width: 200px;
            margin-right: 10px;

            @media (max-width: 760px) {
                position: relative;
                width: 100%;
            }
        }

        input, textarea {
            width: 400px;

            @media (max-width: 760px) {
                width: 100%;
            }
        }

        .radio {
            width: 400px;
            label {
                display: flex;
                align-items: center;

                input {
                    width: auto;
                    margin-right: 5px;
                }
            }

            @media (max-width: 760px) {
                width: 100%;
            }
        }

        &.required-field {
            .control-label:after {
                content: "*";
                color: $c-ielts-red;
                display: inline;
                position: absolute;
                top: 0;
                right: -8px;

                @media (max-width: 760px) {
                    position: static;
                    margin-left: 5px;
                }
            }
        }

        &.has-error {
            input[type='text'], input[type='email'], input[type='number'], input[type='password'], textarea {
                border: 1px solid $c-ielts-red;
                background-color: lighten($c-ielts-red, 40%);
            }

            .field-validation-error {
                width: 400px;
                color: $c-ielts-red;
            }
        }

        &.hidden {
            display: none;
        }
    }

    .validation-summary-errors {
        display: none;
    }
}