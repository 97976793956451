.highlight-box {
    background-color:$c-ielts-purple;
    display: flex;
    flex-direction: column;
    margin-bottom: rem-calc(25);
    padding: rem-calc(20);
    width: 100%;
    
    h3, p {
        color: $c-white;
        font-weight: bold;
    }

    &--left {
        width: 25%;
    }
    &--right {
        float: right;
        width: 25%;
    }

    @include media-breakpoint-down(sm) {
        &--left,
        &--right {
            width: 100%;
        }
    }
}