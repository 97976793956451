.downloadable-reports {
    .report-list {
        margin: 0;
        padding: 0;
        > li {
            border-top: rem-calc(1) solid $c-ielts-border-grey;
            padding-bottom: rem-calc(30); 
            padding-top: rem-calc(30); 
        }
    }
    .report-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        &__detail {
            border-right: rem-calc(1) solid $c-ielts-border-grey;
            margin-right: rem-calc(30);
            padding-right: rem-calc(30);

            @include media-breakpoint-down(sm) {
                border-right-width: 0;
                padding-right: 0;
            }
        }
    }
    
    h3 {
        font-size: rem-calc(20);
        margin-bottom: rem-calc(21);
        a {
            color: $c-ielts-red;

            &:hover,
            &:focus {
                color: $c-ielts-black;
            }
        }
    }

    h4, p {
        font-size: rem-calc(14);
    }

    .tags-list {
        margin: 0;
        padding: 0;
        @include media-breakpoint-down(sm) {
            margin-bottom: rem-calc(12);
        }
        li {
            color: $c-ielts-red;
            display: inline;
            font-size: rem-calc(14);
            position: relative;
            padding-right: rem-calc(6);
            &:after {
                content:',';
                position: absolute;
                bottom: 0;
                right: rem-calc(2);
                width: 3px;
                height: 100%;
            }

            &:last-child {
                &:after{
                    display: none;
                }
            }
        }
    }

    .report-mime-type, {
        margin-bottom: rem-calc(18);
        @include media-breakpoint-down(md) {
            img {
                width: rem-calc(150);
            }
        }
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    a.report-download {
        color: $c-ielts-red;
        display: inline-block;
        font-size: rem-calc(15);
        font-weight: bold;
        margin-bottom: rem-calc(12);
        
        &:hover,
        &:focus {
            color: $c-ielts-black;
        }
    }

    .report-doc-size {
        font-size: rem-calc(12);
        display: block;
        @include media-breakpoint-down(sm) {
            display: inline-block;
        }
    }

    .pagination {
        ul {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }
}