body, a {
    color: $c-ielts-black;
}

h1 {
    font-size: 2.3rem;
}

h2 {
    font-size: 1.6rem;
}

h3 {
    font-size: 1.38rem;
    line-height: normal;
}

li {
    list-style-type: none;
}

a {
    &:hover {
        color: $c-ielts-red;
        transition: color .3s ease-in;
        text-decoration: none;
    }
}

.title {
    color: $c-ielts-red;
}

.main-nav .navbar-nav, .card-text, .title, h1, h2, h3 {
    font-family: 'PFCentroSlabPro_Medium';
}

.form-control {
    border-radius: 0;
}

.form-select {
    position: relative;
    .custom-select {
        border-color: #333333;
    }
    &:after {
        content:'\f078';
        font-family: FontAwesome;
        color: $c-white;
        position: absolute;
        top: rem-calc(1);
        right: 0;
        width: rem-calc(36);
        height: rem-calc(36);
        display: block;
        background: $c-ielts-black;
        line-height: rem-calc(36);
        text-align: center;
        pointer-events: none;
    }
}

.bg-grey {
    background-color: $c-ielts-bg-grey;
    border-top: 1px solid $c-ielts-border-grey;
    border-bottom: 1px solid $c-ielts-border-grey;
}

.bg-purple {
    background-color: $c-ielts-purple;
    border-top: 1px solid $c-ielts-purple;
    border-bottom: 1px solid $c-ielts-purple;
}

.container-fluid {
    margin-bottom: rem-calc(30);
    .container {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.container:after {
    content: "";
    display: table;
    clear: both;
}

body.ielts-online-training {
    background-color: $c-ielts-bg-grey;

    .boxed-layout {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: rem-calc(30);
        background-color: $c-white;
        box-shadow: 0 0 rem-calc(12) rem-calc(-2) rgba(0,0,0,0.75);
        margin-bottom: 12px;
        max-height: calc(100vh - 20px);
        min-height: calc(100vh - 20px);

        .btn-menu {
            position: absolute;
            right: rem-calc(15);
            top: rem-calc(30);
        }
    }
}