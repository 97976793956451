.breadcrumb-wrapper {
    .container {
        padding: 0;
    }
}
.breadcrumb-list {
    padding: rem-calc(15) 0;
    li {
        display: inline-block;
        position: relative;
        padding-right: rem-calc(25);
        font-size: rem-calc(12);

        &:after {
            content:'\f178';
            display: block;
            font-family: FontAwesome;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: rem-calc(5);
            color: $c-ielts-red;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        a {
            &:hover,
            &:focus {
                color: $c-ielts-red;
            }
        }
    }
}