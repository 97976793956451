.feature-col-block {
    a {
        &:hover,
        &:focus {
            .feature-col-block__image {
                &:after {
                    background: rgba($color: #000000, $alpha: 0.4);
                }
            }
        }
    }
    &__image {
        margin-bottom: rem-calc(15);
        position: relative;

        &:after {
            content:'';
            position: absolute;
            height: 100%;
            width: 100%;
            display: block;
            background: rgba($color: #000000, $alpha: 0);
            top: 0;
            transition: background .3s ease-in;
        }

        img {
            width: 100%;
        }
    }

    &__heading {
        h2, h3 {
            color: $c-ielts-red;
            font-size: rem-calc(20);
        }
    }
}