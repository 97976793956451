.carousel-wrapper,
.carousel-wrapper-single {
    padding-left: 0;
    position: relative;
    margin-top: rem-calc(54);
    .mobile-placeholder {
        display: none;
    }
    .carousel-container {
        border-top: rem-calc(5) solid $c-ielts-red;
        .slick-arrow {
            display: none;
        }
        .slick-slide {
            img {
                width: 100%;
            }
        }
        .slide-tag {
            position: absolute;
            background-color: $c-ielts-red;
            color: $c-white;
            bottom: rem-calc(20);
            left: rem-calc(15);
            padding: rem-calc(6) rem-calc(12);
            font-family: 'PFCentroSlabPro_Regular';
            font-weight: bold;
            font-size: rem-calc(14);
        }
        .slick-list {
            z-index: 2;
            overflow: visible;
        }
        .slick-dots {
            height: rem-calc(40);
            padding-top: rem-calc(2);
            text-align: left;
            background-color: #333333;
            li {
                button {
                    &:before {
                        color: $c-white;
                        opacity: 1;
                        z-index: 2;
                        left: rem-calc(7);
                        top: rem-calc(2);
                        width: rem-calc(19);
                        height: rem-calc(19);
                    }
                    &:after {
                        width: rem-calc(22);
                        height: rem-calc(22);
                        display: block;
                        content: '';
                        position: absolute;
                        background-color: #333333;
                        border-radius: 50%;
                        border: rem-calc(2) solid #333333;
                        top: 0;
                    }
                }

                &.slick-active {
                    button {
                        &:before {
                            color: $c-ielts-red;
                        }
                        &:after {
                            border-color: $c-white;
                        }
                    }
                }
            }

        }
    }

    .slide-text-container {
        width: rem-calc(180);
        height: rem-calc(180);
        display: block;
        display: flex;
        align-items: center;
        text-align: center;
        position: absolute;
        bottom: rem-calc(-90);
        right: rem-calc(100);

        @include media-breakpoint-down(md) {
            right: rem-calc(30);
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    
        span {
            position: relative;
            z-index: 4;
            padding: rem-calc(12) rem-calc(20);
            color: $c-white;
            font-size: rem-calc(24);
            font-family: 'PFCentroSlabPro_Medium';
            line-height: normal;
        }
        &:before {
            content: '';
            border-bottom-left-radius: rem-calc(90);
            border-bottom-right-radius: rem-calc(90);
            height: rem-calc(95);
            width: rem-calc(190);
            background: $c-white;
            display: inline-block;
            position: absolute;
            bottom: rem-calc(-5);
            left: rem-calc(-5);
            z-index: 1;
            border: rem-calc(16) solid $c-white;
            border-top: 0;
        }

        &:after {
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba($color: #d31c1e, $alpha: 0.9);
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 3;
        }

    }
}

.carousel-wrapper-single {
    .slide-text-container:before, .carousel-wrapper-single .slide-text-container:before {
        background: transparent;
        border-color: transparent;
    }
}