.ot-numbers-tabs {
    .tabs {
        ul {
            border-bottom-width: 0;
            flex-direction: row;
            flex-wrap: wrap;
        }
        li {
            margin-bottom: rem-calc(5);
            margin-left: rem-calc(5);
            flex: 0 0 18%;
            a {
                background-color: $c-ielts-purple;
                color: $c-white;
                border: rem-calc(1) solid $c-ielts-purple;
                text-align: center;

                &:hover {
                    background-color: $c-white;
                    color: $c-ielts-purple;
                }
                
                &.is-active {
                    background-color: $c-white;
                    color: $c-ielts-purple;
                }
            }
        }
    }

    &.mobile-accordion {
        .item {
            &:before {
                border: rem-calc(1) solid $c-ielts-purple;
                background-color: $c-ielts-purple;
                margin-bottom: rem-calc(5);
            }

            &.is-active {
                &:before {
                    background-color: $c-white;
                    color: $c-ielts-purple;
                }
                &:after {
                    color: $c-ielts-purple;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        .tabs {
            margin-right: rem-calc(20);
            ul {
                border-bottom-width: 0;
                flex-direction: row;
                flex-wrap: nowrap;
            }
    
            li {
                border-right-width: 0;
                margin-bottom: rem-calc(2);
                flex: 1;
                
                a {
                    font-size: rem-calc(16);
                    padding: rem-calc(16) rem-calc(16);
                    &:hover {
                        background-color: $c-white;
                        color: $c-ielts-purple;
                    }
                }
            }
        }
    
        .item {
            width: 0;

            &:before,
            &:after {
                display: none;
            }
    
            &.is-active {
                width: 100%;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .tabs {
            li {
                a {
                    padding: rem-calc(30) rem-calc(16);
                }
            }
        }
    }
}

.tab-wrapper {
    border-bottom: 3px solid $c-ielts-red;
    padding: 0;
    margin: 0;
    display: inline-flex;
    margin-bottom: rem-calc(30);
    @include media-breakpoint-down(sm) {
        flex-direction: column;
        display: flex;
    }
    li {
        margin-right: 5px;
        @include media-breakpoint-down(sm) {
            margin-right: 0;
        }
        &:last-child{
            margin-right: 0;
        }
        a {
            display: block;
            padding: 10px;
            color: $c-white;
            background-color: $c-ielts-black;
            &.active {
                background-color: $c-ielts-red;
            }
        }
        
    }
}

.result-wrapper {
    a {
        color: $c-ielts-red;
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
        font-family: inherit;
        text-decoration: none;
    }
    .search-result-item {
        padding-bottom: rem-calc(20);
        margin-bottom: rem-calc(20);
        border-bottom: 1px solid $c-ielts-grey;
        &:first-of-type {
            margin-top: 20px;
        }
    }
    .search-result-item-title {
        font-weight: bold;
        font-size: rem-calc(23);
        font-family: PFCentroSlabPro_Medium;
        line-height: rem-calc(30);

    }
    .tab-type-b-div {
        display: none;
        margin-bottom: rem-calc(30);
        &.active {
            display: block;
        }
    }
}

.ielts-online-training {

   

    .p-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .carousel-wrapper {
        margin-top: 0px;
        .slide-text-container {
            bottom: -10px;
        }
        .slick-dots {
            .slick-active {
                display: none;
            }
        }
    }
    .ot-feature-col-block {
        .feature-item {
            width: 24%;
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

    .geo-wrapper .geo-message .container {
         max-width: 1140px;
    } 

}