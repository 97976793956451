.promo-banner {
    background: $c-ielts-purple;
    color: $white;
    padding: rem-calc(25) 0;
    position: relative;
    @include clearfix();
    margin-bottom: rem-calc(20);
    @include media-breakpoint-up(md) {
        margin-bottom: rem-calc(40);
    }
    &:before {
        position: absolute;
        width: 9999px;
        top: 0;
        bottom: 0;
        left: -9999px;
        background: $c-ielts-purple;
        display: block;
    }
    &:after {
        position: absolute;
        width: 9999px;
        top: 0;
        bottom: 0;
        left: 100%;
        background: $c-ielts-purple;
        display: block;
    }
    .container {
        display: flex;
        flex-direction: row;
        width: 100%;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }

    &__item {
        margin-right: rem-calc(25);
        @include media-breakpoint-down(sm) {
            margin-right: rem-calc(0);
            margin-bottom: rem-calc(25);
        }

        .fa {
            color: $white;
            font-size: rem-calc(83);
            position: relative;
        }
    }

    &__content {
        flex: 2;
        h2 {
            color: $white;
        }
        p {
            margin-bottom: 0;
            a {
                color: $white;
                display: inline-block;
                &:hover,
                &:focus {
                    color: $c-ielts-red;
                }
            }
        }
    }

    &--flip {
        .promo-banner {
            &__item {
                order: 2;
                flex: 1;
                margin-right: 0;
                align-items: center;
                display: flex;

                a {
                    margin-right: rem-calc(30);
                }

                .fa {
                    font-size: rem-calc(60);
                }
            }

            &__content {
                order: 1;
                flex: 1;
                margin-right: rem-calc(40);
                @include media-breakpoint-down(sm) {
                    margin-right: 0;
                    margin-bottom: rem-calc(25);
                }

                h2, p {
                    margin-left: 0;
                }
            }
        }
    }
}


// full width box -RO page
.full-width-box {
    background: $c-ielts-grey2;
    border: 0;
    padding: rem-calc(25) 0;
    position: relative;
    @include clearfix();
    margin-bottom: rem-calc(20);
    @include media-breakpoint-up(md) {
        margin-bottom: rem-calc(40);
    }
    &:before {
        position: absolute;
        width: 9999px;
        top: 0;
        bottom: 0;
        left: -9999px;
        background: $c-ielts-grey2;
        display: block;
    }
    &:after {
        position: absolute;
        width: 9999px;
        top: 0;
        bottom: 0;
        left: 100%;
        background: $c-ielts-grey2;
        display: block;
    }    
}