.rte {
    padding: rem-calc(20) 0;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $PFCentroSlabProMedium;
        margin-bottom: rem-calc(16);
    }

    h1 {
        font-size: rem-calc(51);
        line-height: 1.09;
        letter-spacing: rem-calc(1.6);
    }

    h2 {
        font-size: rem-calc(34);
        line-height: 1.2;
        letter-spacing: rem-calc(1.5);
    }

    h3 {
        font-size: rem-calc(25);
        line-height: 1.28;
        letter-spacing: rem-calc(1.1);
    }
    p {
        font-family: $standardFont;
        line-height: 1.5;
        letter-spacing: rem-calc(0.5);
        margin:0 0 rem-calc(20);
        font-size: rem-calc(16);
    }

    a:not(.btn, .black-button) {
        color: $c-ielts-red;
        transition: all 0.4s ease-out;

        &:hover,
        &:focus {
            color: $c-ielts-red;
        }
    }

    img {
        margin-bottom: rem-calc(10);
        width: 100%;
    }

    figcaption {
        font-family: $standardFont;
        font-style: italic;
    }

    ul {
        margin: 0 0 rem-calc(20) rem-calc(20);
        padding: 0;
        li {
            list-style: disc;
            font-family: $standardFont;
            padding: 0 0 rem-calc(10) 0;
            ul, ol {
                margin-top: rem-calc(10);
                margin-bottom: 0;
            }
        }
    }

    ol {
        margin: 0 0 rem-calc(20) rem-calc(25);
        padding: 0;
        li {
            list-style: decimal;
            font-family: $standardFont;
            padding: 0 0 rem-calc(10) 0;
            ul, ol {
                margin-top: rem-calc(10);
                margin-bottom: 0;
            }
        }
    }

    table {
        width: 100%;
        margin-bottom: rem-calc(50);
        thead {}

        th {
            font-family: $standardFont;
        }

        td,
        th {
            padding-left: 0;
        }

        tbody {
            tr {
                @include media-breakpoint-up(md) {
                    background-color: #f0f1f1;
                    &:nth-child(odd) {
                        background-color: #fdfdfd;
                    }
                }
            }
        }

        td {
            font-family: $standardFont;
        }
    }
    @include media-breakpoint-down(sm) {
        table,
        tbody,
        td,
        th,
        thead,
        tr {
            display: block;
        }

        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        td {
            /* Behave  like a "row" */
            border: none;
            position: relative;
            padding-left: 50%;
        }

        td:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: rem-calc(6);
            left: rem-calc(6);
            width: 45%;
            padding-right: rem-calc(10);
            white-space: nowrap;
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    background-color: $c-white;
                }
                td {
                    &:nth-child(odd) {
                        //background-color: $c-table-dark-bg;
                    }
                }
            }
        }
    }

    blockquote {
        p {
            border-top: 2px solid $c-ielts-purple;
            border-bottom: 2px solid $c-ielts-purple;
            display: inline-block;
            color: $c-ielts-purple;
            padding: 15px 0;
            font-size: rem-calc(20);
            line-height: rem-calc(30);
            font-family: 'Arial', sans-serif;
        }

        footer {
            display: block;
            font-size: rem-calc(16);
            color: #868e96;
            text-align: right;
            font-family: 'Arial', sans-serif;
        }
    }

	figure {
		img {
			width: 100%;
		}
		figcaption {
			
		    font-size: rem-calc(12);
		    line-height: rem-calc(22);
		    margin: 0 0 rem-calc(16) 0;
		    @include media-breakpoint-up(md) {
                font-size: rem-calc(14);
                line-height: rem-calc(24);
		    }
		    span {
		    	display: block;
                font-size: rem-calc(10);
                line-height: rem-calc(18);
		    }
		}
	}
}
