.video-playlist-carousel {
	position: relative;

	.carousel {
		display: flex;
		flex-direction: row;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
	}

	.carousel-inner {
		flex: 2;

		@include media-breakpoint-down(sm) {
			flex: unset;
		}
	}

	.carousel-indicators-wrapper {
		position: relative;
		width: 25%;
		overflow: auto;
		overflow-x: hidden;

		@include media-breakpoint-down(sm) {
			height: rem-calc(120);
			overflow: auto;
			overflow-y: hidden;
			width: 100%;
		}

		ul {
			display: flex;
			flex-direction: column;
			top: 0;
			bottom: auto;
			justify-content: flex-start;

			@include media-breakpoint-down(sm) {
				margin: 0;
				flex-direction: row;
			}
		}
	}

	.carousel-indicators {
		white-space: nowrap;
		bottom: 0;
		margin-left: rem-calc(10);
		margin-right: 0;
	
		> li {
			width: 100%;
			height: auto;
			text-indent: initial;
			border-bottom: rem-calc(1) solid $c-ielts-border-grey;
			padding-bottom: rem-calc(4);
			background-color: transparent;
			
			img {
				width: 100%;
			}
			a {
				display: block;
				display: flex;
				position: relative;
			}

			&.active {
				img {
					opacity: 0.7;
				}
			}
		}
	}

	.video-item {
		&__image {
			border-bottom: rem-calc(2) solid transparent;
			border-top: rem-calc(2) solid transparent;
			margin: rem-calc(5) 0 0;
			padding: rem-calc(2) 0;
			width: rem-calc(90);
			img {
				height: auto;
				width: 100%;
			}
		}
		
		&__content {
			font-size: rem-calc(14);
			margin-left: rem-calc(10);
			margin-top: rem-calc(5);
			position: relative;
			display: flex;
			flex-direction: column;
			white-space: initial;
			width: rem-calc(120);

			.video-title {
				max-height: rem-calc(45);
				overflow: hidden;
				white-space: unset;
			}

			.video-action {
				position: relative;
				padding-left: rem-calc(25);
				font-weight: bold;
				&:before {
					content:'\f144';
					position: absolute;
					font-size: rem-calc(20);
					display: block;
					top: rem-calc(-4);
					left: 0;
					color: $c-ielts-red;
					text-align: center;
					font-family: FontAwesome;

				}
			}
		}
	}

	.is-playing {
		.video-item {
			&__image {
				border-bottom-color: $c-ielts-red;
				border-top-color: $c-ielts-red;
			}
		}
	}
}

// Generic video player styling
.video-wrapper {
	position: relative;
	height: 0;
	padding-top: 56.25%;
	margin: 0;
	background-color: #000;
	.video-player {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: transparent;
		opacity: 0;
	}
	.video-poster {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-size: cover;
		overflow: hidden;
		opacity: 1;
		transition: opacity 800ms, height 0s;
		transition-delay: 0s, 0s;
	}
	.video-player-content {
		position: absolute;
		top: rem-calc(20);
		left: rem-calc(20);
		color: $c-white;
		padding-top: rem-calc(58);
		@include media-breakpoint-up(md) {
			top: auto;
			bottom: 17%;
			left: 13%;
			width: rem-calc(580);
			padding-top: 0;
		}
		@include media-breakpoint-down(md) {
			width: auto;
		}
		h2 {
			color: $c-white;
			@include font-size(16);
			@include line-height(20);
			margin-bottom: 0;
			@include media-breakpoint-up(md) {
				margin-bottom: rem-calc(11);
				width: 100%;
			}
		}
		p {
			color: $c-white;
			font-weight: 600;
			margin-bottom: 0;
			&.video-duration {
				font-weight: 300;
				margin-bottom: rem-calc(11);
				@include media-breakpoint-up(md) {
					@include font-size(15);
				}
			}
		}
		a {
			color: $c-ielts-red;
			font-weight: bold;

			@include media-breakpoint-down(sm) {
				padding-left: rem-calc(35);
				.fa {
					font-size: rem-calc(30);
				}
			}

			&:hover,
            &:focus {
				color: $c-white;
			}
		}
	}
	&.video-wrapper-active {
		.video-player {
			opacity: 1;
			z-index: 1;
		}
		.video-poster {
			opacity: 0;
			z-index: 0;
		}
	}
	&.is-loading {
		.loading-div {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
		}
	}
}

.error-message {
	text-align: center;
	h2 {
		padding: rem-calc(30);
	}
}

.hidden {
	visibility: hidden;
}

iframe.video-player {
	.ytp-pause-overlay {
		display: none;
	}
}