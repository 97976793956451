.find-test-location {
    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between; 
    
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }
    .find-test-location__breadcrumb {
        display: none;
    }

    &__option {
        border: rem-calc(1) solid $c-ielts-border-grey;
        border-radius: rem-calc(5);
        padding: rem-calc(25) rem-calc(25) rem-calc(85);
        flex: 1;
        margin-bottom: rem-calc(25);
        position: relative;

        &:first-child {
            margin-right: rem-calc(15);
        }

        &:last-child {
            margin-left: rem-calc(15);
        }

        @include media-breakpoint-down(sm) {
            &:first-child {
                margin-right: 0;
            }
    
            &:last-child {
                margin-left: 0;
            }
        }

        .btn {
            display: block;
            text-align: center;
            position: absolute;
            bottom: rem-calc(25);
            left: rem-calc(25);
            right: rem-calc(25);
        }

        &.is-selected {
            background-color: $c-ielts-bg-grey;
        }
    }

    &__content {
        display: none;
        padding: rem-calc(25) 0;
    }

    &__panel {
        h2 {
            margin-bottom: rem-calc(20);
        }

        .two-col-layout {
            display: flex;
            flex-direction: row;
            justify-content: space-between; 

            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }
        }

        .column {
            flex: 1;
            margin-bottom: rem-calc(25);
            position: relative;
    
            &:first-child {
                margin-right: rem-calc(15);
            }
    
            &:last-child {
                margin-left: rem-calc(15);
            }

            @include media-breakpoint-down(sm) {
                &:first-child {
                    margin-right: 0;
                }
        
                &:last-child {
                    margin-left: 0;
                }
            }
        }
    }

    &__search {
        display: flex;
        flex-direction: row;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
        .form-row {
           margin: 0;
           flex: 1;

           @include media-breakpoint-down(sm) {
               flex-direction: column;
           }

           .form-group {
                width: 230px;

                @include media-breakpoint-down(sm) {
                    width: auto;
                }
           }

           .custom-select {
               width: 100%;
           }
        }

        .btn {
            display: flex;
            align-self: flex-start;
            margin-left: rem-calc(20);
            @include media-breakpoint-down(sm) {
                margin-left: 0;
            }
        }
    }
}
