.locations-map {
    position: relative;
    overflow: hidden;

    h3 {
        margin-bottom: rem-calc(20);
        margin-right: rem-calc(30);
    }

    #map {
        width: 100%;
        height: rem-calc(400);
    }

    .locations-panel {
        position: absolute;
        top: 0;
        bottom: 0;
        width: rem-calc(350);
        background-color: $c-white;
        height: 100%;
        z-index: 1;
        overflow: visible;
        left: rem-calc(-350);
        transition: left .2s linear;
        @include media-breakpoint-down(md) {
            width: rem-calc(300);
            left: rem-calc(-300);
        }
        @include media-breakpoint-down(sm) {
            left: 0;
            width: 100%;
            position: relative;
            height: rem-calc(60);
        }

        .icon-close {
            background-color: $c-white;
            color: $c-ielts-black;
            display: block;
            font-family: FontAwesome;
            position: absolute;
            text-align: center;
            top: rem-calc(10);
            right: rem-calc(-40);
            padding: rem-calc(12) rem-calc(10);
            border-radius: 0 rem-calc(5) rem-calc(5) 0;

            @include media-breakpoint-down(sm) {
                right: 0;
                top: 0;

                &:before {
                    content: attr(data-show-text);
                    font-size: rem-calc(12);
                    font-family: $standardFont;
                    left: 0;
                }
            }

            &:after {
                content: '\f054 \f054';
                font-size: rem-calc(20);
                letter-spacing: rem-calc(-4);

                @include media-breakpoint-down(sm) {
                    display: inline-block;
                    transform: rotate(90deg);
                    right: 0;
                }
            }

            &:hover,
            &:focus {
                color: $c-ielts-red;
            }
        }

        &.is-visible {
            box-shadow: rem-calc(8) rem-calc(2) rem-calc(12) rem-calc(-6) rgba(0,0,0,0.75);
            left: 0;

            @include media-breakpoint-down(sm) {
                height: rem-calc(400);
                .panel-list {
                    height: rem-calc(300);
                    top: rem-calc(60);
                }
            }

            .icon-close {
                right: 0;
                top: 0;
                padding: rem-calc(10);

                @include media-breakpoint-down(sm) {
                    padding: rem-calc(12) rem-calc(10);
                    &:before {
                        content: attr(data-hide-text);
                    }
                }
                
                &:after {
                    content: '\f00d';
                    letter-spacing: normal;
                    @include media-breakpoint-down(sm) {
                        content: '\f054 \f054';
                        font-size: rem-calc(20);
                        letter-spacing: rem-calc(-4);
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }

    .panel-controls {
        padding: rem-calc(6) 0;
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }

        .reset-list {
            &.is-hidden {
                display: none;

                ~ .locations-count {
                    display: inline-block;
                }
            }
        }

        .locations-count {
            padding-top: rem-calc(12);
            font-weight: bold;
            font-size: rem-calc(14);
            display: none;
        }
    }

    .panel-list {
        position: absolute;
        top: rem-calc(50);
        bottom: 0;
        left: 0;
        overflow-y: auto;
        overflow-x: hidden;
        padding: rem-calc(12) rem-calc(20) rem-calc(12) 0;
        width: 100%;

        @include media-breakpoint-down(sm) {
            height: 0;
            padding: 0;
        }
    }


    .locations-list {
        margin: 0;
        padding: 0;
        li {
            position: relative;
            padding: rem-calc(12) rem-calc(25) rem-calc(12) rem-calc(35);
            border-bottom: rem-calc(1) solid $c-ielts-border-grey;

            .icon-flag {
                display: block;
                position: absolute;
                top: rem-calc(25);
                left: rem-calc(3);
                
                img {
                    width: rem-calc(30);
                }
            }

            p {
                font-size: rem-calc(14);
                margin-bottom: rem-calc(12);

                &.location-name {
                    font-weight: bold;
                    margin-top: rem-calc(12);
                }
            }

            a {
                &.location-directions,
                &.location-url {
                    color: $c-ielts-red;

                    &:hover,
                    &:focus {
                        color: $c-ielts-black;
                    }
                }

                &.location-directions {
                    i {
                        position: relative;
                        top: rem-calc(2);
                    }
                }
            }

            &.is-hidden {
                display: none;
            }

            &.is-selected {
                background-color: $c-ielts-bg-grey;
                transition: all .2s ease-in;
            }
        }
    }
}
.map-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
    height: 0;
    margin-bottom: 20px;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
}