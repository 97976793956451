.content-tabs--styled {
    margin-top: rem-calc(40);
    
    .tabs {
        display: block;
        ul {
            border-bottom-width: 0;
        }
        li {
            a {
                border: rem-calc(1) solid #cccccc;
                background-color: $c-white;
                color: #999;
                &.is-active {
                    background-color: $c-ielts-border-grey;
                    color: $c-ielts-red;
                }
            }
        }
    }
    &.mobile-accordion {
        .item {
            &:before {
                border: rem-calc(1) solid #cccccc;
                background-color: $c-white;
                color: #999;
                margin-bottom: rem-calc(5);
            }
            &:after {
                color: #999;
            }
            &.is-active {
                &:before {
                    background-color: #cecece;
                    color: $c-ielts-red;
                }
                &:after {
                    color: $c-ielts-red;
                }
            }
        }
    }
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-up(md) {
        .tabs {
            border: rem-calc(1) solid #cccccc;
            box-shadow: rem-calc(1.5) rem-calc(1.5) rem-calc(5) #bbbbbb;
            padding: 0;
            margin-bottom: rem-calc(30);
            position: relative;
            .hover-marker {
                position: absolute;
                height: 100%;
                top: 0;
                display: none;
                @include hoverMarkerBorder();
                @include hoverMarkerSpan();
            }
            ul {
                border-bottom-width: 0;
                margin: 0;
            }
            li {
                border-right-width: 0;
                flex: 1;
                align-content: center;
                justify-content: center;
                a {
                    border-width: 0;
                    background-color: $c-white;
                    color: #999;
                    font-family: 'PFCentroSlabPro_Medium';
                    font-size: rem-calc(14);
                    text-align: center;
                    position: relative;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    padding: 0;
                    span {
                        padding: rem-calc(17) rem-calc(10);
                        border-right: rem-calc(1) solid #ccc;
                        width: 100%;
                    }
                    &:hover,
                    &:focus {
                        background-color: $c-white;
                        color: $c-ielts-black;
                    }
                    &.is-active {
                        background-color: $c-ielts-border-grey;
                        @include hoverMarkerBorder();
                        @include hoverMarkerSpan();
                        span {
                            color: $c-ielts-red;
                        }
                    }
                }
            }
            &.is-hover {
                .hover-marker {
                    display: block;
                }
                li {
                    a.is-active {
                        &:before,
                        &:after,
                        span:before,
                        span:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(lg) {
        .tabs {
            li {
                a {
                    font-size: rem-calc(20);
                }
            }
        }
    }
}