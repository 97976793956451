.global-search {
    display: flex;
    align-self: center;

    &.is-visible {
        .global-search__form {
            display: block;
        }
    }

    &__toggle {
        display: none;
        @include media-breakpoint-down(md) {
            display: block;
            background-color: $c-ielts-black;
            width: rem-calc(45);
            height: rem-calc(46);
            font-size: rem-calc(26);
            padding-top: rem-calc(4);
            &:after {
                content: '\f002';
                color: $c-white;
                position: relative;
                font-family: FontAwesome;
                display: block;
                right: auto;
                top: auto;
                transform: translateY(0);
                text-align: center;
                margin: 0;
                padding: 0 rem-calc(11);
            }
        }
    }

    input[type=text] {
        padding-right: rem-calc(40);
        width: 100%;
    }

    &__form {
        @include media-breakpoint-down(md) {
            position: absolute;
            top: rem-calc(72);
            width: 100%;
            padding: rem-calc(12);
            background-color: $c-ielts-black;
            left: 0;
            z-index: 1;
            display: none;
        }
        @include media-breakpoint-down(sm) {
            top: rem-calc(60);
        }
    }
    .form-inline {
        position: relative;
        
        .search-button {
            position: absolute;
            padding: 0;
            right: rem-calc(8);
            top: 0;
            input {
                height: rem-calc(36);
                width: rem-calc(40);
                text-indent: 9999px;
            }
            &:after {
                content: '\f002';
                font-family: FontAwesome;
                position: absolute;
                font-size: rem-calc(26);
                top: 0;
                right: 0;
                width: rem-calc(40);
                height: rem-calc(36);
                display: block;
                background-color: $c-white;
                color: $c-ielts-red;
                pointer-events: none;
            }
            @include media-breakpoint-down(md) {
                top: 0;
            }
        }
    }
}