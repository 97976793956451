.display-lang-options {
    .lang-options {
        display: block;
    }

    .lang-overlay {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        background: rgba($color: #000000, $alpha: .5);
        z-index: 100;
    }
}

.country-overlay.active {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: rgba($color: #000000, $alpha: .5);
    z-index: 100;
}

.country-options.active {
    display: block;
    z-index: 9999;
    position: absolute;    
}


.lang-options, 
.country-options {
    display: none;
    position: fixed;
    background-color: $c-white;
    width: 100%;
    z-index: 101;
    padding: rem-calc(30) 0;
    top: 0;

    p.lang-title {
        font-family: 'PFCentroSlabPro_Regular';
        font-size: rem-calc(36);
    }

    .site-lang-selector {
        display: inline-block;
        padding: rem-calc(10) 0 rem-calc(10) rem-calc(65);
        color: #333;
        text-decoration: none;
        margin-right: rem-calc(15);

        &.en-gb, &.en {
            background: url('../img/icons/icon-uk.png') no-repeat 0 0;
        }

        &.en-us {
            background: url('../img/icons/icon-us.png') no-repeat 0 0;
        }
    }
}

.cookie-banner {
    padding: rem-calc(30) 0;
    background-color: $c-ielts-black;
    color: $c-white;
    display: none;
    &.active {
        display: block;
    }
}