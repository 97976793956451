.panel {
    padding: rem-calc(25) 0;
    .card {
        border: none;
        border-radius: 0;
        background-color: transparent;
        .card-img-top {
            border-radius: 0;
        }
        .card-block {
            padding: rem-calc(15) 0;
        }
    }
    &.col-4-panel {
        .row {
            .card {
                @include media-breakpoint-down(sm) {
                    margin-bottom: rem-calc(12);
                }
            }
        }
        .title-section {
            border-bottom: rem-calc(2) solid $c-ielts-border-grey;
            margin: 0 rem-calc(15) rem-calc(20);
        }
        .video-wrapper {
            h2, .video-duration {
                display: none;
            }
            .video-player-content {
                top: 0;
                bottom: 0;
                left: 0;
                width: 100%;
            }

            .btn-play {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .purple {
            background-color: $c-ielts-purple;
            color: $white;
            &.card {
                text-align: center;
                justify-content: center;
                padding: 0 rem-calc(15);
            }
        }

        .col-md-3 {
            margin-bottom: rem-calc(12);
        }
    }
}
