@charset "UTF-8";
/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

*,
*::before,
*::after {
  box-sizing: inherit; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Lato", "Arial";
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: left; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 5px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #868e96; }

code,
kbd,
pre,
samp {
  font-family: "Lato", "Arial"; }

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f8f9fa;
  border-radius: 0; }
  a > code {
    padding: 0;
    color: inherit;
    background-color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #212529; }
  pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 0; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-mob-l-1, .col-mob-l-2, .col-mob-l-3, .col-mob-l-4, .col-mob-l-5, .col-mob-l-6, .col-mob-l-7, .col-mob-l-8, .col-mob-l-9, .col-mob-l-10, .col-mob-l-11, .col-mob-l-12, .col-mob-l,
.col-mob-l-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xl_plus-1, .col-xl_plus-2, .col-xl_plus-3, .col-xl_plus-4, .col-xl_plus-5, .col-xl_plus-6, .col-xl_plus-7, .col-xl_plus-8, .col-xl_plus-9, .col-xl_plus-10, .col-xl_plus-11, .col-xl_plus-12, .col-xl_plus,
.col-xl_plus-auto, .col-4k-1, .col-4k-2, .col-4k-3, .col-4k-4, .col-4k-5, .col-4k-6, .col-4k-7, .col-4k-8, .col-4k-9, .col-4k-10, .col-4k-11, .col-4k-12, .col-4k,
.col-4k-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

@media (min-width: 425px) {
  .col-mob-l {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-mob-l-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-mob-l-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-mob-l-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-mob-l-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-mob-l-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-mob-l-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-mob-l-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-mob-l-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-mob-l-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-mob-l-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-mob-l-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-mob-l-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-mob-l-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-mob-l-1 {
    order: 1; }
  .order-mob-l-2 {
    order: 2; }
  .order-mob-l-3 {
    order: 3; }
  .order-mob-l-4 {
    order: 4; }
  .order-mob-l-5 {
    order: 5; }
  .order-mob-l-6 {
    order: 6; }
  .order-mob-l-7 {
    order: 7; }
  .order-mob-l-8 {
    order: 8; }
  .order-mob-l-9 {
    order: 9; }
  .order-mob-l-10 {
    order: 10; }
  .order-mob-l-11 {
    order: 11; }
  .order-mob-l-12 {
    order: 12; } }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; } }

@media (min-width: 1440px) {
  .col-xl_plus {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl_plus-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl_plus-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl_plus-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl_plus-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl_plus-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl_plus-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl_plus-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl_plus-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl_plus-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl_plus-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl_plus-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl_plus-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl_plus-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl_plus-1 {
    order: 1; }
  .order-xl_plus-2 {
    order: 2; }
  .order-xl_plus-3 {
    order: 3; }
  .order-xl_plus-4 {
    order: 4; }
  .order-xl_plus-5 {
    order: 5; }
  .order-xl_plus-6 {
    order: 6; }
  .order-xl_plus-7 {
    order: 7; }
  .order-xl_plus-8 {
    order: 8; }
  .order-xl_plus-9 {
    order: 9; }
  .order-xl_plus-10 {
    order: 10; }
  .order-xl_plus-11 {
    order: 11; }
  .order-xl_plus-12 {
    order: 12; } }

@media (min-width: 2560px) {
  .col-4k {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-4k-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-4k-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-4k-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-4k-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-4k-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-4k-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-4k-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-4k-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-4k-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-4k-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-4k-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-4k-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-4k-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-4k-1 {
    order: 1; }
  .order-4k-2 {
    order: 2; }
  .order-4k-3 {
    order: 3; }
  .order-4k-4 {
    order: 4; }
  .order-4k-5 {
    order: 5; }
  .order-4k-6 {
    order: 6; }
  .order-4k-7 {
    order: 7; }
  .order-4k-8 {
    order: 8; }
  .order-4k-9 {
    order: 9; }
  .order-4k-10 {
    order: 10; }
  .order-4k-11 {
    order: 11; }
  .order-4k-12 {
    order: 12; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e9ecef; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e9ecef; }
  .table tbody + tbody {
    border-top: 2px solid #e9ecef; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #e9ecef; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e9ecef; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddfe2; }

.table-hover .table-secondary:hover {
  background-color: #cfd2d6; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #cfd2d6; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.thead-inverse th {
  color: #fff;
  background-color: #212529; }

.thead-default th {
  color: #495057;
  background-color: #e9ecef; }

.table-inverse {
  color: #fff;
  background-color: #212529; }
  .table-inverse th,
  .table-inverse td,
  .table-inverse thead th {
    border-color: #32383e; }
  .table-inverse.table-bordered {
    border: 0; }
  .table-inverse.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-inverse.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 991px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive.table-bordered {
      border: 0; } }

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: none; }
  .form-control::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block; }

.col-form-label {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  margin-bottom: 0; }

.col-form-label-lg {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem - 1px * 2);
  padding-bottom: calc(0.25rem - 1px * 2);
  font-size: 0.875rem; }

.col-form-legend {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem; }

.form-control-plaintext {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  line-height: 1.25;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .form-control-plaintext.input-group-addon,
  .input-group-sm > .input-group-btn > .form-control-plaintext.btn,
  .input-group-sm > .input-group-btn > .form-control-plaintext.black-button, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .form-control-plaintext.input-group-addon,
  .input-group-lg > .input-group-btn > .form-control-plaintext.btn,
  .input-group-lg > .input-group-btn > .form-control-plaintext.black-button {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn,
.input-group-sm > .input-group-btn > .black-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.black-button:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn,
.input-group-lg > .input-group-btn > .black-button {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.black-button:not([size]):not([multiple]) {
  height: calc(2.3125rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem; }
  .form-check.disabled .form-check-label {
    color: #868e96; }

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0; }

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem; }
  .form-check-input:only-child {
    position: static; }

.form-check-inline {
  display: inline-block; }
  .form-check-inline .form-check-label {
    vertical-align: middle; }
  .form-check-inline + .form-check-inline {
    margin-left: 0.75rem; }

.invalid-feedback {
  display: none;
  margin-top: .25rem;
  font-size: .875rem;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .invalid-feedback,
  .was-validated .form-control:valid ~ .invalid-tooltip, .form-control.is-valid ~ .invalid-feedback,
  .form-control.is-valid ~ .invalid-tooltip, .was-validated
  .custom-select:valid ~ .invalid-feedback,
  .was-validated
  .custom-select:valid ~ .invalid-tooltip,
  .custom-select.is-valid ~ .invalid-feedback,
  .custom-select.is-valid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-indicator, .custom-control-input.is-valid ~ .custom-control-indicator {
  background-color: rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description {
  color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-indicator, .custom-control-input.is-invalid ~ .custom-control-indicator {
  background-color: rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid ~ .custom-control-description, .custom-control-input.is-invalid ~ .custom-control-description {
  color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-control-label {
      margin-bottom: 0;
      vertical-align: middle; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      margin-top: 0;
      margin-bottom: 0; }
    .form-inline .form-check-label {
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0; }
    .form-inline .custom-control-indicator {
      position: static;
      display: inline-block;
      margin-right: 0.25rem;
      vertical-align: text-bottom; }
    .form-inline .has-feedback .form-control-feedback {
      top: 0; } }

.btn, .black-button {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0;
  transition: all 0.15s ease-in-out; }
  .btn:focus, .black-button:focus, .btn:hover, .black-button:hover {
    text-decoration: none; }
  .btn:focus, .black-button:focus, .btn.focus, .focus.black-button {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25); }
  .btn.disabled, .disabled.black-button, .btn:disabled, .black-button:disabled {
    opacity: .65; }
  .btn:active, .black-button:active, .btn.active, .active.black-button {
    background-image: none; }

a.btn.disabled, a.disabled.black-button,
fieldset[disabled] a.btn,
fieldset[disabled] a.black-button {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #0069d9;
    background-image: none;
    border-color: #0062cc; }

.btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #727b84;
    border-color: #6c757d; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #868e96;
    border-color: #868e96; }
  .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: #727b84;
    background-image: none;
    border-color: #6c757d; }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    background-color: #218838;
    background-image: none;
    border-color: #1e7e34; }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    background-color: #138496;
    background-image: none;
    border-color: #117a8b; }

.btn-warning {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #111;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    background-color: #e0a800;
    background-image: none;
    border-color: #d39e00; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    background-color: #c82333;
    background-image: none;
    border-color: #bd2130; }

.btn-light {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #111;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    background-color: #e2e6ea;
    background-image: none;
    border-color: #dae0e5; }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    background-color: #23272b;
    background-image: none;
    border-color: #1d2124; }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:active, .btn-outline-primary.active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #868e96;
    background-color: transparent; }
  .btn-outline-secondary:active, .btn-outline-secondary.active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:active, .btn-outline-success.active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:active, .btn-outline-info.active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:active, .btn-outline-warning.active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:active, .btn-outline-danger.active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:active, .btn-outline-light.active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:active, .btn-outline-dark.active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }

.btn-link {
  font-weight: normal;
  color: #007bff;
  border-radius: 0; }
  .btn-link, .btn-link:active, .btn-link.active, .btn-link:disabled {
    background-color: transparent; }
  .btn-link, .btn-link:focus, .btn-link:active {
    border-color: transparent;
    box-shadow: none; }
  .btn-link:hover {
    border-color: transparent; }
  .btn-link:focus, .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent; }
  .btn-link:disabled {
    color: #868e96; }
    .btn-link:disabled:focus, .btn-link:disabled:hover {
      text-decoration: none; }

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .black-button {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .black-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0; }
  .dropdown-item:focus, .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #868e96;
    background-color: transparent; }

.show > a {
  outline: 0; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .btn-group > .black-button,
  .btn-group-vertical > .btn,
  .btn-group-vertical > .black-button {
    position: relative;
    flex: 0 1 auto;
    margin-bottom: 0; }
    .btn-group > .btn:hover, .btn-group > .black-button:hover,
    .btn-group-vertical > .btn:hover,
    .btn-group-vertical > .black-button:hover {
      z-index: 2; }
    .btn-group > .btn:focus, .btn-group > .black-button:focus, .btn-group > .btn:active, .btn-group > .black-button:active, .btn-group > .btn.active, .btn-group > .active.black-button,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .black-button:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .black-button:active,
    .btn-group-vertical > .btn.active,
    .btn-group-vertical > .active.black-button {
      z-index: 2; }
  .btn-group .btn + .btn, .btn-group .black-button + .btn, .btn-group .btn + .black-button, .btn-group .black-button + .black-button,
  .btn-group .btn + .btn-group,
  .btn-group .black-button + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .black-button,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .black-button + .btn,
  .btn-group-vertical .btn + .black-button,
  .btn-group-vertical .black-button + .black-button,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .black-button + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .black-button,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle), .btn-group > .black-button:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0; }

.btn-group > .btn:first-child, .btn-group > .black-button:first-child {
  margin-left: 0; }
  .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle), .btn-group > .black-button:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.btn-group > .btn:last-child:not(:first-child), .btn-group > .black-button:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group > .btn-group {
  float: left; }

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn, .btn-group > .btn-group:not(:first-child):not(:last-child) > .black-button {
  border-radius: 0; }

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group > .btn-group:first-child:not(:last-child) > .black-button:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child, .btn-group > .btn-group:last-child:not(:first-child) > .black-button:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn + .dropdown-toggle-split, .black-button + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .btn + .dropdown-toggle-split::after, .black-button + .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-group-sm > .black-button + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-lg > .black-button + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn, .btn-group-vertical .black-button,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn, .btn-group-vertical > .black-button + .btn, .btn-group-vertical > .btn + .black-button, .btn-group-vertical > .black-button + .black-button,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .black-button + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .black-button,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }

.btn-group-vertical > .btn:not(:first-child):not(:last-child), .btn-group-vertical > .black-button:not(:first-child):not(:last-child) {
  border-radius: 0; }

.btn-group-vertical > .btn:first-child:not(:last-child), .btn-group-vertical > .black-button:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn:last-child:not(:first-child), .btn-group-vertical > .black-button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn, .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .black-button {
  border-radius: 0; }

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group-vertical > .btn-group:first-child:not(:last-child) > .black-button:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child, .btn-group-vertical > .btn-group:last-child:not(:first-child) > .black-button:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

[data-toggle="buttons"] > .btn input[type="radio"], [data-toggle="buttons"] > .black-button input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .black-button input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .black-button input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .black-button input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  width: 100%; }
  .input-group .form-control {
    position: relative;
    z-index: 2;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group .form-control:focus, .input-group .form-control:active, .input-group .form-control:hover {
      z-index: 3; }

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: flex;
  align-items: center; }
  .input-group-addon:not(:first-child):not(:last-child),
  .input-group-btn:not(:first-child):not(:last-child),
  .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0; }

.input-group-addon,
.input-group-btn {
  white-space: nowrap;
  vertical-align: middle; }

.input-group-addon {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }
  .input-group-addon.form-control-sm,
  .input-group-sm > .input-group-addon,
  .input-group-sm > .input-group-btn > .input-group-addon.btn,
  .input-group-sm > .input-group-btn > .input-group-addon.black-button {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem; }
  .input-group-addon.form-control-lg,
  .input-group-lg > .input-group-addon,
  .input-group-lg > .input-group-btn > .input-group-addon.btn,
  .input-group-lg > .input-group-btn > .input-group-addon.black-button {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem; }
  .input-group-addon input[type="radio"],
  .input-group-addon input[type="checkbox"] {
    margin-top: 0; }

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .black-button,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .btn-group > .black-button,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .black-button:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .black-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group-addon:not(:last-child) {
  border-right: 0; }

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .black-button,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .btn-group > .black-button,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .black-button:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .black-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0; }

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap; }
  .input-group-btn > .btn, .input-group-btn > .black-button {
    position: relative; }
    .input-group-btn > .btn + .btn, .input-group-btn > .black-button + .btn, .input-group-btn > .btn + .black-button, .input-group-btn > .black-button + .black-button {
      margin-left: -1px; }
    .input-group-btn > .btn:focus, .input-group-btn > .black-button:focus, .input-group-btn > .btn:active, .input-group-btn > .black-button:active, .input-group-btn > .btn:hover, .input-group-btn > .black-button:hover {
      z-index: 3; }
  .input-group-btn:not(:last-child) > .btn, .input-group-btn:not(:last-child) > .black-button,
  .input-group-btn:not(:last-child) > .btn-group {
    margin-right: -1px; }
  .input-group-btn:not(:first-child) > .btn, .input-group-btn:not(:first-child) > .black-button,
  .input-group-btn:not(:first-child) > .btn-group {
    z-index: 2;
    margin-left: -1px; }
    .input-group-btn:not(:first-child) > .btn:focus, .input-group-btn:not(:first-child) > .black-button:focus, .input-group-btn:not(:first-child) > .btn:active, .input-group-btn:not(:first-child) > .black-button:active, .input-group-btn:not(:first-child) > .btn:hover, .input-group-btn:not(:first-child) > .black-button:hover,
    .input-group-btn:not(:first-child) > .btn-group:focus,
    .input-group-btn:not(:first-child) > .btn-group:active,
    .input-group-btn:not(:first-child) > .btn-group:hover {
      z-index: 3; }

.custom-control {
  position: relative;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-indicator {
    color: #fff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-indicator {
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px #007bff; }
  .custom-control-input:active ~ .custom-control-indicator {
    color: #fff;
    background-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-indicator {
    background-color: #e9ecef; }
  .custom-control-input:disabled ~ .custom-control-description {
    color: #868e96; }

.custom-control-indicator {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%; }

.custom-checkbox .custom-control-indicator {
  border-radius: 0; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #007bff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-indicator {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-controls-stacked {
  display: flex;
  flex-direction: column; }
  .custom-controls-stacked .custom-control {
    margin-bottom: 0.25rem; }
    .custom-controls-stacked .custom-control + .custom-control {
      margin-left: 0; }

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.25;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: none; }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select:disabled {
    color: #868e96;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: 2.5rem;
  margin-bottom: 0; }

.custom-file-input {
  min-width: 14rem;
  max-width: 100%;
  height: 2.5rem;
  margin: 0;
  opacity: 0; }

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }
  .custom-file-control:lang(en):empty::after {
    content: "Choose file..."; }
  .custom-file-control::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 6;
    display: block;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #e9ecef;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0 0 0 0; }
  .custom-file-control:lang(en)::before {
    content: "Browse"; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:focus, .nav-link:hover {
    text-decoration: none; }
  .nav-link.disabled {
    color: #868e96; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
      border-color: #e9ecef #e9ecef #ddd; }
    .nav-tabs .nav-link.disabled {
      color: #868e96;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #ddd #ddd #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0; }
  .nav-pills .nav-link.active,
  .show > .nav-pills .nav-link {
    color: #fff;
    background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0; }
  .navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 424px) {
  .navbar-expand-mob-l > .container,
  .navbar-expand-mob-l > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 425px) {
  .navbar-expand-mob-l {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-mob-l .navbar-nav {
      flex-direction: row; }
      .navbar-expand-mob-l .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-mob-l .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-mob-l .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-mob-l > .container,
    .navbar-expand-mob-l > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-mob-l .navbar-collapse {
      display: flex !important; }
    .navbar-expand-mob-l .navbar-toggler {
      display: none; } }

@media (max-width: 575px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1439px) {
  .navbar-expand-xl_plus > .container,
  .navbar-expand-xl_plus > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1440px) {
  .navbar-expand-xl_plus {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl_plus .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl_plus .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl_plus .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl_plus .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-xl_plus > .container,
    .navbar-expand-xl_plus > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl_plus .navbar-collapse {
      display: flex !important; }
    .navbar-expand-xl_plus .navbar-toggler {
      display: none; } }

@media (max-width: 2559px) {
  .navbar-expand-4k > .container,
  .navbar-expand-4k > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 2560px) {
  .navbar-expand-4k {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-4k .navbar-nav {
      flex-direction: row; }
      .navbar-expand-4k .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-4k .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-4k .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-4k > .container,
    .navbar-expand-4k > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-4k .navbar-collapse {
      display: flex !important; }
    .navbar-expand-4k .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }

.navbar-dark .navbar-brand {
  color: white; }
  .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
    color: white; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: white; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0 - 1px) calc(0 - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0 - 1px) calc(0 - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0 - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0 - 1px);
  border-top-right-radius: calc(0 - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0 - 1px);
  border-bottom-left-radius: calc(0 - 1px); }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: 15px;
      margin-left: 15px; } }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group .card {
      flex: 1 0 0%; }
      .card-group .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group .card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group .card:first-child .card-img-top {
          border-top-right-radius: 0; }
        .card-group .card:first-child .card-img-bottom {
          border-bottom-right-radius: 0; }
      .card-group .card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group .card:last-child .card-img-top {
          border-top-left-radius: 0; }
        .card-group .card:last-child .card-img-bottom {
          border-bottom-left-radius: 0; }
      .card-group .card:not(:first-child):not(:last-child) {
        border-radius: 0; }
        .card-group .card:not(:first-child):not(:last-child) .card-img-top,
        .card-group .card:not(:first-child):not(:last-child) .card-img-bottom {
          border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0; }
  .breadcrumb::after {
    display: block;
    clear: both;
    content: ""; }

.breadcrumb-item {
  float: left; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #868e96;
    content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #868e96; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #868e96;
  pointer-events: none;
  background-color: #fff;
  border-color: #ddd; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #ddd; }
  .page-link:focus, .page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #ddd; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0; }
  .badge:empty {
    display: none; }

.btn .badge, .black-button .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  .badge-primary[href]:focus, .badge-primary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #868e96; }
  .badge-secondary[href]:focus, .badge-secondary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #6c757d; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  .badge-success[href]:focus, .badge-success[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  .badge-info[href]:focus, .badge-info[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #111;
  background-color: #ffc107; }
  .badge-warning[href]:focus, .badge-warning[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  .badge-danger[href]:focus, .badge-danger[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130; }

.badge-light {
  color: #111;
  background-color: #f8f9fa; }
  .badge-light[href]:focus, .badge-light[href]:hover {
    color: #111;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:focus, .badge-dark[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: bold; }

.alert-dismissible .close {
  position: relative;
  top: -0.75rem;
  right: -1.25rem;
  padding: 0.75rem 1.25rem;
  color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #464a4e;
  background-color: #e7e8ea;
  border-color: #dddfe2; }
  .alert-secondary hr {
    border-top-color: #cfd2d6; }
  .alert-secondary .alert-link {
    color: #2e3133; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  background-color: #e9ecef;
  border-radius: 0; }

.progress-bar {
  height: 1rem;
  line-height: 1rem;
  color: #fff;
  background-color: #007bff;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:focus, .list-group-item-action:hover {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-item:focus, .list-group-item:hover {
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #868e96;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }

a.list-group-item-primary,
button.list-group-item-primary {
  color: #004085; }
  a.list-group-item-primary:focus, a.list-group-item-primary:hover,
  button.list-group-item-primary:focus,
  button.list-group-item-primary:hover {
    color: #004085;
    background-color: #9fcdff; }
  a.list-group-item-primary.active,
  button.list-group-item-primary.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #464a4e;
  background-color: #dddfe2; }

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #464a4e; }
  a.list-group-item-secondary:focus, a.list-group-item-secondary:hover,
  button.list-group-item-secondary:focus,
  button.list-group-item-secondary:hover {
    color: #464a4e;
    background-color: #cfd2d6; }
  a.list-group-item-secondary.active,
  button.list-group-item-secondary.active {
    color: #fff;
    background-color: #464a4e;
    border-color: #464a4e; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }

a.list-group-item-success,
button.list-group-item-success {
  color: #155724; }
  a.list-group-item-success:focus, a.list-group-item-success:hover,
  button.list-group-item-success:focus,
  button.list-group-item-success:hover {
    color: #155724;
    background-color: #b1dfbb; }
  a.list-group-item-success.active,
  button.list-group-item-success.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }

a.list-group-item-info,
button.list-group-item-info {
  color: #0c5460; }
  a.list-group-item-info:focus, a.list-group-item-info:hover,
  button.list-group-item-info:focus,
  button.list-group-item-info:hover {
    color: #0c5460;
    background-color: #abdde5; }
  a.list-group-item-info.active,
  button.list-group-item-info.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }

a.list-group-item-warning,
button.list-group-item-warning {
  color: #856404; }
  a.list-group-item-warning:focus, a.list-group-item-warning:hover,
  button.list-group-item-warning:focus,
  button.list-group-item-warning:hover {
    color: #856404;
    background-color: #ffe8a1; }
  a.list-group-item-warning.active,
  button.list-group-item-warning.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }

a.list-group-item-danger,
button.list-group-item-danger {
  color: #721c24; }
  a.list-group-item-danger:focus, a.list-group-item-danger:hover,
  button.list-group-item-danger:focus,
  button.list-group-item-danger:hover {
    color: #721c24;
    background-color: #f1b0b7; }
  a.list-group-item-danger.active,
  button.list-group-item-danger.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }

a.list-group-item-light,
button.list-group-item-light {
  color: #818182; }
  a.list-group-item-light:focus, a.list-group-item-light:hover,
  button.list-group-item-light:focus,
  button.list-group-item-light:hover {
    color: #818182;
    background-color: #ececf6; }
  a.list-group-item-light.active,
  button.list-group-item-light.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }

a.list-group-item-dark,
button.list-group-item-dark {
  color: #1b1e21; }
  a.list-group-item-dark:focus, a.list-group-item-dark:hover,
  button.list-group-item-dark:focus,
  button.list-group-item-dark:hover {
    color: #1b1e21;
    background-color: #b9bbbe; }
  a.list-group-item-dark.active,
  button.list-group-item-dark.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:focus, .close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75; }

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e9ecef; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Lato", "Arial";
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 5px;
    height: 5px; }
  .tooltip.bs-tooltip-top, .tooltip.bs-tooltip-auto[x-placement^="top"] {
    padding: 5px 0; }
    .tooltip.bs-tooltip-top .arrow, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow {
      bottom: 0; }
    .tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
      margin-left: -3px;
      content: "";
      border-width: 5px 5px 0;
      border-top-color: #000; }
  .tooltip.bs-tooltip-right, .tooltip.bs-tooltip-auto[x-placement^="right"] {
    padding: 0 5px; }
    .tooltip.bs-tooltip-right .arrow, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow {
      left: 0; }
    .tooltip.bs-tooltip-right .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
      margin-top: -3px;
      content: "";
      border-width: 5px 5px 5px 0;
      border-right-color: #000; }
  .tooltip.bs-tooltip-bottom, .tooltip.bs-tooltip-auto[x-placement^="bottom"] {
    padding: 5px 0; }
    .tooltip.bs-tooltip-bottom .arrow, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow {
      top: 0; }
    .tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      margin-left: -3px;
      content: "";
      border-width: 0 5px 5px;
      border-bottom-color: #000; }
  .tooltip.bs-tooltip-left, .tooltip.bs-tooltip-auto[x-placement^="left"] {
    padding: 0 5px; }
    .tooltip.bs-tooltip-left .arrow, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow {
      right: 0; }
    .tooltip.bs-tooltip-left .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
      right: 0;
      margin-top: -3px;
      content: "";
      border-width: 5px 0 5px 5px;
      border-left-color: #000; }
  .tooltip .arrow::before {
    position: absolute;
    border-color: transparent;
    border-style: solid; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  padding: 1px;
  font-family: "Lato", "Arial";
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 10px;
    height: 5px; }
  .popover .arrow::before,
  .popover .arrow::after {
    position: absolute;
    display: block;
    border-color: transparent;
    border-style: solid; }
  .popover .arrow::before {
    content: "";
    border-width: 11px; }
  .popover .arrow::after {
    content: "";
    border-width: 11px; }
  .popover.bs-popover-top, .popover.bs-popover-auto[x-placement^="top"] {
    margin-bottom: 10px; }
    .popover.bs-popover-top .arrow, .popover.bs-popover-auto[x-placement^="top"] .arrow {
      bottom: 0; }
    .popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before,
    .popover.bs-popover-top .arrow::after, .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
      border-bottom-width: 0; }
    .popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before {
      bottom: -11px;
      margin-left: -6px;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .popover.bs-popover-top .arrow::after, .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
      bottom: -10px;
      margin-left: -6px;
      border-top-color: #fff; }
  .popover.bs-popover-right, .popover.bs-popover-auto[x-placement^="right"] {
    margin-left: 10px; }
    .popover.bs-popover-right .arrow, .popover.bs-popover-auto[x-placement^="right"] .arrow {
      left: 0; }
    .popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before,
    .popover.bs-popover-right .arrow::after, .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
      margin-top: -8px;
      border-left-width: 0; }
    .popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before {
      left: -11px;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .popover.bs-popover-right .arrow::after, .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
      left: -10px;
      border-right-color: #fff; }
  .popover.bs-popover-bottom, .popover.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 10px; }
    .popover.bs-popover-bottom .arrow, .popover.bs-popover-auto[x-placement^="bottom"] .arrow {
      top: 0; }
    .popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
    .popover.bs-popover-bottom .arrow::after, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      margin-left: -7px;
      border-top-width: 0; }
    .popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before {
      top: -11px;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .popover.bs-popover-bottom .arrow::after, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      top: -10px;
      border-bottom-color: #fff; }
    .popover.bs-popover-bottom .popover-header::before, .popover.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 20px;
      margin-left: -10px;
      content: "";
      border-bottom: 1px solid #f7f7f7; }
  .popover.bs-popover-left, .popover.bs-popover-auto[x-placement^="left"] {
    margin-right: 10px; }
    .popover.bs-popover-left .arrow, .popover.bs-popover-auto[x-placement^="left"] .arrow {
      right: 0; }
    .popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before,
    .popover.bs-popover-left .arrow::after, .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
      margin-top: -8px;
      border-right-width: 0; }
    .popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before {
      right: -11px;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .popover.bs-popover-left .arrow::after, .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
      right: -10px;
      border-left-color: #fff; }

.popover-header {
  padding: 8px 14px;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 9px 14px;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:focus, .carousel-control-prev:hover,
  .carousel-control-next:focus,
  .carousel-control-next:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #868e96 !important; }

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus, a.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus, a.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:focus, a.bg-light:hover {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-circle {
  border-radius: 50%; }

.rounded-0 {
  border-radius: 0; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 425px) {
  .d-mob-l-none {
    display: none !important; }
  .d-mob-l-inline {
    display: inline !important; }
  .d-mob-l-inline-block {
    display: inline-block !important; }
  .d-mob-l-block {
    display: block !important; }
  .d-mob-l-table {
    display: table !important; }
  .d-mob-l-table-cell {
    display: table-cell !important; }
  .d-mob-l-flex {
    display: flex !important; }
  .d-mob-l-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1440px) {
  .d-xl_plus-none {
    display: none !important; }
  .d-xl_plus-inline {
    display: inline !important; }
  .d-xl_plus-inline-block {
    display: inline-block !important; }
  .d-xl_plus-block {
    display: block !important; }
  .d-xl_plus-table {
    display: table !important; }
  .d-xl_plus-table-cell {
    display: table-cell !important; }
  .d-xl_plus-flex {
    display: flex !important; }
  .d-xl_plus-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 2560px) {
  .d-4k-none {
    display: none !important; }
  .d-4k-inline {
    display: inline !important; }
  .d-4k-inline-block {
    display: inline-block !important; }
  .d-4k-block {
    display: block !important; }
  .d-4k-table {
    display: table !important; }
  .d-4k-table-cell {
    display: table-cell !important; }
  .d-4k-flex {
    display: flex !important; }
  .d-4k-inline-flex {
    display: inline-flex !important; } }

.d-print-block {
  display: none !important; }
  @media print {
    .d-print-block {
      display: block !important; } }

.d-print-inline {
  display: none !important; }
  @media print {
    .d-print-inline {
      display: inline !important; } }

.d-print-inline-block {
  display: none !important; }
  @media print {
    .d-print-inline-block {
      display: inline-block !important; } }

@media print {
  .d-print-none {
    display: none !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 425px) {
  .flex-mob-l-row {
    flex-direction: row !important; }
  .flex-mob-l-column {
    flex-direction: column !important; }
  .flex-mob-l-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-mob-l-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-mob-l-wrap {
    flex-wrap: wrap !important; }
  .flex-mob-l-nowrap {
    flex-wrap: nowrap !important; }
  .flex-mob-l-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-mob-l-start {
    justify-content: flex-start !important; }
  .justify-content-mob-l-end {
    justify-content: flex-end !important; }
  .justify-content-mob-l-center {
    justify-content: center !important; }
  .justify-content-mob-l-between {
    justify-content: space-between !important; }
  .justify-content-mob-l-around {
    justify-content: space-around !important; }
  .align-items-mob-l-start {
    align-items: flex-start !important; }
  .align-items-mob-l-end {
    align-items: flex-end !important; }
  .align-items-mob-l-center {
    align-items: center !important; }
  .align-items-mob-l-baseline {
    align-items: baseline !important; }
  .align-items-mob-l-stretch {
    align-items: stretch !important; }
  .align-content-mob-l-start {
    align-content: flex-start !important; }
  .align-content-mob-l-end {
    align-content: flex-end !important; }
  .align-content-mob-l-center {
    align-content: center !important; }
  .align-content-mob-l-between {
    align-content: space-between !important; }
  .align-content-mob-l-around {
    align-content: space-around !important; }
  .align-content-mob-l-stretch {
    align-content: stretch !important; }
  .align-self-mob-l-auto {
    align-self: auto !important; }
  .align-self-mob-l-start {
    align-self: flex-start !important; }
  .align-self-mob-l-end {
    align-self: flex-end !important; }
  .align-self-mob-l-center {
    align-self: center !important; }
  .align-self-mob-l-baseline {
    align-self: baseline !important; }
  .align-self-mob-l-stretch {
    align-self: stretch !important; } }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1440px) {
  .flex-xl_plus-row {
    flex-direction: row !important; }
  .flex-xl_plus-column {
    flex-direction: column !important; }
  .flex-xl_plus-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl_plus-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl_plus-wrap {
    flex-wrap: wrap !important; }
  .flex-xl_plus-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl_plus-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl_plus-start {
    justify-content: flex-start !important; }
  .justify-content-xl_plus-end {
    justify-content: flex-end !important; }
  .justify-content-xl_plus-center {
    justify-content: center !important; }
  .justify-content-xl_plus-between {
    justify-content: space-between !important; }
  .justify-content-xl_plus-around {
    justify-content: space-around !important; }
  .align-items-xl_plus-start {
    align-items: flex-start !important; }
  .align-items-xl_plus-end {
    align-items: flex-end !important; }
  .align-items-xl_plus-center {
    align-items: center !important; }
  .align-items-xl_plus-baseline {
    align-items: baseline !important; }
  .align-items-xl_plus-stretch {
    align-items: stretch !important; }
  .align-content-xl_plus-start {
    align-content: flex-start !important; }
  .align-content-xl_plus-end {
    align-content: flex-end !important; }
  .align-content-xl_plus-center {
    align-content: center !important; }
  .align-content-xl_plus-between {
    align-content: space-between !important; }
  .align-content-xl_plus-around {
    align-content: space-around !important; }
  .align-content-xl_plus-stretch {
    align-content: stretch !important; }
  .align-self-xl_plus-auto {
    align-self: auto !important; }
  .align-self-xl_plus-start {
    align-self: flex-start !important; }
  .align-self-xl_plus-end {
    align-self: flex-end !important; }
  .align-self-xl_plus-center {
    align-self: center !important; }
  .align-self-xl_plus-baseline {
    align-self: baseline !important; }
  .align-self-xl_plus-stretch {
    align-self: stretch !important; } }

@media (min-width: 2560px) {
  .flex-4k-row {
    flex-direction: row !important; }
  .flex-4k-column {
    flex-direction: column !important; }
  .flex-4k-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-4k-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-4k-wrap {
    flex-wrap: wrap !important; }
  .flex-4k-nowrap {
    flex-wrap: nowrap !important; }
  .flex-4k-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-4k-start {
    justify-content: flex-start !important; }
  .justify-content-4k-end {
    justify-content: flex-end !important; }
  .justify-content-4k-center {
    justify-content: center !important; }
  .justify-content-4k-between {
    justify-content: space-between !important; }
  .justify-content-4k-around {
    justify-content: space-around !important; }
  .align-items-4k-start {
    align-items: flex-start !important; }
  .align-items-4k-end {
    align-items: flex-end !important; }
  .align-items-4k-center {
    align-items: center !important; }
  .align-items-4k-baseline {
    align-items: baseline !important; }
  .align-items-4k-stretch {
    align-items: stretch !important; }
  .align-content-4k-start {
    align-content: flex-start !important; }
  .align-content-4k-end {
    align-content: flex-end !important; }
  .align-content-4k-center {
    align-content: center !important; }
  .align-content-4k-between {
    align-content: space-between !important; }
  .align-content-4k-around {
    align-content: space-around !important; }
  .align-content-4k-stretch {
    align-content: stretch !important; }
  .align-self-4k-auto {
    align-self: auto !important; }
  .align-self-4k-start {
    align-self: flex-start !important; }
  .align-self-4k-end {
    align-self: flex-end !important; }
  .align-self-4k-center {
    align-self: center !important; }
  .align-self-4k-baseline {
    align-self: baseline !important; }
  .align-self-4k-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 425px) {
  .float-mob-l-left {
    float: left !important; }
  .float-mob-l-right {
    float: right !important; }
  .float-mob-l-none {
    float: none !important; } }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1440px) {
  .float-xl_plus-left {
    float: left !important; }
  .float-xl_plus-right {
    float: right !important; }
  .float-xl_plus-none {
    float: none !important; } }

@media (min-width: 2560px) {
  .float-4k-left {
    float: left !important; }
  .float-4k-right {
    float: right !important; }
  .float-4k-none {
    float: none !important; } }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pr-1 {
  padding-right: 0.25rem !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pl-1 {
  padding-left: 0.25rem !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pr-2 {
  padding-right: 0.5rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pl-2 {
  padding-left: 0.5rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pr-3 {
  padding-right: 1rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pl-3 {
  padding-left: 1rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pr-4 {
  padding-right: 1.5rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pl-4 {
  padding-left: 1.5rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pr-5 {
  padding-right: 3rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pl-5 {
  padding-left: 3rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-auto {
  margin-left: auto !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

@media (min-width: 425px) {
  .m-mob-l-0 {
    margin: 0 !important; }
  .mt-mob-l-0 {
    margin-top: 0 !important; }
  .mr-mob-l-0 {
    margin-right: 0 !important; }
  .mb-mob-l-0 {
    margin-bottom: 0 !important; }
  .ml-mob-l-0 {
    margin-left: 0 !important; }
  .mx-mob-l-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-mob-l-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-mob-l-1 {
    margin: 0.25rem !important; }
  .mt-mob-l-1 {
    margin-top: 0.25rem !important; }
  .mr-mob-l-1 {
    margin-right: 0.25rem !important; }
  .mb-mob-l-1 {
    margin-bottom: 0.25rem !important; }
  .ml-mob-l-1 {
    margin-left: 0.25rem !important; }
  .mx-mob-l-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-mob-l-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-mob-l-2 {
    margin: 0.5rem !important; }
  .mt-mob-l-2 {
    margin-top: 0.5rem !important; }
  .mr-mob-l-2 {
    margin-right: 0.5rem !important; }
  .mb-mob-l-2 {
    margin-bottom: 0.5rem !important; }
  .ml-mob-l-2 {
    margin-left: 0.5rem !important; }
  .mx-mob-l-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-mob-l-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-mob-l-3 {
    margin: 1rem !important; }
  .mt-mob-l-3 {
    margin-top: 1rem !important; }
  .mr-mob-l-3 {
    margin-right: 1rem !important; }
  .mb-mob-l-3 {
    margin-bottom: 1rem !important; }
  .ml-mob-l-3 {
    margin-left: 1rem !important; }
  .mx-mob-l-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-mob-l-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-mob-l-4 {
    margin: 1.5rem !important; }
  .mt-mob-l-4 {
    margin-top: 1.5rem !important; }
  .mr-mob-l-4 {
    margin-right: 1.5rem !important; }
  .mb-mob-l-4 {
    margin-bottom: 1.5rem !important; }
  .ml-mob-l-4 {
    margin-left: 1.5rem !important; }
  .mx-mob-l-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-mob-l-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-mob-l-5 {
    margin: 3rem !important; }
  .mt-mob-l-5 {
    margin-top: 3rem !important; }
  .mr-mob-l-5 {
    margin-right: 3rem !important; }
  .mb-mob-l-5 {
    margin-bottom: 3rem !important; }
  .ml-mob-l-5 {
    margin-left: 3rem !important; }
  .mx-mob-l-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-mob-l-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-mob-l-0 {
    padding: 0 !important; }
  .pt-mob-l-0 {
    padding-top: 0 !important; }
  .pr-mob-l-0 {
    padding-right: 0 !important; }
  .pb-mob-l-0 {
    padding-bottom: 0 !important; }
  .pl-mob-l-0 {
    padding-left: 0 !important; }
  .px-mob-l-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-mob-l-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-mob-l-1 {
    padding: 0.25rem !important; }
  .pt-mob-l-1 {
    padding-top: 0.25rem !important; }
  .pr-mob-l-1 {
    padding-right: 0.25rem !important; }
  .pb-mob-l-1 {
    padding-bottom: 0.25rem !important; }
  .pl-mob-l-1 {
    padding-left: 0.25rem !important; }
  .px-mob-l-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-mob-l-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-mob-l-2 {
    padding: 0.5rem !important; }
  .pt-mob-l-2 {
    padding-top: 0.5rem !important; }
  .pr-mob-l-2 {
    padding-right: 0.5rem !important; }
  .pb-mob-l-2 {
    padding-bottom: 0.5rem !important; }
  .pl-mob-l-2 {
    padding-left: 0.5rem !important; }
  .px-mob-l-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-mob-l-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-mob-l-3 {
    padding: 1rem !important; }
  .pt-mob-l-3 {
    padding-top: 1rem !important; }
  .pr-mob-l-3 {
    padding-right: 1rem !important; }
  .pb-mob-l-3 {
    padding-bottom: 1rem !important; }
  .pl-mob-l-3 {
    padding-left: 1rem !important; }
  .px-mob-l-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-mob-l-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-mob-l-4 {
    padding: 1.5rem !important; }
  .pt-mob-l-4 {
    padding-top: 1.5rem !important; }
  .pr-mob-l-4 {
    padding-right: 1.5rem !important; }
  .pb-mob-l-4 {
    padding-bottom: 1.5rem !important; }
  .pl-mob-l-4 {
    padding-left: 1.5rem !important; }
  .px-mob-l-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-mob-l-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-mob-l-5 {
    padding: 3rem !important; }
  .pt-mob-l-5 {
    padding-top: 3rem !important; }
  .pr-mob-l-5 {
    padding-right: 3rem !important; }
  .pb-mob-l-5 {
    padding-bottom: 3rem !important; }
  .pl-mob-l-5 {
    padding-left: 3rem !important; }
  .px-mob-l-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-mob-l-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-mob-l-auto {
    margin: auto !important; }
  .mt-mob-l-auto {
    margin-top: auto !important; }
  .mr-mob-l-auto {
    margin-right: auto !important; }
  .mb-mob-l-auto {
    margin-bottom: auto !important; }
  .ml-mob-l-auto {
    margin-left: auto !important; }
  .mx-mob-l-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-mob-l-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1 {
    margin-left: 0.25rem !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2 {
    margin-left: 0.5rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3 {
    margin-left: 1rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4 {
    margin-left: 1.5rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5 {
    margin-left: 3rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1 {
    padding-left: 0.25rem !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2 {
    padding-left: 0.5rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3 {
    padding-left: 1rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4 {
    padding-left: 1.5rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5 {
    padding-left: 3rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1 {
    margin-left: 0.25rem !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2 {
    margin-left: 0.5rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3 {
    margin-left: 1rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5 {
    margin-left: 3rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1 {
    padding-left: 0.25rem !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2 {
    padding-left: 0.5rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4 {
    padding-left: 1.5rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5 {
    padding-left: 3rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1 {
    margin-left: 0.25rem !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2 {
    margin-left: 0.5rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3 {
    margin-left: 1rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4 {
    margin-left: 1.5rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5 {
    margin-left: 3rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1 {
    padding-left: 0.25rem !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2 {
    padding-left: 0.5rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3 {
    padding-left: 1rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4 {
    padding-left: 1.5rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5 {
    padding-left: 3rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0 {
    margin-left: 0 !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1 {
    margin-left: 0.25rem !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2 {
    margin-left: 0.5rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3 {
    margin-left: 1rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4 {
    margin-left: 1.5rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5 {
    margin-left: 3rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0 {
    padding-left: 0 !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1 {
    padding-left: 0.25rem !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2 {
    padding-left: 0.5rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3 {
    padding-left: 1rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4 {
    padding-left: 1.5rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5 {
    padding-left: 3rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto {
    margin-left: auto !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 1440px) {
  .m-xl_plus-0 {
    margin: 0 !important; }
  .mt-xl_plus-0 {
    margin-top: 0 !important; }
  .mr-xl_plus-0 {
    margin-right: 0 !important; }
  .mb-xl_plus-0 {
    margin-bottom: 0 !important; }
  .ml-xl_plus-0 {
    margin-left: 0 !important; }
  .mx-xl_plus-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-xl_plus-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-xl_plus-1 {
    margin: 0.25rem !important; }
  .mt-xl_plus-1 {
    margin-top: 0.25rem !important; }
  .mr-xl_plus-1 {
    margin-right: 0.25rem !important; }
  .mb-xl_plus-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl_plus-1 {
    margin-left: 0.25rem !important; }
  .mx-xl_plus-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-xl_plus-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-xl_plus-2 {
    margin: 0.5rem !important; }
  .mt-xl_plus-2 {
    margin-top: 0.5rem !important; }
  .mr-xl_plus-2 {
    margin-right: 0.5rem !important; }
  .mb-xl_plus-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl_plus-2 {
    margin-left: 0.5rem !important; }
  .mx-xl_plus-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-xl_plus-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-xl_plus-3 {
    margin: 1rem !important; }
  .mt-xl_plus-3 {
    margin-top: 1rem !important; }
  .mr-xl_plus-3 {
    margin-right: 1rem !important; }
  .mb-xl_plus-3 {
    margin-bottom: 1rem !important; }
  .ml-xl_plus-3 {
    margin-left: 1rem !important; }
  .mx-xl_plus-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-xl_plus-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-xl_plus-4 {
    margin: 1.5rem !important; }
  .mt-xl_plus-4 {
    margin-top: 1.5rem !important; }
  .mr-xl_plus-4 {
    margin-right: 1.5rem !important; }
  .mb-xl_plus-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl_plus-4 {
    margin-left: 1.5rem !important; }
  .mx-xl_plus-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-xl_plus-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-xl_plus-5 {
    margin: 3rem !important; }
  .mt-xl_plus-5 {
    margin-top: 3rem !important; }
  .mr-xl_plus-5 {
    margin-right: 3rem !important; }
  .mb-xl_plus-5 {
    margin-bottom: 3rem !important; }
  .ml-xl_plus-5 {
    margin-left: 3rem !important; }
  .mx-xl_plus-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-xl_plus-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-xl_plus-0 {
    padding: 0 !important; }
  .pt-xl_plus-0 {
    padding-top: 0 !important; }
  .pr-xl_plus-0 {
    padding-right: 0 !important; }
  .pb-xl_plus-0 {
    padding-bottom: 0 !important; }
  .pl-xl_plus-0 {
    padding-left: 0 !important; }
  .px-xl_plus-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-xl_plus-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-xl_plus-1 {
    padding: 0.25rem !important; }
  .pt-xl_plus-1 {
    padding-top: 0.25rem !important; }
  .pr-xl_plus-1 {
    padding-right: 0.25rem !important; }
  .pb-xl_plus-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl_plus-1 {
    padding-left: 0.25rem !important; }
  .px-xl_plus-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-xl_plus-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-xl_plus-2 {
    padding: 0.5rem !important; }
  .pt-xl_plus-2 {
    padding-top: 0.5rem !important; }
  .pr-xl_plus-2 {
    padding-right: 0.5rem !important; }
  .pb-xl_plus-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl_plus-2 {
    padding-left: 0.5rem !important; }
  .px-xl_plus-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-xl_plus-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-xl_plus-3 {
    padding: 1rem !important; }
  .pt-xl_plus-3 {
    padding-top: 1rem !important; }
  .pr-xl_plus-3 {
    padding-right: 1rem !important; }
  .pb-xl_plus-3 {
    padding-bottom: 1rem !important; }
  .pl-xl_plus-3 {
    padding-left: 1rem !important; }
  .px-xl_plus-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-xl_plus-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-xl_plus-4 {
    padding: 1.5rem !important; }
  .pt-xl_plus-4 {
    padding-top: 1.5rem !important; }
  .pr-xl_plus-4 {
    padding-right: 1.5rem !important; }
  .pb-xl_plus-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl_plus-4 {
    padding-left: 1.5rem !important; }
  .px-xl_plus-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-xl_plus-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-xl_plus-5 {
    padding: 3rem !important; }
  .pt-xl_plus-5 {
    padding-top: 3rem !important; }
  .pr-xl_plus-5 {
    padding-right: 3rem !important; }
  .pb-xl_plus-5 {
    padding-bottom: 3rem !important; }
  .pl-xl_plus-5 {
    padding-left: 3rem !important; }
  .px-xl_plus-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl_plus-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-xl_plus-auto {
    margin: auto !important; }
  .mt-xl_plus-auto {
    margin-top: auto !important; }
  .mr-xl_plus-auto {
    margin-right: auto !important; }
  .mb-xl_plus-auto {
    margin-bottom: auto !important; }
  .ml-xl_plus-auto {
    margin-left: auto !important; }
  .mx-xl_plus-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl_plus-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 2560px) {
  .m-4k-0 {
    margin: 0 !important; }
  .mt-4k-0 {
    margin-top: 0 !important; }
  .mr-4k-0 {
    margin-right: 0 !important; }
  .mb-4k-0 {
    margin-bottom: 0 !important; }
  .ml-4k-0 {
    margin-left: 0 !important; }
  .mx-4k-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-4k-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-4k-1 {
    margin: 0.25rem !important; }
  .mt-4k-1 {
    margin-top: 0.25rem !important; }
  .mr-4k-1 {
    margin-right: 0.25rem !important; }
  .mb-4k-1 {
    margin-bottom: 0.25rem !important; }
  .ml-4k-1 {
    margin-left: 0.25rem !important; }
  .mx-4k-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-4k-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-4k-2 {
    margin: 0.5rem !important; }
  .mt-4k-2 {
    margin-top: 0.5rem !important; }
  .mr-4k-2 {
    margin-right: 0.5rem !important; }
  .mb-4k-2 {
    margin-bottom: 0.5rem !important; }
  .ml-4k-2 {
    margin-left: 0.5rem !important; }
  .mx-4k-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-4k-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-4k-3 {
    margin: 1rem !important; }
  .mt-4k-3 {
    margin-top: 1rem !important; }
  .mr-4k-3 {
    margin-right: 1rem !important; }
  .mb-4k-3 {
    margin-bottom: 1rem !important; }
  .ml-4k-3 {
    margin-left: 1rem !important; }
  .mx-4k-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-4k-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-4k-4 {
    margin: 1.5rem !important; }
  .mt-4k-4 {
    margin-top: 1.5rem !important; }
  .mr-4k-4 {
    margin-right: 1.5rem !important; }
  .mb-4k-4 {
    margin-bottom: 1.5rem !important; }
  .ml-4k-4 {
    margin-left: 1.5rem !important; }
  .mx-4k-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-4k-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-4k-5 {
    margin: 3rem !important; }
  .mt-4k-5 {
    margin-top: 3rem !important; }
  .mr-4k-5 {
    margin-right: 3rem !important; }
  .mb-4k-5 {
    margin-bottom: 3rem !important; }
  .ml-4k-5 {
    margin-left: 3rem !important; }
  .mx-4k-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-4k-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-4k-0 {
    padding: 0 !important; }
  .pt-4k-0 {
    padding-top: 0 !important; }
  .pr-4k-0 {
    padding-right: 0 !important; }
  .pb-4k-0 {
    padding-bottom: 0 !important; }
  .pl-4k-0 {
    padding-left: 0 !important; }
  .px-4k-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-4k-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-4k-1 {
    padding: 0.25rem !important; }
  .pt-4k-1 {
    padding-top: 0.25rem !important; }
  .pr-4k-1 {
    padding-right: 0.25rem !important; }
  .pb-4k-1 {
    padding-bottom: 0.25rem !important; }
  .pl-4k-1 {
    padding-left: 0.25rem !important; }
  .px-4k-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-4k-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-4k-2 {
    padding: 0.5rem !important; }
  .pt-4k-2 {
    padding-top: 0.5rem !important; }
  .pr-4k-2 {
    padding-right: 0.5rem !important; }
  .pb-4k-2 {
    padding-bottom: 0.5rem !important; }
  .pl-4k-2 {
    padding-left: 0.5rem !important; }
  .px-4k-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-4k-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-4k-3 {
    padding: 1rem !important; }
  .pt-4k-3 {
    padding-top: 1rem !important; }
  .pr-4k-3 {
    padding-right: 1rem !important; }
  .pb-4k-3 {
    padding-bottom: 1rem !important; }
  .pl-4k-3 {
    padding-left: 1rem !important; }
  .px-4k-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-4k-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-4k-4 {
    padding: 1.5rem !important; }
  .pt-4k-4 {
    padding-top: 1.5rem !important; }
  .pr-4k-4 {
    padding-right: 1.5rem !important; }
  .pb-4k-4 {
    padding-bottom: 1.5rem !important; }
  .pl-4k-4 {
    padding-left: 1.5rem !important; }
  .px-4k-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-4k-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-4k-5 {
    padding: 3rem !important; }
  .pt-4k-5 {
    padding-top: 3rem !important; }
  .pr-4k-5 {
    padding-right: 3rem !important; }
  .pb-4k-5 {
    padding-bottom: 3rem !important; }
  .pl-4k-5 {
    padding-left: 3rem !important; }
  .px-4k-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-4k-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-4k-auto {
    margin: auto !important; }
  .mt-4k-auto {
    margin-top: auto !important; }
  .mr-4k-auto {
    margin-right: auto !important; }
  .mb-4k-auto {
    margin-bottom: auto !important; }
  .ml-4k-auto {
    margin-left: auto !important; }
  .mx-4k-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-4k-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 425px) {
  .text-mob-l-left {
    text-align: left !important; }
  .text-mob-l-right {
    text-align: right !important; }
  .text-mob-l-center {
    text-align: center !important; } }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1440px) {
  .text-xl_plus-left {
    text-align: left !important; }
  .text-xl_plus-right {
    text-align: right !important; }
  .text-xl_plus-center {
    text-align: center !important; } }

@media (min-width: 2560px) {
  .text-4k-left {
    text-align: left !important; }
  .text-4k-right {
    text-align: right !important; }
  .text-4k-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-normal {
  font-weight: normal; }

.font-weight-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important; }

.text-secondary {
  color: #868e96 !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #6c757d !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important; }

.text-muted {
  color: #868e96 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.ui-datepicker {
  display: none;
  padding: 8px;
  z-index: 1005 !important; }

.ui-datepicker-header {
  background-color: #f4f4f4;
  height: 40px;
  font-size: em(13px);
  color: #fff; }
  .ui-datepicker-header a {
    width: 36px;
    height: 40px;
    cursor: pointer; }
    .ui-datepicker-header a:hover {
      background-color: #e7e7e7; }
    .ui-datepicker-header a.ui-state-disabled {
      background: none;
      cursor: default; }

.ui-datepicker-title {
  font-weight: bold;
  text-align: center;
  line-height: 40px; }

.ui-datepicker-prev {
  float: left;
  border-right: 1px solid #fff;
  background: url(../img/icons/icon-arw-lft-wht.png) no-repeat center; }

.ui-datepicker-next {
  float: right;
  border-left: 1px solid #fff;
  background: url(../img/icons/icon-arw-rght-wht.png) no-repeat center; }

.ui-datepicker-month, .ui-datepicker-year {
  width: 65px;
  height: 30px;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: solid 1px #e5e7e8;
  padding: 2px 20px 2px 2px;
  margin: 5px 0 0 0;
  font-family: "soleil", sans-serif;
  font-weight: 400;
  font-size: 1em;
  -webkit-appearance: none;
  /*Removes default chrome and safari style*/
  -moz-appearance: none;
  /* Removes Default Firefox style*/
  background: #fff url(../img/icons/icon--ui-cal-down.png) no-repeat;
  /*Adds background-image*/
  background-position: right 10px;
  /*Position of the background-image*/
  text-indent: 0.01px;
  /* Removes default arrow from firefox*/
  text-overflow: "";
  /*Removes default arrow from firefox*/ }

.ui-datepicker-month::-ms-expand, .ui-datepicker-year::-ms-expand {
  display: none; }

.no-csscolumns .ui-datepicker-month, .no-csscolumns .ui-datepicker-year {
  background: #fff;
  padding: 5px; }

.ui-datepicker-month {
  margin-right: 15px; }

.ui-datepicker-calendar {
  border: 0;
  margin: 0;
  display: table;
  table-layout: auto; }
  .ui-datepicker-calendar thead {
    display: table-header-group;
    float: none; }
  .ui-datepicker-calendar tbody {
    display: table-row-group;
    white-space: normal; }
  .ui-datepicker-calendar thead tr,
  .ui-datepicker-calendar tbody tr {
    display: table-row; }
  .ui-datepicker-calendar th,
  .ui-datepicker-calendar td {
    background-color: #ff0000;
    border: 1px solid #d9d9d9;
    font-weight: bold;
    font-size: em(13px);
    padding: 0;
    width: 35px;
    height: 35px;
    text-align: center;
    vertical-align: middle;
    display: table-cell; }
    .ui-datepicker-calendar th.ui-datepicker-today,
    .ui-datepicker-calendar td.ui-datepicker-today {
      background: #f4f4f4; }
      .ui-datepicker-calendar th.ui-datepicker-today a,
      .ui-datepicker-calendar td.ui-datepicker-today a {
        color: #fff; }
  .ui-datepicker-calendar th {
    cursor: default; }
  .ui-datepicker-calendar td {
    background-color: #fefefe; }
    .ui-datepicker-calendar td a {
      color: #ddd;
      display: table-cell;
      width: 32px;
      height: 32px;
      vertical-align: middle; }

.ui-datepicker-unselectable {
  color: #999;
  cursor: default; }

.ui-datepicker-calendar .ui-datepicker-other-month a {
  color: #999; }

td.ui-datepicker-current-day a {
  border: 2px solid #1c6802; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }
  .slick-slide:focus {
    outline: none; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/icons/icon-loader.svg") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.75; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0 0 40px 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.75;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: red;
      opacity: 1; }

@font-face {
  font-family: 'PFCentroSlabPro_Regular';
  src: url("../fonts/PFCentroSlabPro/PFCentroSlabPro_Regular.eot");
  src: url("../fonts/PFCentroSlabPro/TPFCentroSlabPro_Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/PFCentroSlabPro/PFCentroSlabPro_Regular.woff") format("woff"), url("../fonts/PFCentroSlabPro/PFCentroSlabPro_Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'PFCentroSlabPro_Medium';
  src: url("../fonts/PFCentroSlabPro/PFCentroSlabPro_Medium.eot");
  src: url("../fonts/PFCentroSlabPro/PFCentroSlabPro_Medium?#iefix") format("embedded-opentype"), url("../fonts/PFCentroSlabPro/PFCentroSlabPro_Medium.woff") format("woff"), url("../fonts/PFCentroSlabPro/PFCentroSlabPro_Medium.ttf") format("truetype");
  font-weight: medium;
  font-style: normal; }

::selection {
  background: #dc0b21;
  /* WebKit/Blink Browsers */
  color: #fff; }

::-moz-selection {
  background: #dc0b21;
  /* Gecko Browsers */
  color: #fff; }

html, body {
  overflow-x: hidden; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased; }

body {
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  letter-spacing: .5px;
  line-height: 1.5;
  color: #333333;
  overflow-x: hidden;
  position: relative; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #333333;
  letter-spacing: -0.5px; }

h1 {
  color: #dc0b21; }

strong {
  font-weight: 700; }

a {
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  a[target="_blank"] {
    padding-right: 1.5625rem;
    position: relative; }
    a[target="_blank"]:after {
      content: '\f08e';
      height: 0.75rem;
      font-family: FontAwesome;
      display: block;
      position: absolute;
      right: 0.125rem;
      bottom: 0.5rem;
      width: 0.75rem; }
    a[target="_blank"].btn:after, a[target="_blank"].black-button:after {
      bottom: 0.9375rem; }
    a[target="_blank"].btn-default:after, a[target="_blank"].black-button:after {
      display: none; }

body, a {
  color: #333333; }

h1 {
  font-size: 2.3rem; }

h2 {
  font-size: 1.6rem; }

h3 {
  font-size: 1.38rem;
  line-height: normal; }

li {
  list-style-type: none; }

a:hover {
  color: #dc0b21;
  transition: color .3s ease-in;
  text-decoration: none; }

.title {
  color: #dc0b21; }

.main-nav .navbar-nav, .card-text, .title, h1, h2, h3 {
  font-family: 'PFCentroSlabPro_Medium'; }

.form-control {
  border-radius: 0; }

.form-select {
  position: relative; }
  .form-select .custom-select {
    border-color: #333333; }
  .form-select:after {
    content: '\f078';
    font-family: FontAwesome;
    color: #FFFFFF;
    position: absolute;
    top: 0.0625rem;
    right: 0;
    width: 2.25rem;
    height: 2.25rem;
    display: block;
    background: #333333;
    line-height: 2.25rem;
    text-align: center;
    pointer-events: none; }

.bg-grey {
  background-color: #f1f2ed;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece; }

.bg-purple {
  background-color: #661357;
  border-top: 1px solid #661357;
  border-bottom: 1px solid #661357; }

.container-fluid {
  margin-bottom: 1.875rem; }
  @media (max-width: 767px) {
    .container-fluid .container {
      padding-left: 0;
      padding-right: 0; } }

.container:after {
  content: "";
  display: table;
  clear: both; }

body.ielts-online-training {
  background-color: #f1f2ed; }
  body.ielts-online-training .boxed-layout {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1.875rem;
    background-color: #FFFFFF;
    box-shadow: 0 0 0.75rem -0.125rem rgba(0, 0, 0, 0.75);
    margin-bottom: 12px;
    max-height: calc(100vh - 20px);
    min-height: calc(100vh - 20px); }
    body.ielts-online-training .boxed-layout .btn-menu {
      position: absolute;
      right: 0.9375rem;
      top: 1.875rem; }

.nav-container {
  background-color: #333333;
  position: relative;
  z-index: 5; }
  @media (max-width: 991px) {
    .nav-container {
      width: auto;
      display: flex;
      align-items: center; } }
  @media (max-width: 991px) {
    .nav-container {
      background-color: transparent; } }
  .nav-container .nav-toggle {
    display: none; }
    @media (max-width: 991px) {
      .nav-container .nav-toggle {
        display: block;
        color: #FFFFFF;
        width: 5.625rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #333333;
        padding: 0.6875rem 0; }
        .nav-container .nav-toggle .fa {
          color: #FFFFFF; } }
    @media (max-width: 424px) {
      .nav-container .nav-toggle {
        width: 2.8125rem;
        justify-content: flex-start;
        padding-left: 0.875rem; }
        .nav-container .nav-toggle .fa {
          margin-right: 0.75rem; } }
    .nav-container .nav-toggle.is-open ~ ul {
      display: block; }
  .nav-container .main-nav {
    margin: 0 auto;
    z-index: 1000; }
    @media (max-width: 991px) {
      .nav-container .main-nav {
        padding-right: 0;
        position: relative; } }
    .nav-container .main-nav > ul {
      display: flex;
      flex-direction: row;
      text-align: center;
      padding: 0;
      margin: 0; }
      @media (max-width: 991px) {
        .nav-container .main-nav > ul {
          display: none;
          background-color: #333333;
          position: absolute;
          flex-direction: column;
          right: 0;
          z-index: 20;
          width: auto;
          text-align: left; } }
      .nav-container .main-nav > ul li {
        border-right: 0.0625rem solid #FFFFFF;
        display: flex;
        flex: 1;
        justify-content: center;
        position: relative; }
        @media (max-width: 991px) {
          .nav-container .main-nav > ul li {
            border-right-width: 0;
            border-bottom: 0.0625rem solid #FFFFFF;
            flex-direction: column; } }
        .nav-container .main-nav > ul li:first-child {
          border-left: 0.0625rem solid #FFFFFF; }
          @media (max-width: 991px) {
            .nav-container .main-nav > ul li:first-child {
              border-left-width: 0; } }
        @media (max-width: 991px) {
          .nav-container .main-nav > ul li:last-child {
            border-bottom-width: 0; } }
        .nav-container .main-nav > ul li.is-active > a {
          background-color: #dc0b21; }
          @media (max-width: 991px) {
            .nav-container .main-nav > ul li.is-active > a.has-sub-items:after {
              content: '\f077'; } }
        .nav-container .main-nav > ul li.is-active > .nav-sub-items {
          display: block; }
      .nav-container .main-nav > ul a {
        color: #FFFFFF;
        padding: 0.375rem 0.3125rem;
        width: 100%;
        position: relative; }
        @media (max-width: 991px) {
          .nav-container .main-nav > ul a {
            white-space: nowrap;
            padding: 0.375rem 2.1875rem 0.375rem 0.75rem; } }
        @media (max-width: 991px) {
          .nav-container .main-nav > ul a.has-sub-items:after {
            content: '\f078';
            font-family: FontAwesome;
            font-size: 0.875rem;
            position: absolute;
            top: 50%;
            right: 0.625rem;
            width: 0.875rem;
            height: 1.25rem;
            display: block;
            transform: translateY(-50%); } }
    .nav-container .main-nav .nav-sub-items {
      display: none;
      position: absolute;
      top: 100%;
      background-color: #333333;
      z-index: 1000;
      padding: 0;
      margin: 0;
      border-top: 0.1875rem solid #b9091d;
      min-width: 100%;
      width: auto;
      left: 0;
      text-align: left; }
      @media (max-width: 991px) {
        .nav-container .main-nav .nav-sub-items {
          position: relative; } }
      .nav-container .main-nav .nav-sub-items > li {
        border: 0;
        border-bottom: 0.0625rem solid #FFFFFF; }
        .nav-container .main-nav .nav-sub-items > li:first-child {
          border: 0;
          border-bottom: 0.0625rem solid #FFFFFF; }
        .nav-container .main-nav .nav-sub-items > li:last-child {
          border-bottom: 0; }
        .nav-container .main-nav .nav-sub-items > li a {
          padding: 0.375rem 0.75rem;
          white-space: nowrap; }
          @media (max-width: 991px) {
            .nav-container .main-nav .nav-sub-items > li a {
              padding-left: 1.875rem; } }
          .nav-container .main-nav .nav-sub-items > li a:hover, .nav-container .main-nav .nav-sub-items > li a:focus {
            background-color: #dc0b21; }

.header {
  margin-top: 0.9375rem;
  display: flex;
  flex-direction: column;
  align-content: center; }
  @media (min-width: 992px) {
    .header > .container {
      max-width: 100%;
      margin: 0;
      padding: 0; } }
  @media (max-width: 991px) {
    .header > .container {
      display: flex;
      flex-direction: row; } }
  @media (max-width: 991px) {
    .header > .container .header-container {
      max-width: 100%;
      margin: 0;
      padding-left: 0; } }
  @media (max-width: 991px) {
    .header {
      flex-direction: row; } }
  .header .header-container {
    display: flex;
    flex-direction: row; }
    @media (max-width: 991px) {
      .header .header-container {
        padding-right: 0; } }
  .header .main-logo {
    display: flex;
    flex: 1; }
    .header .main-logo a {
      display: block; }
    @media (max-width: 767px) {
      .header .main-logo img {
        width: 7.5rem; } }
  .header .top-links {
    display: flex;
    justify-content: center;
    align-items: center; }
    .header .top-links .top-links-nav {
      display: flex;
      flex-direction: row;
      padding: 0;
      margin: 0; }
    .header .top-links .nav-item {
      margin: 0;
      border-right: 0.0625rem solid #333333;
      font-size: 0.75rem;
      display: flex;
      align-items: center; }
      @media (max-width: 767px) {
        .header .top-links .nav-item {
          display: none; } }
      .header .top-links .nav-item:last-child {
        border-right: 0; }
        @media (max-width: 767px) {
          .header .top-links .nav-item:last-child {
            display: block; } }
      .header .top-links .nav-item .nav-link {
        padding: 0;
        margin: 0 0.75rem; }
      .header .top-links .nav-item .lang-toggle {
        position: relative; }
        .header .top-links .nav-item .lang-toggle img {
          width: 60%; }
        .header .top-links .nav-item .lang-toggle:after {
          content: '\f078';
          position: absolute;
          font-family: FontAwesome;
          width: 0.75rem;
          height: 1.125rem;
          display: block;
          right: 0;
          top: 50%;
          transform: translateY(-50%); }

.footer {
  border-top: 0.1875rem solid #cdcdcd;
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem; }
  .footer .copyright {
    font-weight: bold;
    font-size: 0.75rem;
    text-align: right;
    padding-right: 0.75rem; }
    @media (max-width: 991px) {
      .footer .copyright {
        text-align: left; } }
    .footer .copyright p {
      margin-bottom: 0; }
  .footer .footer-links {
    padding: 0;
    margin: 0; }
    .footer .footer-links li {
      display: inline-block;
      position: relative;
      font-size: 0.875rem; }
      .footer .footer-links li:after {
        content: '';
        position: absolute;
        height: 0.75rem;
        width: 0.0625rem;
        background-color: #333333;
        display: block;
        right: 0;
        top: 50%;
        transform: translateY(-50%); }
      .footer .footer-links li a {
        padding: 0 0.5rem 0 0.25rem;
        display: block; }
      .footer .footer-links li:last-child:after {
        display: none; }
    .footer .footer-links--site {
      font-weight: bold; }
    .footer .footer-links--info {
      margin-bottom: 1.125rem; }
    @media (max-width: 991px) {
      .footer .footer-links--social {
        margin-bottom: 1.875rem; } }
    .footer .footer-links--social li .fa-plus-circle {
      font-size: 1.875rem; }
    .footer .footer-links--social li .fa-youtube-play {
      font-size: 1.875rem;
      color: #FF0000; }
    .footer .footer-links--social li .fa-facebook-official {
      font-size: 1.875rem;
      color: #3b5998; }
    .footer .footer-links--social li a {
      padding: 0 0.75rem;
      display: flex;
      align-items: center; }
    .footer .footer-links--social li:first-child a {
      padding-left: 0; }
    .footer .footer-links--partners {
      margin-bottom: 1.875rem;
      display: flex;
      justify-content: flex-end; }
      @media (max-width: 991px) {
        .footer .footer-links--partners {
          flex-wrap: wrap;
          justify-content: flex-start; } }
      .footer .footer-links--partners li {
        margin-right: 1.25rem; }
        @media (max-width: 991px) {
          .footer .footer-links--partners li {
            margin-right: 0; } }
        @media (max-width: 767px) {
          .footer .footer-links--partners li {
            margin-bottom: 1.25rem; } }
        .footer .footer-links--partners li:after {
          display: none; }
        .footer .footer-links--partners li:last-child {
          margin-right: 0; }

.btn, .black-button {
  border-radius: 0; }

.fa {
  color: #dc0b21;
  padding-right: 0.3125rem;
  font-size: 1.3rem; }

.btn-default, .black-button {
  color: #fff;
  background-color: #0a0a0a; }
  .btn-default .fa, .black-button .fa {
    position: relative;
    top: 0.125rem; }
  .btn-default input, .black-button input {
    border: none;
    background-color: inherit;
    color: #fff; }
  .btn-default.btn-rte, .btn-rte.black-button {
    position: relative;
    padding-left: 2.1875rem;
    margin-top: 0.9375rem; }
    .btn-default.btn-rte:before, .btn-rte.black-button:before {
      content: '\f061';
      position: absolute;
      font-family: FontAwesome;
      top: 6px;
      left: 10px;
      color: #dc0b21;
      font-size: 1.25rem; }
    .btn-default.btn-rte.btn-icon-rotate:before, .btn-rte.btn-icon-rotate.black-button:before {
      transform: rotate(180deg); }

.black-button {
  position: relative;
  padding-left: 2.1875rem; }
  .black-button:before {
    content: '\f061';
    position: absolute;
    font-family: FontAwesome;
    top: 6px;
    left: 10px;
    color: #dc0b21;
    font-size: 1.25rem; }

input[type="submit"].btn-default, input[type="submit"].black-button {
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding-left: 2.5rem;
  background: #0a0a0a url("../img/icons/icon-submit-arrow.png") no-repeat 7px 7px; }
  input[type="submit"].btn-default:hover, input[type="submit"].black-button:hover {
    color: #dc0b21;
    cursor: pointer; }

.btn-play {
  background: none;
  position: relative;
  padding-left: 2.5rem; }
  .btn-play .fa {
    font-size: 2.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0; }

.btn-transparent {
  color: #fff;
  background-color: transparent; }
  .btn-transparent .fa {
    position: relative;
    top: 0.125rem; }
  .btn-transparent input {
    border: none;
    background-color: inherit;
    color: #fff; }

.btn-grey {
  background-color: #e8e8e1;
  border: 1px solid #ccc;
  color: #0a0a0a; }

.breadcrumb-wrapper .container {
  padding: 0; }

.breadcrumb-list {
  padding: 0.9375rem 0; }
  .breadcrumb-list li {
    display: inline-block;
    position: relative;
    padding-right: 1.5625rem;
    font-size: 0.75rem; }
    .breadcrumb-list li:after {
      content: '\f178';
      display: block;
      font-family: FontAwesome;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.3125rem;
      color: #dc0b21; }
    .breadcrumb-list li:last-child:after {
      display: none; }
    .breadcrumb-list li a:hover, .breadcrumb-list li a:focus {
      color: #dc0b21; }

.global-search {
  display: flex;
  align-self: center; }
  .global-search.is-visible .global-search__form {
    display: block; }
  .global-search__toggle {
    display: none; }
    @media (max-width: 991px) {
      .global-search__toggle {
        display: block;
        background-color: #333333;
        width: 2.8125rem;
        height: 2.875rem;
        font-size: 1.625rem;
        padding-top: 0.25rem; }
        .global-search__toggle:after {
          content: '\f002';
          color: #FFFFFF;
          position: relative;
          font-family: FontAwesome;
          display: block;
          right: auto;
          top: auto;
          transform: translateY(0);
          text-align: center;
          margin: 0;
          padding: 0 0.6875rem; } }
  .global-search input[type=text] {
    padding-right: 2.5rem;
    width: 100%; }
  @media (max-width: 991px) {
    .global-search__form {
      position: absolute;
      top: 4.5rem;
      width: 100%;
      padding: 0.75rem;
      background-color: #333333;
      left: 0;
      z-index: 1;
      display: none; } }
  @media (max-width: 767px) {
    .global-search__form {
      top: 3.75rem; } }
  .global-search .form-inline {
    position: relative; }
    .global-search .form-inline .search-button {
      position: absolute;
      padding: 0;
      right: 0.5rem;
      top: 0; }
      .global-search .form-inline .search-button input {
        height: 2.25rem;
        width: 2.5rem;
        text-indent: 9999px; }
      .global-search .form-inline .search-button:after {
        content: '\f002';
        font-family: FontAwesome;
        position: absolute;
        font-size: 1.625rem;
        top: 0;
        right: 0;
        width: 2.5rem;
        height: 2.25rem;
        display: block;
        background-color: #FFFFFF;
        color: #dc0b21;
        pointer-events: none; }
      @media (max-width: 991px) {
        .global-search .form-inline .search-button {
          top: 0; } }

.display-lang-options .lang-options {
  display: block; }

.display-lang-options .lang-overlay {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100; }

.country-overlay.active {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100; }

.country-options.active {
  display: block;
  z-index: 9999;
  position: absolute; }

.lang-options,
.country-options {
  display: none;
  position: fixed;
  background-color: #FFFFFF;
  width: 100%;
  z-index: 101;
  padding: 1.875rem 0;
  top: 0; }
  .lang-options p.lang-title,
  .country-options p.lang-title {
    font-family: 'PFCentroSlabPro_Regular';
    font-size: 2.25rem; }
  .lang-options .site-lang-selector,
  .country-options .site-lang-selector {
    display: inline-block;
    padding: 0.625rem 0 0.625rem 4.0625rem;
    color: #333;
    text-decoration: none;
    margin-right: 0.9375rem; }
    .lang-options .site-lang-selector.en-gb, .lang-options .site-lang-selector.en,
    .country-options .site-lang-selector.en-gb,
    .country-options .site-lang-selector.en {
      background: url("../img/icons/icon-uk.png") no-repeat 0 0; }
    .lang-options .site-lang-selector.en-us,
    .country-options .site-lang-selector.en-us {
      background: url("../img/icons/icon-us.png") no-repeat 0 0; }

.cookie-banner {
  padding: 1.875rem 0;
  background-color: #333333;
  color: #FFFFFF;
  display: none; }
  .cookie-banner.active {
    display: block; }

.loader {
  display: none; }

.is-loading {
  position: relative;
  min-height: 12.5rem; }
  .is-loading .loader {
    display: block;
    min-height: 12.5rem;
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20; }
    .is-loading .loader .loader-icon,
    .is-loading .loader .loader-icon:after {
      border-radius: 50%;
      width: 6.25rem;
      height: 6.25rem;
      position: relative;
      z-index: 4; }
    .is-loading .loader .loader-icon {
      font-size: 0.625rem;
      position: relative;
      text-indent: -624.9375rem;
      border-top: 0.6875rem solid rgba(100, 100, 100, 0.2);
      border-right: 0.6875rem solid rgba(100, 100, 100, 0.2);
      border-bottom: 0.6875rem solid rgba(100, 100, 100, 0.2);
      border-left: 0.6875rem solid #333333;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-30%, -50%); }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
    .is-loading .loader:before {
      content: '';
      background-color: #FFFFFF;
      bottom: 0;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1; }

.panel {
  padding: 1.5625rem 0; }
  .panel .card {
    border: none;
    border-radius: 0;
    background-color: transparent; }
    .panel .card .card-img-top {
      border-radius: 0; }
    .panel .card .card-block {
      padding: 0.9375rem 0; }
  @media (max-width: 767px) {
    .panel.col-4-panel .row .card {
      margin-bottom: 0.75rem; } }
  .panel.col-4-panel .title-section {
    border-bottom: 0.125rem solid #cecece;
    margin: 0 0.9375rem 1.25rem; }
  .panel.col-4-panel .video-wrapper h2, .panel.col-4-panel .video-wrapper .video-duration {
    display: none; }
  .panel.col-4-panel .video-wrapper .video-player-content {
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%; }
  .panel.col-4-panel .video-wrapper .btn-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .panel.col-4-panel .purple {
    background-color: #661357;
    color: #fff; }
    .panel.col-4-panel .purple.card {
      text-align: center;
      justify-content: center;
      padding: 0 0.9375rem; }
  .panel.col-4-panel .col-md-3 {
    margin-bottom: 0.75rem; }

.card {
  border: none; }
  .card h1, .card h2, .card h3, .card h4 {
    font-family: 'PFCentroSlabPro_Medium'; }
  .card p, .card a {
    font-family: arial, sans-serif; }

.hero-small {
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
  border-bottom: 0.0625rem solid #cecece; }
  .hero-small .card-img {
    height: 100%;
    width: 35%;
    align-self: center;
    border-radius: 0; }
  .hero-small .card-block {
    width: auto;
    padding: 1.5625rem; }
    .hero-small .card-block .card-title {
      padding-bottom: 0.9375rem;
      color: #dc0b21; }
    .hero-small .card-block a {
      color: #dc0b21;
      font-weight: bold; }
  .hero-small.left {
    flex-direction: row; }
  .hero-small.right {
    flex-direction: row-reverse; }
    .hero-small.right .card-block {
      padding-left: 0; }
  @media (max-width: 767px) {
    .hero-small.left, .hero-small.right {
      flex-direction: column; }
      .hero-small.left .card-block, .hero-small.right .card-block {
        padding-left: 0; }
    .hero-small .card-img {
      width: 100%; }
    .hero-small .card-block {
      width: 100%; } }

.hero-banner {
  padding: 0 1.5625rem 0 0;
  border-radius: 0;
  border: 0.0625rem solid #cecece;
  background: #f1f2ed;
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: row; }
  .hero-banner .hero-block {
    padding: 1.5625rem 0.9375rem 0.9375rem 1.5625rem; }
    .hero-banner .hero-block h2 {
      font-size: 1.5rem;
      border-bottom: 0.0625rem solid #cecece;
      padding-bottom: 0.25rem; }
    .hero-banner .hero-block p {
      font-family: arial, sans-serif;
      font-weight: normal;
      font-size: 1rem; }
  @media (max-width: 767px) {
    .hero-banner .hero-img {
      display: none; }
    .hero-banner .hero-block {
      display: block;
      width: 100%; } }

.search-bar {
  padding: 1.875rem 0; }
  .search-bar .search-input {
    min-width: 25rem;
    margin-right: 1.25rem;
    margin-bottom: 0.625rem;
    padding: 0.375rem 0.625rem;
    vertical-align: top; }
    @media (max-width: 991px) {
      .search-bar .search-input {
        width: 100%; } }
  .search-bar .btns.btn-default, .search-bar .btns.black-button {
    margin-bottom: 0.625rem; }
  .search-bar .search-bar-radio-item {
    display: inline-block;
    margin-right: 1.875rem; }
    .search-bar .search-bar-radio-item input {
      margin-right: 0.3125rem; }

.paging-block {
  text-align: center; }
  .paging-block a {
    margin-right: 0.3125rem; }
    .paging-block a.current {
      color: #333333; }

.hero-statement {
  border-bottom: 0.125rem solid #cecece;
  margin-bottom: 1.5625rem;
  padding: 1.875rem 0 0.9375rem;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .hero-statement h1, .hero-statement h2 {
    padding: 0;
    margin: 0; }
  .hero-statement a {
    color: #dc0b21; }
    .hero-statement a:hover, .hero-statement a:focus {
      color: #333333; }
  .hero-statement__heading {
    border-right: 0.125rem solid #cecece;
    padding-right: 1.875rem;
    margin-right: 1.875rem; }
    @media (max-width: 767px) {
      .hero-statement__heading {
        border-right-width: 0;
        padding-right: 0;
        margin-right: 0; } }
  @media (max-width: 767px) {
    .hero-statement {
      flex-direction: column; }
      .hero-statement__heading {
        border-right-width: 0;
        padding-right: 0;
        margin-right: 0;
        margin-bottom: 1.25rem; } }

.carousel-wrapper,
.carousel-wrapper-single {
  padding-left: 0;
  position: relative;
  margin-top: 3.375rem; }
  .carousel-wrapper .mobile-placeholder,
  .carousel-wrapper-single .mobile-placeholder {
    display: none; }
  .carousel-wrapper .carousel-container,
  .carousel-wrapper-single .carousel-container {
    border-top: 0.3125rem solid #dc0b21; }
    .carousel-wrapper .carousel-container .slick-arrow,
    .carousel-wrapper-single .carousel-container .slick-arrow {
      display: none; }
    .carousel-wrapper .carousel-container .slick-slide img,
    .carousel-wrapper-single .carousel-container .slick-slide img {
      width: 100%; }
    .carousel-wrapper .carousel-container .slide-tag,
    .carousel-wrapper-single .carousel-container .slide-tag {
      position: absolute;
      background-color: #dc0b21;
      color: #FFFFFF;
      bottom: 1.25rem;
      left: 0.9375rem;
      padding: 0.375rem 0.75rem;
      font-family: 'PFCentroSlabPro_Regular';
      font-weight: bold;
      font-size: 0.875rem; }
    .carousel-wrapper .carousel-container .slick-list,
    .carousel-wrapper-single .carousel-container .slick-list {
      z-index: 2;
      overflow: visible; }
    .carousel-wrapper .carousel-container .slick-dots,
    .carousel-wrapper-single .carousel-container .slick-dots {
      height: 2.5rem;
      padding-top: 0.125rem;
      text-align: left;
      background-color: #333333; }
      .carousel-wrapper .carousel-container .slick-dots li button:before,
      .carousel-wrapper-single .carousel-container .slick-dots li button:before {
        color: #FFFFFF;
        opacity: 1;
        z-index: 2;
        left: 0.4375rem;
        top: 0.125rem;
        width: 1.1875rem;
        height: 1.1875rem; }
      .carousel-wrapper .carousel-container .slick-dots li button:after,
      .carousel-wrapper-single .carousel-container .slick-dots li button:after {
        width: 1.375rem;
        height: 1.375rem;
        display: block;
        content: '';
        position: absolute;
        background-color: #333333;
        border-radius: 50%;
        border: 0.125rem solid #333333;
        top: 0; }
      .carousel-wrapper .carousel-container .slick-dots li.slick-active button:before,
      .carousel-wrapper-single .carousel-container .slick-dots li.slick-active button:before {
        color: #dc0b21; }
      .carousel-wrapper .carousel-container .slick-dots li.slick-active button:after,
      .carousel-wrapper-single .carousel-container .slick-dots li.slick-active button:after {
        border-color: #FFFFFF; }
  .carousel-wrapper .slide-text-container,
  .carousel-wrapper-single .slide-text-container {
    width: 11.25rem;
    height: 11.25rem;
    display: block;
    display: flex;
    align-items: center;
    text-align: center;
    position: absolute;
    bottom: -5.625rem;
    right: 6.25rem; }
    @media (max-width: 991px) {
      .carousel-wrapper .slide-text-container,
      .carousel-wrapper-single .slide-text-container {
        right: 1.875rem; } }
    @media (max-width: 767px) {
      .carousel-wrapper .slide-text-container,
      .carousel-wrapper-single .slide-text-container {
        display: none; } }
    .carousel-wrapper .slide-text-container span,
    .carousel-wrapper-single .slide-text-container span {
      position: relative;
      z-index: 4;
      padding: 0.75rem 1.25rem;
      color: #FFFFFF;
      font-size: 1.5rem;
      font-family: 'PFCentroSlabPro_Medium';
      line-height: normal; }
    .carousel-wrapper .slide-text-container:before,
    .carousel-wrapper-single .slide-text-container:before {
      content: '';
      border-bottom-left-radius: 5.625rem;
      border-bottom-right-radius: 5.625rem;
      height: 5.9375rem;
      width: 11.875rem;
      background: #FFFFFF;
      display: inline-block;
      position: absolute;
      bottom: -0.3125rem;
      left: -0.3125rem;
      z-index: 1;
      border: 1rem solid #FFFFFF;
      border-top: 0; }
    .carousel-wrapper .slide-text-container:after,
    .carousel-wrapper-single .slide-text-container:after {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(211, 28, 30, 0.9);
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3; }

.carousel-wrapper-single .slide-text-container:before, .carousel-wrapper-single .carousel-wrapper-single .slide-text-container:before {
  background: transparent;
  border-color: transparent; }

.quote {
  margin: 0.625rem 0; }
  .quote p {
    border-top: 2px solid #661357;
    border-bottom: 2px solid #661357;
    display: inline-block;
    color: #661357;
    padding: 15px 0; }
    .quote p .fa {
      background: #dc0b21;
      border-radius: 50%;
      margin-right: 10px;
      color: #fff;
      padding: 8px;
      width: 38px; }
      .quote p .fa::before {
        position: relative;
        left: 1px; }
  .quote .blockquote-footer {
    text-align: right; }
  .quote.blockquote-il {
    display: inline-block;
    width: 35%; }
  @media (max-width: 767px) {
    .quote.blockquote-il {
      width: 100%;
      display: block; } }

.promo-banner {
  background: #661357;
  color: #fff;
  padding: 1.5625rem 0;
  position: relative;
  margin-bottom: 1.25rem; }
  .promo-banner:before {
    content: "";
    display: table;
    clear: both; }
  .promo-banner:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 768px) {
    .promo-banner {
      margin-bottom: 2.5rem; } }
  .promo-banner:before {
    position: absolute;
    width: 9999px;
    top: 0;
    bottom: 0;
    left: -9999px;
    background: #661357;
    display: block; }
  .promo-banner:after {
    position: absolute;
    width: 9999px;
    top: 0;
    bottom: 0;
    left: 100%;
    background: #661357;
    display: block; }
  .promo-banner .container {
    display: flex;
    flex-direction: row;
    width: 100%; }
    @media (max-width: 767px) {
      .promo-banner .container {
        flex-direction: column; } }
  .promo-banner__item {
    margin-right: 1.5625rem; }
    @media (max-width: 767px) {
      .promo-banner__item {
        margin-right: 0rem;
        margin-bottom: 1.5625rem; } }
    .promo-banner__item .fa {
      color: #fff;
      font-size: 5.1875rem;
      position: relative; }
  .promo-banner__content {
    flex: 2; }
    .promo-banner__content h2 {
      color: #fff; }
    .promo-banner__content p {
      margin-bottom: 0; }
      .promo-banner__content p a {
        color: #fff;
        display: inline-block; }
        .promo-banner__content p a:hover, .promo-banner__content p a:focus {
          color: #dc0b21; }
  .promo-banner--flip .promo-banner__item {
    order: 2;
    flex: 1;
    margin-right: 0;
    align-items: center;
    display: flex; }
    .promo-banner--flip .promo-banner__item a {
      margin-right: 1.875rem; }
    .promo-banner--flip .promo-banner__item .fa {
      font-size: 3.75rem; }
  .promo-banner--flip .promo-banner__content {
    order: 1;
    flex: 1;
    margin-right: 2.5rem; }
    @media (max-width: 767px) {
      .promo-banner--flip .promo-banner__content {
        margin-right: 0;
        margin-bottom: 1.5625rem; } }
    .promo-banner--flip .promo-banner__content h2, .promo-banner--flip .promo-banner__content p {
      margin-left: 0; }

.full-width-box {
  background: #f0f0f0;
  border: 0;
  padding: 1.5625rem 0;
  position: relative;
  margin-bottom: 1.25rem; }
  .full-width-box:before {
    content: "";
    display: table;
    clear: both; }
  .full-width-box:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 768px) {
    .full-width-box {
      margin-bottom: 2.5rem; } }
  .full-width-box:before {
    position: absolute;
    width: 9999px;
    top: 0;
    bottom: 0;
    left: -9999px;
    background: #f0f0f0;
    display: block; }
  .full-width-box:after {
    position: absolute;
    width: 9999px;
    top: 0;
    bottom: 0;
    left: 100%;
    background: #f0f0f0;
    display: block; }

.rte {
  padding: 1.25rem 0; }
  .rte h1,
  .rte h2,
  .rte h3,
  .rte h4,
  .rte h5,
  .rte h6 {
    font-family: "PFCentroSlabPro_Medium";
    margin-bottom: 1rem; }
  .rte h1 {
    font-size: 3.1875rem;
    line-height: 1.09;
    letter-spacing: 0.1rem; }
  .rte h2 {
    font-size: 2.125rem;
    line-height: 1.2;
    letter-spacing: 0.09375rem; }
  .rte h3 {
    font-size: 1.5625rem;
    line-height: 1.28;
    letter-spacing: 0.06875rem; }
  .rte p {
    font-family: "Lato", sans-serif;
    line-height: 1.5;
    letter-spacing: 0.03125rem;
    margin: 0 0 1.25rem;
    font-size: 1rem; }
  .rte a:not(.btn):not(.black-button):not(.black-button) {
    color: #dc0b21;
    transition: all 0.4s ease-out; }
    .rte a:not(.btn):not(.black-button):not(.black-button):hover, .rte a:not(.btn):not(.black-button):not(.black-button):focus {
      color: #dc0b21; }
  .rte img {
    margin-bottom: 0.625rem;
    width: 100%; }
  .rte figcaption {
    font-family: "Lato", sans-serif;
    font-style: italic; }
  .rte ul {
    margin: 0 0 1.25rem 1.25rem;
    padding: 0; }
    .rte ul li {
      list-style: disc;
      font-family: "Lato", sans-serif;
      padding: 0 0 0.625rem 0; }
      .rte ul li ul, .rte ul li ol {
        margin-top: 0.625rem;
        margin-bottom: 0; }
  .rte ol {
    margin: 0 0 1.25rem 1.5625rem;
    padding: 0; }
    .rte ol li {
      list-style: decimal;
      font-family: "Lato", sans-serif;
      padding: 0 0 0.625rem 0; }
      .rte ol li ul, .rte ol li ol {
        margin-top: 0.625rem;
        margin-bottom: 0; }
  .rte table {
    width: 100%;
    margin-bottom: 3.125rem; }
    .rte table th {
      font-family: "Lato", sans-serif; }
    .rte table td,
    .rte table th {
      padding-left: 0; }
    @media (min-width: 768px) {
      .rte table tbody tr {
        background-color: #f0f1f1; }
        .rte table tbody tr:nth-child(odd) {
          background-color: #fdfdfd; } }
    .rte table td {
      font-family: "Lato", sans-serif; }
  @media (max-width: 767px) {
    .rte table,
    .rte tbody,
    .rte td,
    .rte th,
    .rte thead,
    .rte tr {
      display: block; }
    .rte thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px; }
    .rte td {
      /* Behave  like a "row" */
      border: none;
      position: relative;
      padding-left: 50%; }
    .rte td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 0.375rem;
      left: 0.375rem;
      width: 45%;
      padding-right: 0.625rem;
      white-space: nowrap; }
    .rte tbody tr:nth-child(odd) {
      background-color: #FFFFFF; } }
  .rte blockquote p {
    border-top: 2px solid #661357;
    border-bottom: 2px solid #661357;
    display: inline-block;
    color: #661357;
    padding: 15px 0;
    font-size: 1.25rem;
    line-height: 1.875rem;
    font-family: 'Arial', sans-serif; }
  .rte blockquote footer {
    display: block;
    font-size: 1rem;
    color: #868e96;
    text-align: right;
    font-family: 'Arial', sans-serif; }
  .rte figure img {
    width: 100%; }
  .rte figure figcaption {
    font-size: 0.75rem;
    line-height: 1.375rem;
    margin: 0 0 1rem 0; }
    @media (min-width: 768px) {
      .rte figure figcaption {
        font-size: 0.875rem;
        line-height: 1.5rem; } }
    .rte figure figcaption span {
      display: block;
      font-size: 0.625rem;
      line-height: 1.125rem; }

.responsive-image img {
  width: 100%; }

.responsive-image figcaption {
  font-family: "Lato", sans-serif;
  font-size: 0.875rem;
  font-style: italic;
  line-height: 1.5rem; }

.formatted-table table {
  border: 1px solid #333333;
  widows: 100%; }
  .formatted-table table th, .formatted-table table td {
    border-bottom: 1px solid #333333;
    padding: 1.25rem; }
  .formatted-table table th {
    border-right: 1px solid #333333; }
  @media (max-width: 767px) {
    .formatted-table table {
      border-bottom: 0; }
      .formatted-table table th, .formatted-table table td {
        width: 100%;
        display: block;
        border-right: 0; }
      .formatted-table table th {
        border-bottom: 1px solid #333333; } }

.highlight-box {
  background-color: #661357;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5625rem;
  padding: 1.25rem;
  width: 100%; }
  .highlight-box h3, .highlight-box p {
    color: #FFFFFF;
    font-weight: bold; }
  .highlight-box--left {
    width: 25%; }
  .highlight-box--right {
    float: right;
    width: 25%; }
  @media (max-width: 767px) {
    .highlight-box--left, .highlight-box--right {
      width: 100%; } }

.feature-col-block a:hover .feature-col-block__image:after, .feature-col-block a:focus .feature-col-block__image:after {
  background: rgba(0, 0, 0, 0.4); }

.feature-col-block__image {
  margin-bottom: 0.9375rem;
  position: relative; }
  .feature-col-block__image:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    background: transparent;
    top: 0;
    transition: background .3s ease-in; }
  .feature-col-block__image img {
    width: 100%; }

.feature-col-block__heading h2, .feature-col-block__heading h3 {
  color: #dc0b21;
  font-size: 1.25rem; }

.content-tabs {
  margin: 1.25rem 0; }
  .content-tabs .tabs {
    display: block; }
    .content-tabs .tabs ul {
      display: flex;
      border-bottom: 0.1875rem solid #dc0b21;
      padding: 0;
      flex-direction: column; }
    .content-tabs .tabs li {
      display: flex;
      position: relative;
      justify-content: space-between;
      flex: 1 1 auto; }
      .content-tabs .tabs li a {
        display: block;
        background-color: #333333;
        width: 100%;
        font-size: 0.875rem;
        text-align: left;
        color: #FFFFFF;
        padding: 0.5rem 1rem; }
        .content-tabs .tabs li a.is-active {
          background-color: #dc0b21; }
          .content-tabs .tabs li a.is-active:hover {
            cursor: default; }
        .content-tabs .tabs li a:hover, .content-tabs .tabs li a:focus {
          background-color: #dc0b21; }
      .content-tabs .tabs li:last-child {
        border-right-width: 0; }
  .content-tabs .item-content {
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden; }
  .content-tabs .item {
    min-height: 0; }
    .content-tabs .item:before, .content-tabs .item:after {
      display: none; }
    .content-tabs .item.is-active .item-content {
      opacity: 1;
      visibility: visible;
      height: auto;
      transition: all .5s linear; }
  .content-tabs.mobile-accordion .tabs {
    display: none; }
  .content-tabs.mobile-accordion .item {
    min-height: 2rem;
    position: relative; }
    .content-tabs.mobile-accordion .item:before {
      content: attr(data-title);
      background-color: #333333;
      color: #FFFFFF;
      width: 100%;
      display: block;
      padding: 0.5rem 1rem; }
    .content-tabs.mobile-accordion .item:after {
      content: '\f078';
      font-family: FontAwesome;
      position: absolute;
      top: 0.375rem;
      right: 0.375rem;
      width: 1.25rem;
      height: 1.125rem;
      display: block;
      color: #FFFFFF; }
    .content-tabs.mobile-accordion .item.is-active:before {
      background-color: #dc0b21;
      margin-bottom: 0.75rem; }
  @media (min-width: 576px) {
    .content-tabs .tabs li {
      border-right: 0.0625rem solid #FFFFFF; }
      .content-tabs .tabs li a {
        padding: 0.5rem; } }
  @media (min-width: 768px) {
    .content-tabs .tabs {
      display: block; }
      .content-tabs .tabs ul {
        display: flex;
        border-bottom: 0.1875rem solid #dc0b21;
        padding: 0;
        flex-direction: row; }
      .content-tabs .tabs li {
        display: flex;
        position: relative;
        justify-content: space-between;
        border-right: 0.0625rem solid #FFFFFF;
        flex: 1 1 auto; }
        .content-tabs .tabs li a {
          display: block;
          background-color: #333333;
          width: 100%;
          font-size: 0.875rem;
          text-align: left;
          color: #FFFFFF;
          padding: 0.5rem 1rem; }
          .content-tabs .tabs li a.is-active {
            background-color: #dc0b21; }
            .content-tabs .tabs li a.is-active:hover {
              cursor: default; }
          .content-tabs .tabs li a:hover, .content-tabs .tabs li a:focus {
            background-color: #dc0b21; }
        .content-tabs .tabs li:last-child {
          border-right-width: 0; }
    .content-tabs .item {
      min-height: 0; }
      .content-tabs .item:before, .content-tabs .item:after {
        display: none; }
    .content-tabs.mobile-accordion .tabs {
      display: block; }
    .content-tabs.mobile-accordion .item {
      min-height: 0; }
      .content-tabs.mobile-accordion .item:before, .content-tabs.mobile-accordion .item:after {
        display: none; } }

.content-tabs--styled {
  margin-top: 2.5rem; }
  .content-tabs--styled .tabs {
    display: block; }
    .content-tabs--styled .tabs ul {
      border-bottom-width: 0; }
    .content-tabs--styled .tabs li a {
      border: 0.0625rem solid #cccccc;
      background-color: #FFFFFF;
      color: #999; }
      .content-tabs--styled .tabs li a.is-active {
        background-color: #cecece;
        color: #dc0b21; }
  .content-tabs--styled.mobile-accordion .item:before {
    border: 0.0625rem solid #cccccc;
    background-color: #FFFFFF;
    color: #999;
    margin-bottom: 0.3125rem; }
  .content-tabs--styled.mobile-accordion .item:after {
    color: #999; }
  .content-tabs--styled.mobile-accordion .item.is-active:before {
    background-color: #cecece;
    color: #dc0b21; }
  .content-tabs--styled.mobile-accordion .item.is-active:after {
    color: #dc0b21; }
  @media (min-width: 768px) {
    .content-tabs--styled .tabs {
      border: 0.0625rem solid #cccccc;
      box-shadow: 0.09375rem 0.09375rem 0.3125rem #bbbbbb;
      padding: 0;
      margin-bottom: 1.875rem;
      position: relative; }
      .content-tabs--styled .tabs .hover-marker {
        position: absolute;
        height: 100%;
        top: 0;
        display: none; }
        .content-tabs--styled .tabs .hover-marker:before, .content-tabs--styled .tabs .hover-marker:after {
          background-color: #dc0b21;
          content: '';
          position: absolute;
          height: 0.625rem;
          width: 90%;
          left: 50%;
          transform: translateX(-50%); }
        .content-tabs--styled .tabs .hover-marker:before {
          top: -0.625rem;
          border-radius: 0.375rem 0.375rem 0 0; }
        .content-tabs--styled .tabs .hover-marker:after {
          bottom: -0.625rem;
          border-radius: 0 0 0.375rem 0.375rem; }
        .content-tabs--styled .tabs .hover-marker span:before, .content-tabs--styled .tabs .hover-marker span:after {
          color: #FFFFFF;
          font-family: FontAwesome;
          position: absolute;
          line-height: 1.5rem;
          z-index: 1; }
        .content-tabs--styled .tabs .hover-marker span:before {
          content: '';
          top: -0.3125rem;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0.9375rem 0.9375rem 0 0.9375rem;
          border-color: #dc0b21 transparent transparent transparent;
          left: 50%;
          transform: translateX(-50%); }
        .content-tabs--styled .tabs .hover-marker span:after {
          background-color: #dc0b21;
          content: '\f104 \f105';
          height: 1.625rem;
          font-size: 1.25rem;
          width: 40%;
          left: 50%;
          transform: translateX(-50%);
          bottom: -1.25rem;
          border-radius: 0.3125rem;
          text-align: center;
          padding: 0 0.625rem;
          letter-spacing: 2.5625rem; } }
      @media (min-width: 768px) and (max-width: 1199px) {
        .content-tabs--styled .tabs .hover-marker span:after {
          letter-spacing: 2rem; } }
      @media (min-width: 768px) and (max-width: 991px) {
        .content-tabs--styled .tabs .hover-marker span:after {
          letter-spacing: 0.875rem; } }
  @media (min-width: 768px) {
      .content-tabs--styled .tabs ul {
        border-bottom-width: 0;
        margin: 0; }
      .content-tabs--styled .tabs li {
        border-right-width: 0;
        flex: 1;
        align-content: center;
        justify-content: center; }
        .content-tabs--styled .tabs li a {
          border-width: 0;
          background-color: #FFFFFF;
          color: #999;
          font-family: 'PFCentroSlabPro_Medium';
          font-size: 0.875rem;
          text-align: center;
          position: relative;
          align-items: center;
          justify-content: center;
          display: flex;
          padding: 0; }
          .content-tabs--styled .tabs li a span {
            padding: 1.0625rem 0.625rem;
            border-right: 0.0625rem solid #ccc;
            width: 100%; }
          .content-tabs--styled .tabs li a:hover, .content-tabs--styled .tabs li a:focus {
            background-color: #FFFFFF;
            color: #333333; }
          .content-tabs--styled .tabs li a.is-active {
            background-color: #cecece; }
            .content-tabs--styled .tabs li a.is-active:before, .content-tabs--styled .tabs li a.is-active:after {
              background-color: #dc0b21;
              content: '';
              position: absolute;
              height: 0.625rem;
              width: 90%;
              left: 50%;
              transform: translateX(-50%); }
            .content-tabs--styled .tabs li a.is-active:before {
              top: -0.625rem;
              border-radius: 0.375rem 0.375rem 0 0; }
            .content-tabs--styled .tabs li a.is-active:after {
              bottom: -0.625rem;
              border-radius: 0 0 0.375rem 0.375rem; }
            .content-tabs--styled .tabs li a.is-active span:before, .content-tabs--styled .tabs li a.is-active span:after {
              color: #FFFFFF;
              font-family: FontAwesome;
              position: absolute;
              line-height: 1.5rem;
              z-index: 1; }
            .content-tabs--styled .tabs li a.is-active span:before {
              content: '';
              top: -0.3125rem;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0.9375rem 0.9375rem 0 0.9375rem;
              border-color: #dc0b21 transparent transparent transparent;
              left: 50%;
              transform: translateX(-50%); }
            .content-tabs--styled .tabs li a.is-active span:after {
              background-color: #dc0b21;
              content: '\f104 \f105';
              height: 1.625rem;
              font-size: 1.25rem;
              width: 40%;
              left: 50%;
              transform: translateX(-50%);
              bottom: -1.25rem;
              border-radius: 0.3125rem;
              text-align: center;
              padding: 0 0.625rem;
              letter-spacing: 2.5625rem; } }
          @media (min-width: 768px) and (max-width: 1199px) {
            .content-tabs--styled .tabs li a.is-active span:after {
              letter-spacing: 2rem; } }
          @media (min-width: 768px) and (max-width: 991px) {
            .content-tabs--styled .tabs li a.is-active span:after {
              letter-spacing: 0.875rem; } }
  @media (min-width: 768px) {
            .content-tabs--styled .tabs li a.is-active span {
              color: #dc0b21; }
      .content-tabs--styled .tabs.is-hover .hover-marker {
        display: block; }
      .content-tabs--styled .tabs.is-hover li a.is-active:before, .content-tabs--styled .tabs.is-hover li a.is-active:after,
      .content-tabs--styled .tabs.is-hover li a.is-active span:before,
      .content-tabs--styled .tabs.is-hover li a.is-active span:after {
        display: none; } }
  @media (min-width: 992px) {
    .content-tabs--styled .tabs li a {
      font-size: 1.25rem; } }

.video-playlist-carousel {
  position: relative; }
  .video-playlist-carousel .carousel {
    display: flex;
    flex-direction: row; }
    @media (max-width: 767px) {
      .video-playlist-carousel .carousel {
        flex-direction: column; } }
  .video-playlist-carousel .carousel-inner {
    flex: 2; }
    @media (max-width: 767px) {
      .video-playlist-carousel .carousel-inner {
        flex: unset; } }
  .video-playlist-carousel .carousel-indicators-wrapper {
    position: relative;
    width: 25%;
    overflow: auto;
    overflow-x: hidden; }
    @media (max-width: 767px) {
      .video-playlist-carousel .carousel-indicators-wrapper {
        height: 7.5rem;
        overflow: auto;
        overflow-y: hidden;
        width: 100%; } }
    .video-playlist-carousel .carousel-indicators-wrapper ul {
      display: flex;
      flex-direction: column;
      top: 0;
      bottom: auto;
      justify-content: flex-start; }
      @media (max-width: 767px) {
        .video-playlist-carousel .carousel-indicators-wrapper ul {
          margin: 0;
          flex-direction: row; } }
  .video-playlist-carousel .carousel-indicators {
    white-space: nowrap;
    bottom: 0;
    margin-left: 0.625rem;
    margin-right: 0; }
    .video-playlist-carousel .carousel-indicators > li {
      width: 100%;
      height: auto;
      text-indent: initial;
      border-bottom: 0.0625rem solid #cecece;
      padding-bottom: 0.25rem;
      background-color: transparent; }
      .video-playlist-carousel .carousel-indicators > li img {
        width: 100%; }
      .video-playlist-carousel .carousel-indicators > li a {
        display: block;
        display: flex;
        position: relative; }
      .video-playlist-carousel .carousel-indicators > li.active img {
        opacity: 0.7; }
  .video-playlist-carousel .video-item__image {
    border-bottom: 0.125rem solid transparent;
    border-top: 0.125rem solid transparent;
    margin: 0.3125rem 0 0;
    padding: 0.125rem 0;
    width: 5.625rem; }
    .video-playlist-carousel .video-item__image img {
      height: auto;
      width: 100%; }
  .video-playlist-carousel .video-item__content {
    font-size: 0.875rem;
    margin-left: 0.625rem;
    margin-top: 0.3125rem;
    position: relative;
    display: flex;
    flex-direction: column;
    white-space: initial;
    width: 7.5rem; }
    .video-playlist-carousel .video-item__content .video-title {
      max-height: 2.8125rem;
      overflow: hidden;
      white-space: unset; }
    .video-playlist-carousel .video-item__content .video-action {
      position: relative;
      padding-left: 1.5625rem;
      font-weight: bold; }
      .video-playlist-carousel .video-item__content .video-action:before {
        content: '\f144';
        position: absolute;
        font-size: 1.25rem;
        display: block;
        top: -0.25rem;
        left: 0;
        color: #dc0b21;
        text-align: center;
        font-family: FontAwesome; }
  .video-playlist-carousel .is-playing .video-item__image {
    border-bottom-color: #dc0b21;
    border-top-color: #dc0b21; }

.video-wrapper {
  position: relative;
  height: 0;
  padding-top: 56.25%;
  margin: 0;
  background-color: #000; }
  .video-wrapper .video-player {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    opacity: 0; }
  .video-wrapper .video-poster {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-size: cover;
    overflow: hidden;
    opacity: 1;
    transition: opacity 800ms, height 0s;
    transition-delay: 0s, 0s; }
  .video-wrapper .video-player-content {
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
    color: #FFFFFF;
    padding-top: 3.625rem; }
    @media (min-width: 768px) {
      .video-wrapper .video-player-content {
        top: auto;
        bottom: 17%;
        left: 13%;
        width: 36.25rem;
        padding-top: 0; } }
    @media (max-width: 991px) {
      .video-wrapper .video-player-content {
        width: auto; } }
    .video-wrapper .video-player-content h2 {
      color: #FFFFFF;
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 20px;
      line-height: 2rem;
      margin-bottom: 0; }
      @media (min-width: 768px) {
        .video-wrapper .video-player-content h2 {
          margin-bottom: 0.6875rem;
          width: 100%; } }
    .video-wrapper .video-player-content p {
      color: #FFFFFF;
      font-weight: 600;
      margin-bottom: 0; }
      .video-wrapper .video-player-content p.video-duration {
        font-weight: 300;
        margin-bottom: 0.6875rem; }
        @media (min-width: 768px) {
          .video-wrapper .video-player-content p.video-duration {
            font-size: 15px;
            font-size: 1.5rem; } }
    .video-wrapper .video-player-content a {
      color: #dc0b21;
      font-weight: bold; }
      @media (max-width: 767px) {
        .video-wrapper .video-player-content a {
          padding-left: 2.1875rem; }
          .video-wrapper .video-player-content a .fa {
            font-size: 1.875rem; } }
      .video-wrapper .video-player-content a:hover, .video-wrapper .video-player-content a:focus {
        color: #FFFFFF; }
  .video-wrapper.video-wrapper-active .video-player {
    opacity: 1;
    z-index: 1; }
  .video-wrapper.video-wrapper-active .video-poster {
    opacity: 0;
    z-index: 0; }
  .video-wrapper.is-loading .loading-div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0; }

.error-message {
  text-align: center; }
  .error-message h2 {
    padding: 1.875rem; }

.hidden {
  visibility: hidden; }

iframe.video-player .ytp-pause-overlay {
  display: none; }

.document-list {
  display: inline-block;
  margin: 1.25rem 0; }
  .document-list h2 {
    font-size: 1.125rem; }
  .document-list ul {
    padding: 0;
    margin: 0; }
    .document-list ul li {
      border-bottom: 0.0625rem solid #cdcdcd;
      padding: 0.3125rem 0; }
      .document-list ul li:last-child {
        border-bottom-width: 0; }
    .document-list ul a {
      color: #dc0b21;
      margin-right: 0.625rem; }
      .document-list ul a:hover, .document-list ul a:focus {
        color: #333333; }
      .document-list ul a:before {
        top: 0; }

.downloadable-reports .report-list {
  margin: 0;
  padding: 0; }
  .downloadable-reports .report-list > li {
    border-top: 0.0625rem solid #cecece;
    padding-bottom: 1.875rem;
    padding-top: 1.875rem; }

.downloadable-reports .report-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .downloadable-reports .report-item {
      flex-direction: column; } }
  .downloadable-reports .report-item__detail {
    border-right: 0.0625rem solid #cecece;
    margin-right: 1.875rem;
    padding-right: 1.875rem; }
    @media (max-width: 767px) {
      .downloadable-reports .report-item__detail {
        border-right-width: 0;
        padding-right: 0; } }

.downloadable-reports h3 {
  font-size: 1.25rem;
  margin-bottom: 1.3125rem; }
  .downloadable-reports h3 a {
    color: #dc0b21; }
    .downloadable-reports h3 a:hover, .downloadable-reports h3 a:focus {
      color: #333333; }

.downloadable-reports h4, .downloadable-reports p {
  font-size: 0.875rem; }

.downloadable-reports .tags-list {
  margin: 0;
  padding: 0; }
  @media (max-width: 767px) {
    .downloadable-reports .tags-list {
      margin-bottom: 0.75rem; } }
  .downloadable-reports .tags-list li {
    color: #dc0b21;
    display: inline;
    font-size: 0.875rem;
    position: relative;
    padding-right: 0.375rem; }
    .downloadable-reports .tags-list li:after {
      content: ',';
      position: absolute;
      bottom: 0;
      right: 0.125rem;
      width: 3px;
      height: 100%; }
    .downloadable-reports .tags-list li:last-child:after {
      display: none; }

.downloadable-reports .report-mime-type {
  margin-bottom: 1.125rem; }
  @media (max-width: 991px) {
    .downloadable-reports .report-mime-type img {
      width: 9.375rem; } }
  @media (max-width: 767px) {
    .downloadable-reports .report-mime-type {
      display: none; } }

.downloadable-reports a.report-download {
  color: #dc0b21;
  display: inline-block;
  font-size: 0.9375rem;
  font-weight: bold;
  margin-bottom: 0.75rem; }
  .downloadable-reports a.report-download:hover, .downloadable-reports a.report-download:focus {
    color: #333333; }

.downloadable-reports .report-doc-size {
  font-size: 0.75rem;
  display: block; }
  @media (max-width: 767px) {
    .downloadable-reports .report-doc-size {
      display: inline-block; } }

.downloadable-reports .pagination ul {
  display: flex;
  justify-content: space-between;
  width: 100%; }

.reports-filter {
  background-color: #f1f2ed;
  border: 0.0625rem solid #cecece;
  padding: 1.5625rem 0.9375rem; }
  .reports-filter label {
    font-size: 0.875rem; }
  .reports-filter input.form-check-input {
    margin-right: 0.25rem; }
  @media (max-width: 767px) {
    .reports-filter {
      padding: 0; }
      .reports-filter .toggle-heading {
        padding: 0.9375rem 0.9375rem; }
        .reports-filter .toggle-heading h3 {
          margin-bottom: 0; }
      .reports-filter .toggle-content {
        padding: 0.9375rem; } }

@media (max-width: 767px) {
  .toggle-action .toggle-heading {
    position: relative; }
    .toggle-action .toggle-heading:after {
      content: '\f078';
      color: #dc0b21;
      display: block;
      font-family: FontAwesome;
      height: 1.125rem;
      margin-top: -0.3125rem;
      position: absolute;
      right: 0.625rem;
      top: 50%;
      transform: translateY(-50%);
      width: 1.25rem; }
  .toggle-action .toggle-content {
    display: none; }
  .toggle-action.is-visible .toggle-heading {
    background-color: #dc0b21; }
    .toggle-action.is-visible .toggle-heading h3 {
      color: #FFFFFF; }
    .toggle-action.is-visible .toggle-heading:after {
      color: #FFFFFF; } }

.video-playlist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1.25rem 0; }
  .video-playlist__highlight {
    background-color: #661357;
    padding: 1.25rem;
    width: 20%; }
    @media (max-width: 1199px) {
      .video-playlist__highlight {
        width: 25%; } }
    @media (max-width: 767px) {
      .video-playlist__highlight {
        width: 100%; } }
    .video-playlist__highlight .player-title {
      border-bottom: 0.0625rem solid #FFFFFF;
      color: #FFFFFF;
      padding-bottom: 0.9375rem; }
  .video-playlist__container {
    flex: 2; }
    @media (max-width: 767px) {
      .video-playlist__container {
        width: 100%; } }

.icon-block {
  display: flex;
  justify-content: space-around; }
  @media (max-width: 767px) {
    .icon-block {
      flex-direction: column; } }
  .icon-block h2 {
    font-size: 1rem;
    color: #dc0b21; }
    .icon-block h2 a {
      color: #dc0b21; }
      .icon-block h2 a:hover, .icon-block h2 a:focus {
        color: #333333; }
  .icon-block p {
    font-size: 0.9375rem; }
  .icon-block__item {
    display: flex;
    flex-direction: row;
    margin-right: 1.25rem;
    flex: 1; }
    @media (max-width: 767px) {
      .icon-block__item {
        margin-right: 0;
        margin-bottom: 0.75rem; } }
    .icon-block__item:last-child {
      margin-right: 0; }
    .icon-block__item .item-image {
      margin-right: 1.25rem; }
      .icon-block__item .item-image img {
        width: 3.75rem; }

.info-search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.875rem; }
  .info-search label {
    text-align: right;
    margin-right: 1.25rem;
    font-size: 1.375rem;
    font-family: 'PFCentroSlabPro_Medium';
    margin-top: 0.3125rem; }
  .info-search__form {
    position: relative; }
  .info-search .search-button {
    position: absolute;
    padding: 0;
    right: 0.5rem;
    top: 0; }
    .info-search .search-button input {
      height: 2.25rem;
      width: 2.5rem; }
    .info-search .search-button:after {
      content: '\f002';
      font-family: FontAwesome;
      position: absolute;
      font-size: 1.125rem;
      padding-top: 0.4375rem;
      top: 0;
      right: 0;
      width: 2.5rem;
      height: 2.25rem;
      display: block;
      background-color: #FFFFFF;
      color: #dc0b21;
      pointer-events: none; }
    @media (max-width: 991px) {
      .info-search .search-button {
        top: 0; } }
  @media (max-width: 767px) {
    .info-search {
      flex-direction: column; }
      .info-search__heading, .info-search__form {
        flex: 0 0 100%; }
      .info-search label {
        text-align: center;
        width: 100%; }
      .info-search .search-button {
        margin: 0; } }
  .info-search.research-db-search {
    padding-top: 1.25rem; }
    .info-search.research-db-search .form-inline {
      flex-direction: column;
      align-items: flex-start;
      width: 100%; }
    .info-search.research-db-search .info-search__form {
      display: flex;
      flex-direction: row;
      width: 100%; }
      .info-search.research-db-search .info-search__form .form-control {
        flex: 0 0 40%; }

.required-field-marker {
  color: #dc0b21; }

.p-30 {
  padding: 1.875rem 0; }

.radio-container {
  display: flex; }

.generic-form-label {
  width: 200px;
  min-width: 200px; }
  .generic-form-label + div {
    margin-left: 6.9px; }
  @media (max-width: 767px) {
    .generic-form-label {
      display: block;
      width: auto; }
      .generic-form-label + div {
        margin-left: 0; } }

.generic-textarea-container {
  display: inline-flex; }

.generic-form-div {
  margin-bottom: 1.875rem;
  display: flex; }
  @media (max-width: 767px) {
    .generic-form-div {
      flex-direction: column; } }
  .generic-form-div input[type="text"],
  .generic-form-div textarea,
  .generic-form-div select {
    width: 400px;
    min-height: 35px;
    line-height: 30px;
    padding: 0 10px;
    border: 1px solid #ccc;
    background: #FFFFFF; }
    @media (max-width: 767px) {
      .generic-form-div input[type="text"],
      .generic-form-div textarea,
      .generic-form-div select {
        width: 100%; } }
  .generic-form-div input[type="text"],
  .generic-form-div select {
    height: 35px; }
  .generic-form-div .generic-filter-dropdown-wrapper {
    position: relative; }
    .generic-form-div .generic-filter-dropdown-wrapper select {
      padding-right: 2.1875rem; }
    .generic-form-div .generic-filter-dropdown-wrapper:after {
      content: '';
      position: absolute;
      height: 35px;
      right: 0;
      top: 0;
      width: 35px;
      background-color: #333333;
      pointer-events: none; }
    .generic-form-div .generic-filter-dropdown-wrapper:before {
      content: '\f078';
      font-family: 'FontAwesome';
      right: 8px;
      top: 5px;
      position: absolute;
      color: #FFFFFF;
      z-index: 999;
      pointer-events: none; }

.generic-error-message {
  display: none; }
  .generic-error-message.active {
    display: block; }

.field-error input,
.field-error select,
.field-error textarea {
  border-color: #dc0b21 !important;
  background-color: rgba(220, 20, 60, 0.3) !important; }

.field-error label {
  color: #dc0b21; }

.field-error .generic-error-message {
  display: block !important;
  color: #dc0b21;
  visibility: visible !important; }

body.ielts-online-training .carousel-container {
  border: 0; }

body.ielts-online-training .hero-statement {
  margin-bottom: 0rem;
  padding: 0;
  border: 0; }

body.ielts-online-training .slick-dots {
  top: -40px;
  position: relative;
  z-index: 101; }

body.ielts-online-training .carousel-wrapper-single .slide-text-container:before {
  z-index: 99; }

body.ielts-online-training .carousel-wrapper .slide-text-container {
  bottom: -1px;
  z-index: 104; }

body.ielts-online-training .boxed {
  background-color: #fff;
  box-shadow: 0 0 0.75rem -0.125rem rgba(0, 0, 0, 0.75);
  padding: 0 1.25rem;
  max-height: calc(100vh - 20px);
  min-height: calc(100vh - 20px); }

body.ielts-online-training .slick-dotted.slick-slider,
body.ielts-online-training .slick-dots {
  margin-bottom: 10px; }

body.ielts-online-training .clearfix:after {
  content: "";
  display: table;
  clear: both; }

body.ielts-online-training hr {
  color: #cecece;
  border-width: 3px;
  margin: 10px 0; }

body.ielts-online-training img {
  width: 100%; }

body.ielts-online-training .tutorial-image {
  position: relative; }
  body.ielts-online-training .tutorial-image img {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%; }
  body.ielts-online-training .tutorial-image .image-snippet {
    position: absolute;
    right: -10px;
    bottom: -10px;
    background-color: #dc0b21;
    color: #FFFFFF;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    border: 3px solid #FFFFFF;
    font-size: 0.8125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center; }

body.ielts-online-training .sub-content-nav ul {
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  border: 1px solid #dc0b21;
  border-bottom-width: 0;
  border-right-width: 0; }
  body.ielts-online-training .sub-content-nav ul li {
    border-bottom: 1px solid #dc0b21;
    display: inline-block;
    border-bottom: 1px solid #dc0b21;
    border-right: 1px solid #dc0b21;
    width: 33.33334%; }
    body.ielts-online-training .sub-content-nav ul li a {
      display: block;
      padding: 0.625rem;
      color: #dc0b21;
      height: 100%;
      transition: all 200ms ease-out; }
      body.ielts-online-training .sub-content-nav ul li a:hover {
        background: #dc0b21;
        color: #FFFFFF; }

.bandscore-form .generic-form-div {
  align-items: center; }
  @media (max-width: 767px) {
    .bandscore-form .generic-form-div {
      align-items: flex-start; } }
  .bandscore-form .generic-form-div div {
    margin-right: 1.25rem; }
  .bandscore-form .generic-form-div select,
  .bandscore-form .generic-form-div input {
    width: 100% !important;
    display: block; }

.btn-action {
  background-color: #333333;
  padding-top: 7px;
  padding-left: 7px;
  padding-bottom: 5px;
  padding-right: 3px; }

.ml-10 {
  margin-left: 0.625rem; }

.forms-widget-page {
  margin-top: 1.875rem; }

.receive-updates {
  display: flex; }
  @media (max-width: 767px) {
    .receive-updates {
      flex-direction: column; } }
  .receive-updates > div:first-child {
    margin-right: 1.875rem; }
  .receive-updates ul {
    padding-left: 0; }
    .receive-updates ul li {
      position: relative; }
      .receive-updates ul li:before {
        content: "• ";
        color: red;
        /* or whatever color you prefer */ }
  .receive-updates .receive-updates-checkbox span {
    font-weight: bold; }

table.last-step-results {
  width: 100%;
  border-collapse: collapse; }
  table.last-step-results thead {
    background-color: #efefef;
    font-weight: bold; }
  table.last-step-results tr {
    border: 1px solid #ccc; }
    table.last-step-results tr td {
      padding: 0.9375rem;
      border-right: 1px solid #ccc; }
      table.last-step-results tr td:last-child {
        border: 0; }
  @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
    table.last-step-results {
      /* Force table to not be like tables anymore */
      /* Hide table headers (but not display: none;, for accessibility) */
      /*
            Label the data
            */ }
      table.last-step-results table, table.last-step-results thead, table.last-step-results tbody, table.last-step-results th, table.last-step-results td, table.last-step-results tr {
        display: block; }
      table.last-step-results thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px; }
      table.last-step-results tr {
        border: 1px solid #ccc; }
      table.last-step-results td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%; }
      table.last-step-results td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap; }
      table.last-step-results td:nth-of-type(1):before {
        content: "Organisation"; }
      table.last-step-results td:nth-of-type(2):before {
        content: "Band score requirements"; }
      table.last-step-results td:nth-of-type(3):before {
        content: "Results preference"; }
      table.last-step-results td:nth-of-type(4):before {
        content: "Nominated representative"; } }

form[data-wffm] .form-group {
  width: 610px;
  position: relative;
  padding-left: 210px; }
  @media (max-width: 760px) {
    form[data-wffm] .form-group {
      width: 100%;
      padding-left: 0; } }
  form[data-wffm] .form-group .control-label {
    position: absolute;
    left: 0;
    width: 200px;
    margin-right: 10px; }
    @media (max-width: 760px) {
      form[data-wffm] .form-group .control-label {
        position: relative;
        width: 100%; } }
  form[data-wffm] .form-group input, form[data-wffm] .form-group textarea {
    width: 400px; }
    @media (max-width: 760px) {
      form[data-wffm] .form-group input, form[data-wffm] .form-group textarea {
        width: 100%; } }
  form[data-wffm] .form-group .radio {
    width: 400px; }
    form[data-wffm] .form-group .radio label {
      display: flex;
      align-items: center; }
      form[data-wffm] .form-group .radio label input {
        width: auto;
        margin-right: 5px; }
    @media (max-width: 760px) {
      form[data-wffm] .form-group .radio {
        width: 100%; } }
  form[data-wffm] .form-group.required-field .control-label:after {
    content: "*";
    color: #dc0b21;
    display: inline;
    position: absolute;
    top: 0;
    right: -8px; }
    @media (max-width: 760px) {
      form[data-wffm] .form-group.required-field .control-label:after {
        position: static;
        margin-left: 5px; } }
  form[data-wffm] .form-group.has-error input[type='text'], form[data-wffm] .form-group.has-error input[type='email'], form[data-wffm] .form-group.has-error input[type='number'], form[data-wffm] .form-group.has-error input[type='password'], form[data-wffm] .form-group.has-error textarea {
    border: 1px solid #dc0b21;
    background-color: #fbb8bf; }
  form[data-wffm] .form-group.has-error .field-validation-error {
    width: 400px;
    color: #dc0b21; }
  form[data-wffm] .form-group.hidden {
    display: none; }

form[data-wffm] .validation-summary-errors {
  display: none; }

.spotlight-panel {
  display: flex;
  flex-direction: row;
  margin: 0.625rem 0; }
  .spotlight-panel .accordion {
    margin-bottom: 0; }
  .spotlight-panel__content {
    background-color: #661357;
    color: #FFFFFF;
    border-left: 0.125rem solid #dc0b21; }
    .spotlight-panel__content h2 {
      margin: 1.25rem 1.25rem 0;
      color: #FFFFFF;
      border-bottom: 0.0625rem solid #FFFFFF;
      padding-bottom: 0.75rem;
      margin-bottom: 0.75rem; }
    .spotlight-panel__content p {
      margin: 1.25rem; }
    .spotlight-panel__content .btn:first-child, .spotlight-panel__content .black-button:first-child {
      margin-left: 1.25rem; }
  .spotlight-panel__content, .spotlight-panel__item {
    flex: 1; }
  .spotlight-panel__item {
    overflow: hidden;
    position: relative; }
    .spotlight-panel__item img {
      height: 100%;
      width: 100%;
      max-width: 100%;
      display: block; }
  .spotlight-panel--right {
    flex-direction: row-reverse; }
    .spotlight-panel--right .spotlight-panel__content {
      border-left-width: 0;
      border-right: 0.125rem solid #dc0b21; }
  .spotlight-panel--small .spotlight-panel__content {
    background-color: transparent;
    color: #333333; }
    .spotlight-panel--small .spotlight-panel__content h2 {
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
      color: #333333; }
      .spotlight-panel--small .spotlight-panel__content h2 a {
        margin: 0;
        padding: 0;
        color: #dc0b21;
        font-family: 'PFCentroSlabPro_Medium'; }
        .spotlight-panel--small .spotlight-panel__content h2 a:hover, .spotlight-panel--small .spotlight-panel__content h2 a:focus {
          color: #333333; }
    .spotlight-panel--small .spotlight-panel__content p {
      margin-left: 0; }
    .spotlight-panel--small .spotlight-panel__content .btn-transparent {
      color: #dc0b21; }
  .spotlight-panel--small .spotlight-panel__content {
    flex: 2;
    border-width: 0; }
  .spotlight-panel--small .spotlight-panel__item {
    margin-right: 1.25rem; }
    .spotlight-panel--small .spotlight-panel__item img {
      height: auto;
      width: 100%; }
  .spotlight-panel--small--right {
    flex-direction: row-reverse; }
    .spotlight-panel--small--right .spotlight-panel__content h4, .spotlight-panel--small--right .spotlight-panel__content p {
      margin-left: 0; }
    .spotlight-panel--small--right .spotlight-panel__item {
      margin-right: 0; }
  .spotlight-panel--small .blockquote-il {
    width: auto; }
  @media (max-width: 767px) {
    .spotlight-panel {
      flex-direction: column; }
      .spotlight-panel__content {
        padding-bottom: 1.25rem;
        border-width: 0; }
      .spotlight-panel--right .spotlight-panel__content {
        border-width: 0; }
      .spotlight-panel--small .spotlight-panel__item {
        margin: 0 0 1.25rem; }
        .spotlight-panel--small .spotlight-panel__item img {
          width: 100%; } }

.spotlight-panel + .spotlight-panel--small {
  margin-top: 40px;
  padding-top: 40px; }

.spotlight-panel--small + .spotlight-panel--small {
  margin-top: 50px;
  padding-top: 50px;
  border-top: solid 1px #cecece; }

.accordion {
  margin-bottom: 5rem; }
  .accordion__title {
    position: relative;
    margin: 0 0 0.125rem;
    padding: 0 45px 0 0;
    background-color: #333333;
    box-shadow: 0 1px 0 0 #e7e7e8; }
    .accordion__title:before {
      content: "";
      display: table;
      clear: both; }
    .accordion__title:after {
      content: "";
      display: table;
      clear: both; }
    .accordion__title h2 {
      display: inline-block;
      padding-left: 1.25rem;
      padding: 20px 0 20px 20px;
      margin: 0;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: bold;
      letter-spacing: .4px;
      color: #FFFFFF !important;
      border-bottom: none; }
    .accordion__title:before, .accordion__title:after {
      position: absolute;
      background: #FFFFFF;
      -webkit-transition: 400ms all ease-out;
      transition: 300ms all ease-out;
      top: 1.875rem;
      right: 1.25rem;
      height: 0.125rem;
      width: 0.625rem; }
    .accordion__title:before {
      transform: rotate(45deg);
      right: 1.625rem; }
    .accordion__title:after {
      transform: rotate(-45deg); }
    .accordion__title.active {
      border: 0;
      background-color: #dc0b21; }
      .accordion__title.active:before {
        transform: rotate(-45deg); }
      .accordion__title.active:after {
        transform: rotate(45deg); }
    .accordion__title:hover {
      cursor: pointer; }
  .accordion__content {
    border-width: 0px 1px 1px 1px;
    display: none;
    padding: 1.25rem; }
    .accordion__content > img {
      max-width: 100%;
      height: auto;
      display: block; }
    .accordion__content .rte ~ .btn ~ h3, .accordion__content .rte ~ .black-button ~ h3 {
      padding-top: 10px;
      margin-bottom: 0; }
      @media (max-width: 767px) {
        .accordion__content .rte ~ .btn ~ h3, .accordion__content .rte ~ .black-button ~ h3 {
          padding-top: 20px; } }
    @media (max-width: 767px) {
      .accordion__content .rte ~ .btn ~ .rte, .accordion__content .rte ~ .black-button ~ .rte {
        padding-top: 20px; } }
    .accordion__content .rte > p {
      margin: 0 0 1.25rem; }
      .accordion__content .rte > p:last-child {
        margin-bottom: 0; }
    .accordion__content .rte {
      margin-bottom: 0;
      padding: 0; }
    .accordion__content .accordion__footer {
      margin-top: 1.875rem; }
    .accordion__content .btn, .accordion__content .black-button {
      display: inline-block; }
      .accordion__content .btn:hover, .accordion__content .black-button:hover {
        text-decoration: none; }
    .accordion__content .social-media {
      float: right; }
      .accordion__content .social-media a {
        transition: color 200ms linear; }
        .accordion__content .social-media a:first-child {
          margin-right: 0.3125rem; }
        .accordion__content .social-media a:hover {
          text-decoration: none; }
    .accordion__content .icon-text {
      margin-top: 20px;
      margin-bottom: 20px; }
      .accordion__content .icon-text--item.message h2 {
        font-size: 40px;
        font-size: 4rem;
        line-height: 40px;
        line-height: 4rem; }
      .accordion__content .icon-text--item_title {
        font-size: 40px;
        font-size: 4rem;
        line-height: 40px;
        line-height: 4rem; }

.results-table {
  margin-top: 3.75rem; }
  .results-table h3 {
    margin-bottom: 1.25rem; }
  .results-table .result-item-header {
    display: flex;
    flex-direction: row;
    background-color: #f1f1ed;
    font-family: 'PFCentroSlabPro_Medium';
    font-size: 1.0625rem;
    padding: 0.9375rem 0.625rem; }
    @media (max-width: 767px) {
      .results-table .result-item-header {
        display: none; } }
    .results-table .result-item-header .header-text {
      flex: 1;
      margin-right: 0.625rem; }
      .results-table .result-item-header .header-text:first-child {
        flex: 2; }
      .results-table .result-item-header .header-text:last-child {
        margin-right: 0; }
    .results-table .result-item-header .filter-arrow:after {
      content: '\f078';
      font-family: FontAwesome;
      color: #dc0b21;
      font-size: 0.75rem;
      text-align: center;
      position: relative;
      height: 1.25rem;
      width: 1.25rem;
      display: block;
      border: 0.0625rem solid #cecece;
      display: inline-block;
      top: 0rem;
      margin-left: 0.625rem; }
    .results-table .result-item-header .filter-arrow.up:after {
      content: '\f077'; }
  .results-table .result-item {
    display: flex;
    flex-direction: row;
    padding: 0.9375rem 0.625rem;
    border-bottom: 0.0625rem solid #cecece; }
    @media (max-width: 767px) {
      .results-table .result-item {
        background-color: #f1f2ed;
        border: 0.0625rem solid #cecece;
        flex-direction: column;
        margin-bottom: 1.25rem;
        padding: 0.9375rem; } }
    .results-table .result-item .item-text {
      flex: 1;
      align-self: center;
      margin-right: 0.625rem; }
      .results-table .result-item .item-text:first-child {
        flex: 2; }
      .results-table .result-item .item-text.item-text--action {
        display: flex;
        justify-content: flex-end; }
      @media (max-width: 767px) {
        .results-table .result-item .item-text {
          align-self: left;
          margin-bottom: 0.625rem; }
          .results-table .result-item .item-text.item-text--name {
            border-bottom: 0.125rem solid #cecece;
            padding-bottom: 0.625rem;
            width: 100%; }
          .results-table .result-item .item-text.item-text--location:before {
            content: attr(data-item);
            display: block;
            font-weight: bold;
            padding: 0.3125rem 0;
            width: 100%; }
          .results-table .result-item .item-text.item-text--band:before {
            content: attr(data-item);
            display: block;
            font-weight: bold;
            padding: 0.3125rem 0;
            width: 100%; } }
      .results-table .result-item .item-text:last-child {
        margin-right: 0; }
      .results-table .result-item .item-text a {
        color: #dc0b21;
        font-weight: bold; }
        .results-table .result-item .item-text a:hover, .results-table .result-item .item-text a:focus {
          color: #333333; }
        .results-table .result-item .item-text a.btn.btn-default, .results-table .result-item .item-text a.black-button {
          color: #FFFFFF;
          font-weight: normal; }
          .results-table .result-item .item-text a.btn.btn-default:hover, .results-table .result-item .item-text a.black-button:hover, .results-table .result-item .item-text a.btn.btn-default:focus, .results-table .result-item .item-text a.black-button:focus {
            color: #dc0b21; }

.organisational-search {
  margin-bottom: 30px; }
  .organisational-search .form-inline {
    flex: 1; }
  .organisational-search a.accordion__title {
    background: transparent; }
  .organisational-search__form {
    margin-right: 1.25rem;
    position: relative;
    width: 40%; }
    @media (max-width: 767px) {
      .organisational-search__form {
        margin-bottom: 1.25rem;
        width: 100%; } }
    .organisational-search__form .form-control {
      width: 100%; }
    .organisational-search__form .search-button {
      background: transparent;
      border-radius: 0;
      position: absolute;
      right: 0;
      top: 0; }
  .organisational-search .form-row {
    margin: 0; }
    @media (max-width: 767px) {
      .organisational-search .form-row {
        flex-direction: column; } }
  .organisational-search .form-group {
    flex: 1;
    margin-right: 1.25rem; }
    @media (max-width: 767px) {
      .organisational-search .form-group {
        margin-right: 0;
        margin-bottom: 1.25rem; } }
    .organisational-search .form-group:last-child {
      margin-right: 0; }
  .organisational-search .custom-select {
    border-color: #333333;
    width: 100%; }
  .organisational-search .accordion {
    margin-bottom: 0; }
    .organisational-search .accordion h3 {
      margin-bottom: 1.25rem; }
    .organisational-search .accordion__title {
      background-color: #FFFFFF;
      color: #dc0b21;
      box-shadow: none; }
      .organisational-search .accordion__title:before, .organisational-search .accordion__title:after {
        display: none; }
      .organisational-search .accordion__title .fa {
        padding: 0 0.3125rem;
        transition: all .2s linear; }
      .organisational-search .accordion__title.active .fa {
        transform: rotate(180deg); }
    .organisational-search .accordion__content {
      display: none;
      padding-left: 0;
      padding-right: 0;
      width: 100%; }
  .organisational-search__advanced-type {
    border-top: 0.0625rem solid #cecece;
    border-bottom: 0.0625rem solid #cecece;
    margin-top: 1.25rem;
    padding: 1.25rem 0; }
    @media (max-width: 767px) {
      .organisational-search__advanced-type .form-row {
        flex-direction: column; } }
    .organisational-search__advanced-type .form-check {
      flex: 1;
      align-items: left;
      justify-content: left; }
    .organisational-search__advanced-type label {
      display: block;
      font-weight: bold; }
    .organisational-search__advanced-type span {
      display: block;
      font-weight: normal;
      margin-left: 1.375rem; }
      @media (max-width: 767px) {
        .organisational-search__advanced-type span {
          margin-left: 0; } }
  .organisational-search__advanced-location {
    border-bottom: 0.0625rem solid #cecece;
    padding: 1.25rem 0;
    margin-bottom: 1.25rem; }
  .organisational-search__results {
    margin-top: 1.875rem; }
    .organisational-search__results h3 {
      margin-bottom: 1.25rem; }
    .organisational-search__results-filter {
      display: flex;
      margin-bottom: 1.25rem; }
      .organisational-search__results-filter h3 {
        margin: 0.625rem 1.25rem 0 0; }
        @media (max-width: 767px) {
          .organisational-search__results-filter h3 {
            margin-bottom: 1.25rem; } }
      .organisational-search__results-filter .form-row {
        width: 100%; }
    .organisational-search__results .result-item-header {
      display: flex;
      flex-direction: row;
      background-color: #f1f1ed;
      font-family: 'PFCentroSlabPro_Medium';
      font-size: 1.0625rem;
      padding: 0.9375rem 0.625rem; }
      @media (max-width: 767px) {
        .organisational-search__results .result-item-header {
          display: none; } }
      .organisational-search__results .result-item-header .header-text {
        flex: 1;
        margin-right: 0.625rem; }
        .organisational-search__results .result-item-header .header-text:last-child {
          margin-right: 0; }
      .organisational-search__results .result-item-header .filter-arrow:after {
        content: '\f078';
        font-family: FontAwesome;
        color: #dc0b21;
        font-size: 0.75rem;
        text-align: center;
        position: relative;
        height: 1.25rem;
        width: 1.25rem;
        display: block;
        border: 0.0625rem solid #cecece;
        display: inline-block;
        top: 0rem;
        margin-left: 0.625rem; }
      .organisational-search__results .result-item-header .filter-arrow.up:after {
        content: '\f077'; }
    .organisational-search__results .result-item .item-text[class*=" icon-"] {
      font-weight: bold; }
      .organisational-search__results .result-item .item-text[class*=" icon-"]:before {
        content: '';
        position: relative;
        height: 1.875rem;
        width: 1.875rem;
        display: inline-block;
        margin-bottom: -0.625rem;
        margin-right: 0.625rem; }
    .organisational-search__results .result-item .item-text.icon-education:before {
      background: url("../img/icons/icon-education.png") no-repeat center center;
      background-size: contain; }
    .organisational-search__results .result-item .item-text.icon-government:before {
      background: url("../img/icons/icon-government.jpg") no-repeat center center;
      background-size: contain; }
    .organisational-search__results .result-item .item-text.icon-employer:before {
      background: url("../img/icons/icon-employer.png") no-repeat center center;
      background-size: contain; }
    .organisational-search__results .result-item .item-text.icon-professional-registration:before {
      background: url("../img/icons/icon-professional-registration.jpg") no-repeat center center;
      background-size: contain; }
    .organisational-search__results .result-item .item-text .band-score {
      border: 0.0625rem solid #cecece;
      text-align: center;
      position: relative;
      height: 2.5rem;
      width: 3.125rem;
      color: #FFFFFF;
      justify-content: center;
      align-items: center;
      display: flex;
      font-weight: bold;
      background-color: #8b1a77; }
      .organisational-search__results .result-item .item-text .band-score:before {
        content: '';
        height: 100%;
        display: block;
        height: 2.375rem;
        width: 3rem;
        position: absolute;
        left: 0rem;
        top: 0rem;
        z-index: 0;
        border: 0.25rem solid #FFFFFF; }
        @media (max-width: 767px) {
          .organisational-search__results .result-item .item-text .band-score:before {
            border-color: #f1f2ed; } }

.band-score-list {
  margin-bottom: 1.875rem; }
  @media (max-width: 767px) {
    .band-score-list {
      text-align: center; } }
  .band-score-list__item {
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 1.5625rem; }
    .band-score-list__item__desc {
      font-weight: bold;
      margin-bottom: 0px; }
  .band-score-list .band-score {
    position: relative;
    height: 8.125rem;
    width: 8.125rem;
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    font-size: 60px;
    background-color: #8b1a77;
    margin: 8px; }
    .band-score-list .band-score:before {
      content: '';
      border: 0.0625rem solid #cecece;
      height: 100%;
      display: block;
      height: 9.125rem;
      width: 9.125rem;
      position: absolute;
      left: -8px;
      top: -8px;
      z-index: 0;
      background-color: #FFFFFF;
      z-index: -1; }
      @media (max-width: 767px) {
        .band-score-list .band-score:before {
          border-color: #f1f2ed; } }

.mb-60 {
  margin-bottom: 3.75rem; }

.mb-30 {
  margin-bottom: 1.875rem; }

.mb-15 {
  margin-bottom: 0.9375rem; }

.btn-default input[type="submit"], .black-button input[type="submit"] {
  background: transparent !important; }

.pagination {
  justify-content: center;
  margin: 1.875rem 0 1.25rem; }
  .pagination ul {
    margin: 0;
    padding: 0; }
    .pagination ul li {
      display: inline-block;
      font-size: 1rem;
      padding: 0 0.3125rem; }
      .pagination ul li a {
        color: #dc0b21;
        font-family: "Lato", sans-serif; }
        .pagination ul li a:hover, .pagination ul li a:focus {
          color: #333333; }
      .pagination ul li.is-hidden {
        display: none; }
      .pagination ul li.page-prev a, .pagination ul li.page-next a {
        position: relative; }
        .pagination ul li.page-prev a:before, .pagination ul li.page-next a:before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          color: #dc0b21;
          font-family: FontAwesome;
          transition: all .2s ease-in-out; }
        .pagination ul li.page-prev a:hover:before, .pagination ul li.page-prev a:focus:before, .pagination ul li.page-next a:hover:before, .pagination ul li.page-next a:focus:before {
          color: #333333; }
      .pagination ul li.page-prev a {
        padding-left: 30px; }
        .pagination ul li.page-prev a:before {
          content: '\f053 \f053';
          left: 0; }
      .pagination ul li.page-next a {
        padding-right: 30px; }
        .pagination ul li.page-next a:before {
          content: '\f054 \f054';
          right: 0; }
      .pagination ul li.current a {
        color: #333333; }
        .pagination ul li.current a:hover, .pagination ul li.current a:focus {
          color: #dc0b21; }

.locations-map {
  position: relative;
  overflow: hidden; }
  .locations-map h3 {
    margin-bottom: 1.25rem;
    margin-right: 1.875rem; }
  .locations-map #map {
    width: 100%;
    height: 25rem; }
  .locations-map .locations-panel {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 21.875rem;
    background-color: #FFFFFF;
    height: 100%;
    z-index: 1;
    overflow: visible;
    left: -21.875rem;
    transition: left .2s linear; }
    @media (max-width: 991px) {
      .locations-map .locations-panel {
        width: 18.75rem;
        left: -18.75rem; } }
    @media (max-width: 767px) {
      .locations-map .locations-panel {
        left: 0;
        width: 100%;
        position: relative;
        height: 3.75rem; } }
    .locations-map .locations-panel .icon-close {
      background-color: #FFFFFF;
      color: #333333;
      display: block;
      font-family: FontAwesome;
      position: absolute;
      text-align: center;
      top: 0.625rem;
      right: -2.5rem;
      padding: 0.75rem 0.625rem;
      border-radius: 0 0.3125rem 0.3125rem 0; }
      @media (max-width: 767px) {
        .locations-map .locations-panel .icon-close {
          right: 0;
          top: 0; }
          .locations-map .locations-panel .icon-close:before {
            content: attr(data-show-text);
            font-size: 0.75rem;
            font-family: "Lato", sans-serif;
            left: 0; } }
      .locations-map .locations-panel .icon-close:after {
        content: '\f054 \f054';
        font-size: 1.25rem;
        letter-spacing: -0.25rem; }
        @media (max-width: 767px) {
          .locations-map .locations-panel .icon-close:after {
            display: inline-block;
            transform: rotate(90deg);
            right: 0; } }
      .locations-map .locations-panel .icon-close:hover, .locations-map .locations-panel .icon-close:focus {
        color: #dc0b21; }
    .locations-map .locations-panel.is-visible {
      box-shadow: 0.5rem 0.125rem 0.75rem -0.375rem rgba(0, 0, 0, 0.75);
      left: 0; }
      @media (max-width: 767px) {
        .locations-map .locations-panel.is-visible {
          height: 25rem; }
          .locations-map .locations-panel.is-visible .panel-list {
            height: 18.75rem;
            top: 3.75rem; } }
      .locations-map .locations-panel.is-visible .icon-close {
        right: 0;
        top: 0;
        padding: 0.625rem; }
        @media (max-width: 767px) {
          .locations-map .locations-panel.is-visible .icon-close {
            padding: 0.75rem 0.625rem; }
            .locations-map .locations-panel.is-visible .icon-close:before {
              content: attr(data-hide-text); } }
        .locations-map .locations-panel.is-visible .icon-close:after {
          content: '\f00d';
          letter-spacing: normal; }
          @media (max-width: 767px) {
            .locations-map .locations-panel.is-visible .icon-close:after {
              content: '\f054 \f054';
              font-size: 1.25rem;
              letter-spacing: -0.25rem;
              transform: rotate(-90deg); } }
  .locations-map .panel-controls {
    padding: 0.375rem 0; }
    @media (max-width: 767px) {
      .locations-map .panel-controls {
        padding-left: 0;
        padding-right: 0; } }
    .locations-map .panel-controls .reset-list.is-hidden {
      display: none; }
      .locations-map .panel-controls .reset-list.is-hidden ~ .locations-count {
        display: inline-block; }
    .locations-map .panel-controls .locations-count {
      padding-top: 0.75rem;
      font-weight: bold;
      font-size: 0.875rem;
      display: none; }
  .locations-map .panel-list {
    position: absolute;
    top: 3.125rem;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0.75rem 1.25rem 0.75rem 0;
    width: 100%; }
    @media (max-width: 767px) {
      .locations-map .panel-list {
        height: 0;
        padding: 0; } }
  .locations-map .locations-list {
    margin: 0;
    padding: 0; }
    .locations-map .locations-list li {
      position: relative;
      padding: 0.75rem 1.5625rem 0.75rem 2.1875rem;
      border-bottom: 0.0625rem solid #cecece; }
      .locations-map .locations-list li .icon-flag {
        display: block;
        position: absolute;
        top: 1.5625rem;
        left: 0.1875rem; }
        .locations-map .locations-list li .icon-flag img {
          width: 1.875rem; }
      .locations-map .locations-list li p {
        font-size: 0.875rem;
        margin-bottom: 0.75rem; }
        .locations-map .locations-list li p.location-name {
          font-weight: bold;
          margin-top: 0.75rem; }
      .locations-map .locations-list li a.location-directions, .locations-map .locations-list li a.location-url {
        color: #dc0b21; }
        .locations-map .locations-list li a.location-directions:hover, .locations-map .locations-list li a.location-directions:focus, .locations-map .locations-list li a.location-url:hover, .locations-map .locations-list li a.location-url:focus {
          color: #333333; }
      .locations-map .locations-list li a.location-directions i {
        position: relative;
        top: 0.125rem; }
      .locations-map .locations-list li.is-hidden {
        display: none; }
      .locations-map .locations-list li.is-selected {
        background-color: #f1f2ed;
        transition: all .2s ease-in; }

.map-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin-bottom: 20px; }
  .map-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%; }

.find-test-location__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .find-test-location__container {
      flex-direction: column; } }

.find-test-location .find-test-location__breadcrumb {
  display: none; }

.find-test-location__option {
  border: 0.0625rem solid #cecece;
  border-radius: 0.3125rem;
  padding: 1.5625rem 1.5625rem 5.3125rem;
  flex: 1;
  margin-bottom: 1.5625rem;
  position: relative; }
  .find-test-location__option:first-child {
    margin-right: 0.9375rem; }
  .find-test-location__option:last-child {
    margin-left: 0.9375rem; }
  @media (max-width: 767px) {
    .find-test-location__option:first-child {
      margin-right: 0; }
    .find-test-location__option:last-child {
      margin-left: 0; } }
  .find-test-location__option .btn, .find-test-location__option .black-button {
    display: block;
    text-align: center;
    position: absolute;
    bottom: 1.5625rem;
    left: 1.5625rem;
    right: 1.5625rem; }
  .find-test-location__option.is-selected {
    background-color: #f1f2ed; }

.find-test-location__content {
  display: none;
  padding: 1.5625rem 0; }

.find-test-location__panel h2 {
  margin-bottom: 1.25rem; }

.find-test-location__panel .two-col-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .find-test-location__panel .two-col-layout {
      flex-direction: column; } }

.find-test-location__panel .column {
  flex: 1;
  margin-bottom: 1.5625rem;
  position: relative; }
  .find-test-location__panel .column:first-child {
    margin-right: 0.9375rem; }
  .find-test-location__panel .column:last-child {
    margin-left: 0.9375rem; }
  @media (max-width: 767px) {
    .find-test-location__panel .column:first-child {
      margin-right: 0; }
    .find-test-location__panel .column:last-child {
      margin-left: 0; } }

.find-test-location__search {
  display: flex;
  flex-direction: row; }
  @media (max-width: 767px) {
    .find-test-location__search {
      flex-direction: column; } }
  .find-test-location__search .form-row {
    margin: 0;
    flex: 1; }
    @media (max-width: 767px) {
      .find-test-location__search .form-row {
        flex-direction: column; } }
    .find-test-location__search .form-row .form-group {
      width: 230px; }
      @media (max-width: 767px) {
        .find-test-location__search .form-row .form-group {
          width: auto; } }
    .find-test-location__search .form-row .custom-select {
      width: 100%; }
  .find-test-location__search .btn, .find-test-location__search .black-button {
    display: flex;
    align-self: flex-start;
    margin-left: 1.25rem; }
    @media (max-width: 767px) {
      .find-test-location__search .btn, .find-test-location__search .black-button {
        margin-left: 0; } }

.find-test-location-results__filter .form-inline {
  flex: 1; }

.find-test-location-results__filter .form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%; }
  @media (max-width: 767px) {
    .find-test-location-results__filter .form-row {
      flex-direction: column; } }
  .find-test-location-results__filter .form-row h3 {
    margin: 0.625rem 1.25rem 0 0; }
    @media (max-width: 767px) {
      .find-test-location-results__filter .form-row h3 {
        margin-bottom: 1.25rem; } }
  .find-test-location-results__filter .form-row .form-group {
    flex: 1;
    margin-right: 1.25rem; }
    .find-test-location-results__filter .form-row .form-group:last-child {
      margin-right: 0; }
    @media (max-width: 767px) {
      .find-test-location-results__filter .form-row .form-group {
        margin-right: 0; } }
  .find-test-location-results__filter .form-row .custom-select {
    width: 100%; }

.find-test-location-results .results-table {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem; }

.find-test-location__breadcrumb {
  margin-bottom: 1.5625rem; }
  .find-test-location__breadcrumb ul {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0; }
    @media (max-width: 767px) {
      .find-test-location__breadcrumb ul {
        position: relative; } }
  .find-test-location__breadcrumb li {
    font-size: 0.875rem;
    background-color: #f0f0f0;
    font-weight: bold;
    position: relative;
    border: 1px solid #ccc; }
    @media (max-width: 767px) {
      .find-test-location__breadcrumb li {
        flex: 1;
        margin-top: 30px; } }
    .find-test-location__breadcrumb li a {
      color: #ccc;
      padding: 0.375rem 0.75rem 0.375rem 1.875rem;
      display: block; }
    .find-test-location__breadcrumb li:first-child a {
      padding-left: 0.75rem; }
    .find-test-location__breadcrumb li:before, .find-test-location__breadcrumb li:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      z-index: 1; }
    .find-test-location__breadcrumb li:before {
      border-width: 1.125rem 0 1.125rem 1.125rem;
      border-color: transparent transparent transparent #ccc;
      right: -1.4375rem;
      top: -0.125rem; }
    .find-test-location__breadcrumb li:after {
      border-width: 1rem 0 1rem 1rem;
      border-color: transparent transparent transparent #f0f0f0;
      right: -1rem;
      top: 0; }
    @media (max-width: 767px) {
      .find-test-location__breadcrumb li:before, .find-test-location__breadcrumb li:after {
        border-width: 0; } }
    .find-test-location__breadcrumb li a:before, .find-test-location__breadcrumb li a:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      z-index: 1; }
    .find-test-location__breadcrumb li a:before {
      border-width: 1.375rem 0 1.375rem 1.375rem;
      border-color: transparent transparent transparent #FFFFFF;
      right: -1.375rem;
      top: -0.375rem; }
    .find-test-location__breadcrumb li a:after {
      border-width: 1.125rem 0 1.125rem 1.125rem;
      border-color: transparent transparent transparent #ccc;
      right: -1.125rem;
      top: -0.125rem; }
    @media (max-width: 767px) {
      .find-test-location__breadcrumb li a {
        font-size: 0;
        color: transparent; }
        .find-test-location__breadcrumb li a:before, .find-test-location__breadcrumb li a:after {
          display: none; } }
    .find-test-location__breadcrumb li:last-child:before, .find-test-location__breadcrumb li:last-child:after {
      display: none; }
    .find-test-location__breadcrumb li:last-child a:before, .find-test-location__breadcrumb li:last-child a:after {
      display: none; }
    .find-test-location__breadcrumb li.is-active {
      background-color: #dc0b21; }
      @media (max-width: 767px) {
        .find-test-location__breadcrumb li.is-active {
          position: static;
          border-color: #dc0b21; }
          .find-test-location__breadcrumb li.is-active:before {
            content: attr(data-mobile-text);
            position: absolute;
            display: flex;
            width: 100%;
            left: 0; } }
      .find-test-location__breadcrumb li.is-active a {
        color: #FFFFFF; }
      .find-test-location__breadcrumb li.is-active:after {
        border-color: transparent transparent transparent #dc0b21; }

.book-a-test {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }
  .book-a-test .column {
    margin-bottom: 0; }
    @media (max-width: 767px) {
      .book-a-test .column:first-child {
        margin-bottom: 1.5625rem; } }
  .book-a-test .address-title {
    font-weight: bold;
    margin-bottom: 0; }
  .book-a-test address ul {
    margin: 0;
    padding: 0; }
  .book-a-test address li span {
    font-weight: bold;
    width: 1.5625rem;
    display: inline-block; }
  .book-a-test address a {
    color: #dc0b21; }
  .book-a-test .fee {
    font-weight: bold; }
    .book-a-test .fee span {
      font-size: 1.125rem; }

.two-col-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .two-col-layout {
      flex-direction: column; } }
  .two-col-layout .column {
    flex: 1;
    margin-bottom: 1.5625rem;
    position: relative; }
    .two-col-layout .column:first-child {
      margin-right: 0.9375rem; }
    .two-col-layout .column:last-child {
      margin-left: 0.9375rem; }
    @media (max-width: 767px) {
      .two-col-layout .column:first-child {
        margin-right: 0; }
      .two-col-layout .column:last-child {
        margin-left: 0; } }

.ot-breadcrumb-wrapper .ot-breadcrumb-list,
.ot-breadcrumb-wrapper .steps-wrapper-list {
  counter-reset: section;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0 0 1.875rem; }
  @media (max-width: 767px) {
    .ot-breadcrumb-wrapper .ot-breadcrumb-list,
    .ot-breadcrumb-wrapper .steps-wrapper-list {
      flex-direction: column; } }
  .ot-breadcrumb-wrapper .ot-breadcrumb-list li,
  .ot-breadcrumb-wrapper .steps-wrapper-list li {
    font-size: 0.875rem;
    background-color: #f0f0f0;
    padding: 0.375rem 0.75rem;
    color: #333333;
    font-weight: bold;
    position: relative;
    padding-left: 1.875rem; }
    @media (max-width: 767px) {
      .ot-breadcrumb-wrapper .ot-breadcrumb-list li,
      .ot-breadcrumb-wrapper .steps-wrapper-list li {
        padding-left: 0.75rem; } }
    .ot-breadcrumb-wrapper .ot-breadcrumb-list li:before,
    .ot-breadcrumb-wrapper .steps-wrapper-list li:before {
      counter-increment: section;
      content: counter(section) ".";
      color: #333333; }
    .ot-breadcrumb-wrapper .ot-breadcrumb-list li span:before, .ot-breadcrumb-wrapper .ot-breadcrumb-list li span:after,
    .ot-breadcrumb-wrapper .steps-wrapper-list li span:before,
    .ot-breadcrumb-wrapper .steps-wrapper-list li span:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      z-index: 1; }
    .ot-breadcrumb-wrapper .ot-breadcrumb-list li span:before,
    .ot-breadcrumb-wrapper .steps-wrapper-list li span:before {
      border-width: 1.25rem 0 1.25rem 1.25rem;
      border-color: transparent transparent transparent #FFFFFF;
      right: -1.25rem;
      top: -0.25rem; }
    .ot-breadcrumb-wrapper .ot-breadcrumb-list li span:after,
    .ot-breadcrumb-wrapper .steps-wrapper-list li span:after {
      border-width: 1rem 0 1rem 1rem;
      border-color: transparent transparent transparent #f0f0f0;
      right: -1rem;
      top: 0; }
    @media (max-width: 767px) {
      .ot-breadcrumb-wrapper .ot-breadcrumb-list li span:before, .ot-breadcrumb-wrapper .ot-breadcrumb-list li span:after,
      .ot-breadcrumb-wrapper .steps-wrapper-list li span:before,
      .ot-breadcrumb-wrapper .steps-wrapper-list li span:after {
        display: none; } }
    .ot-breadcrumb-wrapper .ot-breadcrumb-list li.is-active,
    .ot-breadcrumb-wrapper .steps-wrapper-list li.is-active {
      background-color: #dc0b21;
      color: #FFFFFF; }
      .ot-breadcrumb-wrapper .ot-breadcrumb-list li.is-active:before,
      .ot-breadcrumb-wrapper .steps-wrapper-list li.is-active:before {
        color: #FFFFFF; }
      .ot-breadcrumb-wrapper .ot-breadcrumb-list li.is-active span:after,
      .ot-breadcrumb-wrapper .steps-wrapper-list li.is-active span:after {
        border-color: transparent transparent transparent #dc0b21; }
    .ot-breadcrumb-wrapper .ot-breadcrumb-list li:first-child,
    .ot-breadcrumb-wrapper .steps-wrapper-list li:first-child {
      padding-left: 0.75rem; }
    .ot-breadcrumb-wrapper .ot-breadcrumb-list li:last-child span:before, .ot-breadcrumb-wrapper .ot-breadcrumb-list li:last-child span:after,
    .ot-breadcrumb-wrapper .steps-wrapper-list li:last-child span:before,
    .ot-breadcrumb-wrapper .steps-wrapper-list li:last-child span:after {
      display: none; }

.step-title {
  font-size: 2rem;
  line-height: 2.5rem;
  color: #dc0b21; }

.steps-wrapper--list {
  margin-left: 0;
  padding-left: 0; }
  .steps-wrapper--list li {
    display: inline-block; }
    .steps-wrapper--list li:first-of-type a:after {
      border: 0; }
    .steps-wrapper--list li:last-of-type a:before {
      border: 0; }
    .steps-wrapper--list li a {
      font-size: 0.8125rem;
      padding: 0.3125rem 0.9375rem;
      background: #e8e8e1;
      border: 1px #ccc;
      border-width: 1px 0;
      position: relative;
      margin-bottom: 0.3125rem;
      display: inline-block;
      margin-right: 3px; }
      .steps-wrapper--list li a:before {
        content: "";
        position: absolute;
        right: -6px;
        bottom: 0;
        width: 0;
        height: 0;
        border-top: 15px solid #fff;
        border-left: 6px solid #e8e8e1;
        border-bottom: 14px solid #fff;
        z-index: 7; }
      .steps-wrapper--list li a:after {
        content: "";
        position: absolute;
        left: -1px;
        bottom: 0;
        width: 0;
        height: 0;
        border-top: 15px solid #e8e8e1;
        border-left: 6px solid #fff;
        border-bottom: 14px solid #e8e8e1;
        z-index: 6; }
      .steps-wrapper--list li a.inactive {
        pointer-events: none;
        background-color: #ccc; }
        .steps-wrapper--list li a.inactive:before {
          border-left-color: #ccc; }
        .steps-wrapper--list li a.inactive::after {
          border-top-color: #ccc;
          border-bottom-color: #ccc; }
      .steps-wrapper--list li a.is-active {
        background: #dc0b21;
        color: #FFFFFF; }
        .steps-wrapper--list li a.is-active:before {
          border-left-color: #dc0b21; }
        .steps-wrapper--list li a.is-active::after {
          border-top-color: #dc0b21;
          border-bottom-color: #dc0b21; }

.ot-pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;
  width: 100%; }
  .ot-pagination .btn-next .fa {
    padding-left: 0.3125rem;
    padding-right: 0; }

.ot-feature-col-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 0 0 25%;
  flex-wrap: wrap; }
  @media (max-width: 767px) {
    .ot-feature-col-block {
      flex-direction: column;
      flex: 0 0 100%; } }
  @media (max-width: 1199px) {
    .ot-feature-col-block [class^="col-md-"] {
      margin-bottom: 1.875rem; } }
  @media (max-width: 767px) {
    .ot-feature-col-block [class^="col-md-"] {
      margin-bottom: 1.25rem; } }
  .ot-feature-col-block .feature-item {
    background-color: #661357;
    height: 100%;
    width: 24%; }
    .ot-feature-col-block .feature-item h2, .ot-feature-col-block .feature-item p, .ot-feature-col-block .feature-item span {
      color: #FFFFFF; }
    .ot-feature-col-block .feature-item p {
      font-size: 0.875rem; }
    .ot-feature-col-block .feature-item span.btn, .ot-feature-col-block .feature-item span.black-button {
      padding-left: 0; }
      .ot-feature-col-block .feature-item span.btn .fa, .ot-feature-col-block .feature-item span.black-button .fa {
        color: #FFFFFF;
        position: relative;
        top: 0.125rem;
        transition: color .1s ease-in; }
    .ot-feature-col-block .feature-item a {
      display: block;
      padding: 0.75rem 1.125rem; }
      .ot-feature-col-block .feature-item a:hover span.btn, .ot-feature-col-block .feature-item a:hover span.black-button {
        color: #dc0b21; }
        .ot-feature-col-block .feature-item a:hover span.btn .fa, .ot-feature-col-block .feature-item a:hover span.black-button .fa {
          color: #dc0b21; }

.ot-numbered-list ol {
  margin: 0;
  padding: 0;
  counter-reset: item; }
  .ot-numbered-list ol li {
    position: relative;
    min-height: 3.125rem;
    padding-left: 4.0625rem;
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
    font-weight: bold; }
    .ot-numbered-list ol li:before {
      left: 0;
      top: 0;
      position: absolute;
      counter-increment: item;
      content: counter(item);
      color: #333333;
      height: 3.125rem;
      width: 3.125rem;
      background-color: #661357;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF; }
    .ot-numbered-list ol li a {
      color: #dc0b21; }
      .ot-numbered-list ol li a:hover {
        color: #333333; }

.ot-content-tabs--styled .tabs ul {
  border-bottom-width: 0; }

.ot-content-tabs--styled .tabs li {
  margin-bottom: 0.3125rem; }
  .ot-content-tabs--styled .tabs li a {
    background-color: #661357;
    color: #FFFFFF;
    border: 0.0625rem solid #661357; }
    .ot-content-tabs--styled .tabs li a:hover {
      background-color: #FFFFFF;
      color: #661357; }
    .ot-content-tabs--styled .tabs li a.is-active {
      background-color: #FFFFFF;
      color: #661357; }

.ot-content-tabs--styled .item:before {
  background-color: #661357;
  color: #FFFFFF;
  border: 0.0625rem solid #661357;
  margin-bottom: 0.0625rem; }

.ot-content-tabs--styled .item:after {
  content: '\f078';
  font-family: FontAwesome;
  position: absolute;
  top: 0.375rem;
  right: 0.375rem;
  width: 1.25rem;
  height: 1.125rem;
  display: block;
  color: #FFFFFF; }

.ot-content-tabs--styled .item.is-active:before {
  background-color: #FFFFFF;
  color: #661357;
  margin-bottom: 0.75rem; }

.ot-content-tabs--styled .item.is-active:after {
  color: #661357; }

.ot-content-tabs--styled.mobile-accordion .tabs ul {
  flex-direction: column;
  border-bottom-width: 0; }
  .ot-content-tabs--styled.mobile-accordion .tabs ul li {
    margin-bottom: 0.3125rem; }
    .ot-content-tabs--styled.mobile-accordion .tabs ul li a {
      background-color: #661357;
      color: #FFFFFF;
      border: 0.0625rem solid #661357; }
      .ot-content-tabs--styled.mobile-accordion .tabs ul li a:hover {
        background-color: #FFFFFF;
        color: #661357; }
      .ot-content-tabs--styled.mobile-accordion .tabs ul li a.is-active {
        background-color: #FFFFFF;
        color: #661357; }

.ot-content-tabs--styled.mobile-accordion .item:before {
  background-color: #661357; }

.ot-content-tabs--styled.mobile-accordion .item.is-active:before {
  background-color: #FFFFFF;
  color: #661357; }

@media (min-width: 768px) {
  .ot-content-tabs--styled {
    display: flex;
    flex-direction: row; }
    .ot-content-tabs--styled .tabs {
      margin-right: 1.25rem; }
      .ot-content-tabs--styled .tabs ul {
        border-bottom-width: 0;
        flex-direction: column; }
      .ot-content-tabs--styled .tabs li {
        border-right-width: 0;
        margin-bottom: 0.125rem; }
        .ot-content-tabs--styled .tabs li a {
          font-size: 1rem; }
          .ot-content-tabs--styled .tabs li a:hover {
            background-color: #FFFFFF;
            color: #661357; }
          .ot-content-tabs--styled .tabs li a.is-active:after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0.5rem 0 0.5rem 0.5rem;
            border-color: transparent transparent transparent #661357;
            right: -0.5rem; }
    .ot-content-tabs--styled .item {
      width: 0; }
      .ot-content-tabs--styled .item:before, .ot-content-tabs--styled .item:after {
        display: none; }
      .ot-content-tabs--styled .item.is-active {
        width: 100%; } }

.ot-tutorial-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem; }
  .ot-tutorial-question h4 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
    line-height: normal; }
  .ot-tutorial-question label {
    margin-right: 0.9375rem; }
  .ot-tutorial-question input {
    margin-right: 0.3125rem; }
  .ot-tutorial-question .quiz-answer {
    display: none;
    background: #f0f0f0;
    padding: 5px 10px;
    margin: 0;
    color: #222;
    vertical-align: top; }
    .ot-tutorial-question .quiz-answer.active {
      display: inline-block; }
    .ot-tutorial-question .quiz-answer.error {
      color: #dc0b21; }

.ot-numbers-tabs .tabs ul {
  border-bottom-width: 0;
  flex-direction: row;
  flex-wrap: wrap; }

.ot-numbers-tabs .tabs li {
  margin-bottom: 0.3125rem;
  margin-left: 0.3125rem;
  flex: 0 0 18%; }
  .ot-numbers-tabs .tabs li a {
    background-color: #661357;
    color: #FFFFFF;
    border: 0.0625rem solid #661357;
    text-align: center; }
    .ot-numbers-tabs .tabs li a:hover {
      background-color: #FFFFFF;
      color: #661357; }
    .ot-numbers-tabs .tabs li a.is-active {
      background-color: #FFFFFF;
      color: #661357; }

.ot-numbers-tabs.mobile-accordion .item:before {
  border: 0.0625rem solid #661357;
  background-color: #661357;
  margin-bottom: 0.3125rem; }

.ot-numbers-tabs.mobile-accordion .item.is-active:before {
  background-color: #FFFFFF;
  color: #661357; }

.ot-numbers-tabs.mobile-accordion .item.is-active:after {
  color: #661357; }

@media (min-width: 768px) {
  .ot-numbers-tabs {
    display: flex;
    flex-direction: column; }
    .ot-numbers-tabs .tabs {
      margin-right: 1.25rem; }
      .ot-numbers-tabs .tabs ul {
        border-bottom-width: 0;
        flex-direction: row;
        flex-wrap: nowrap; }
      .ot-numbers-tabs .tabs li {
        border-right-width: 0;
        margin-bottom: 0.125rem;
        flex: 1; }
        .ot-numbers-tabs .tabs li a {
          font-size: 1rem;
          padding: 1rem 1rem; }
          .ot-numbers-tabs .tabs li a:hover {
            background-color: #FFFFFF;
            color: #661357; }
    .ot-numbers-tabs .item {
      width: 0; }
      .ot-numbers-tabs .item:before, .ot-numbers-tabs .item:after {
        display: none; }
      .ot-numbers-tabs .item.is-active {
        width: 100%; } }

@media (min-width: 992px) {
  .ot-numbers-tabs .tabs li a {
    padding: 1.875rem 1rem; } }

.tab-wrapper {
  border-bottom: 3px solid #dc0b21;
  padding: 0;
  margin: 0;
  display: inline-flex;
  margin-bottom: 1.875rem; }
  @media (max-width: 767px) {
    .tab-wrapper {
      flex-direction: column;
      display: flex; } }
  .tab-wrapper li {
    margin-right: 5px; }
    @media (max-width: 767px) {
      .tab-wrapper li {
        margin-right: 0; } }
    .tab-wrapper li:last-child {
      margin-right: 0; }
    .tab-wrapper li a {
      display: block;
      padding: 10px;
      color: #FFFFFF;
      background-color: #333333; }
      .tab-wrapper li a.active {
        background-color: #dc0b21; }

.result-wrapper a {
  color: #dc0b21;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  font-family: inherit;
  text-decoration: none; }

.result-wrapper .search-result-item {
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid #ccc; }
  .result-wrapper .search-result-item:first-of-type {
    margin-top: 20px; }

.result-wrapper .search-result-item-title {
  font-weight: bold;
  font-size: 1.4375rem;
  font-family: PFCentroSlabPro_Medium;
  line-height: 1.875rem; }

.result-wrapper .tab-type-b-div {
  display: none;
  margin-bottom: 1.875rem; }
  .result-wrapper .tab-type-b-div.active {
    display: block; }

.ielts-online-training .p-20 {
  padding-top: 20px;
  padding-bottom: 20px; }

.ielts-online-training .carousel-wrapper {
  margin-top: 0px; }
  .ielts-online-training .carousel-wrapper .slide-text-container {
    bottom: -10px; }
  .ielts-online-training .carousel-wrapper .slick-dots .slick-active {
    display: none; }

.ielts-online-training .ot-feature-col-block .feature-item {
  width: 24%; }
  @media (max-width: 767px) {
    .ielts-online-training .ot-feature-col-block .feature-item {
      width: 100%; } }

.ielts-online-training .geo-wrapper .geo-message .container  {
   max-width:  1140px; }
