.reports-filter {
    background-color: $c-ielts-bg-grey;
    border: rem-calc(1) solid $c-ielts-border-grey;
    padding: rem-calc(25) rem-calc(15);

    label {
        font-size: rem-calc(14);
    }
    input.form-check-input {
        margin-right: rem-calc(4);
    }

    @include media-breakpoint-down(sm) {
        padding: 0;
        .toggle-heading {
            padding: rem-calc(15) rem-calc(15);
            h3 {
                margin-bottom: 0;
            }
        }

        .toggle-content {
            padding: rem-calc(15);
        }
    }
}