.responsive-image {
    img {
        width: 100%;
    }

    figcaption {
        font-family: $standardFont;
        font-size: rem-calc(14);
        font-style: italic;
        line-height: rem-calc(24);
    }
}