.btn {
    border-radius: 0;
}
.fa {
    color: $c-ielts-red;
    padding-right: rem-calc(5);
    font-size: 1.3rem;
}

.btn-default {
    color: $white;
    background-color: $c-black;
    .fa {
        position: relative;
        top: rem-calc(2);
    }
    input {
        border: none;
        background-color: inherit;
        color: $white;
    }
    &.btn-rte {
        position: relative;
        padding-left: rem-calc(35);
        margin-top: rem-calc(15);
        &:before {
            content: '\f061';
            position: absolute;
            font-family: FontAwesome;
            top: 6px;
            left: 10px;
            color: $c-ielts-red;
            font-size: rem-calc(20);
        }
        &.btn-icon-rotate {
            &:before {
                transform: rotate(180deg);
            }
        }
    }
    
}

.black-button {
    @extend .btn;
    @extend .btn-default;
    
    position: relative;
    padding-left: rem-calc(35);

    &:before {
        content: '\f061';
        position: absolute;
        font-family: FontAwesome;
        top: 6px;
        left: 10px;
        color: $c-ielts-red;
        font-size: rem-calc(20);
    }
}

input[type="submit"] {
    &.btn-default {
        @include animate;
        padding-left: rem-calc(40);
        background: $c-black url('../img/icons/icon-submit-arrow.png')no-repeat 7px 7px;
        &:hover {
            color: $c-ielts-red;
            cursor: pointer;
        }
    }
}

.btn-play {
    background: none;
    position: relative;
    padding-left: rem-calc(40);
    .fa {
        font-size: 2.5rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
    }
}

.btn-transparent {
    color: $white;
    background-color: transparent;
    .fa {
        position: relative;
        top: rem-calc(2);
    }
    input {
        border: none;
        background-color: inherit;
        color: $white;
    }
}

.btn-grey {
    background-color: #e8e8e1;
    border: 1px solid #ccc;
    color: $c-black;
}
