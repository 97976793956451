.footer {
    border-top: rem-calc(3) solid $c-ielts-dark-grey;
    padding-top: rem-calc(25);
    padding-bottom: rem-calc(25);
    
    .copyright {
        font-weight: bold;
        font-size: rem-calc(12);
        text-align: right;
        padding-right: rem-calc(12);
        @include media-breakpoint-down(md) {
            text-align: left;
        }
        p {
            margin-bottom: 0;
        }
    }

    .footer-links {
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            position: relative;
            font-size: rem-calc(14);
            &:after {
                content: '';
                position: absolute;
                height: rem-calc(12);
                width: rem-calc(1);
                background-color: $c-ielts-black;
                display: block;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            
            a {
                padding: 0 rem-calc(8) 0 rem-calc(4);
                display: block;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }

        &--site {
            font-weight: bold;
        }

        &--info {
            margin-bottom: rem-calc(18);
        }

        &--social {
            @include media-breakpoint-down(md) {
                margin-bottom: rem-calc(30);
            }
            li {
                .fa-plus-circle {
                    font-size: rem-calc(30);
                }
                .fa-youtube-play {
                    font-size: rem-calc(30);
                    color: #FF0000;
                }

                .fa-facebook-official {
                    font-size: rem-calc(30);
                    color: #3b5998;
                }

                a {
                    padding: 0 rem-calc(12);
                    display: flex;
                    align-items: center;
                }

                &:first-child {
                    a {
                        padding-left: 0;
                    }
                }
            }
        }

        &--partners {
            margin-bottom: rem-calc(30);
            display: flex;
            justify-content: flex-end;
            @include media-breakpoint-down(md) {
                flex-wrap: wrap;
                justify-content: flex-start;
            }
            li {
                margin-right: rem-calc(20);
                @include media-breakpoint-down(md) {
                    margin-right: 0;
                }
                @include media-breakpoint-down(sm) {
                    margin-bottom: rem-calc(20)
                }
                &:after {
                    display: none;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
