.card {
    border: none;
    h1, h2, h3, h4 {
        font-family: 'PFCentroSlabPro_Medium';
    }
    p, a {
        font-family: arial, sans-serif;
    }
}

.hero-small {
    margin-top: rem-calc(25);
    margin-bottom: rem-calc(25);
    border-bottom: rem-calc(1) solid $c-ielts-border-grey;
    .card-img {
        height: 100%;
        width: 35%;
        align-self: center;
        border-radius: 0;
    }
    .card-block {
        width: auto;
        padding: rem-calc(25);
        .card-title {
            padding-bottom: rem-calc(15);
            color: $c-ielts-red;
        }
        a {
            color: $c-ielts-red;
            font-weight: bold;
        }
    }

    &.left {
        flex-direction: row;
    }

    &.right {
        flex-direction: row-reverse;
        .card-block {
            padding-left: 0;
        }
    }
    @include media-breakpoint-down(sm) {
        &.left, &.right {
            flex-direction: column;
            .card-block {
                padding-left: 0;
            }
        }
        .card-img {
            width: 100%;
        }
        .card-block {
            width: 100%;
        }
    }
}

.hero-banner {
    padding: 0 rem-calc(25) 0 0;
    border-radius: 0;
    border: rem-calc(1) solid $c-ielts-border-grey;
    background: $c-ielts-bg-grey;
    margin-bottom: rem-calc(20);
    display: flex;
    flex-direction: row;
    
    .hero-block {
        padding: rem-calc(25) rem-calc(15) rem-calc(15) rem-calc(25);
        h2 {
            font-size: rem-calc(24);
            border-bottom: rem-calc(1) solid $c-ielts-border-grey;
            padding-bottom: rem-calc(4);
        }
        p {
            font-family: arial, sans-serif;
            font-weight: normal;
            font-size: rem-calc(16);
        }
    }

    @include media-breakpoint-down(sm) {
        .hero-img {
            display: none;
        }
        .hero-block {
            display: block;
            width: 100%;
        }
    }
}

.search-bar {
    padding: rem-calc(30) 0;
    .search-input {
        min-width: rem-calc(400);
        margin-right: rem-calc(20);
        margin-bottom: rem-calc(10);
        padding: rem-calc(6) rem-calc(10);
        vertical-align: top;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
    .btns.btn-default {
        margin-bottom: rem-calc(10);
    }
    .search-bar-radio-item {
        display: inline-block;
        margin-right: rem-calc(30);
        input {
            margin-right: rem-calc(5);
        }
    }
}
.paging-block {
    text-align: center;
    a {
        margin-right: rem-calc(5);
        &.current {
            color: $c-ielts-black;
        }
    }
}