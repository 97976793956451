.nav-container {
    background-color: $c-ielts-black;
    position: relative;
    z-index: 5;
    @include media-breakpoint-down(md) {
        width: auto;
        display: flex;
        align-items: center;
    }
    @include media-breakpoint-down(md) {
        background-color: transparent;
    }

    // Mobile nav toggle
    .nav-toggle {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
            color: $c-white;
            width: rem-calc(90);
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $c-ielts-black;
            padding: rem-calc(11) 0;

            .fa {
                color: $c-white;
            }
        }

        @include media-breakpoint-down(xs) {
            width: rem-calc(45);
            justify-content: flex-start;
            padding-left: rem-calc(14);

            .fa {
                margin-right: rem-calc(12);
            }
        }

        &.is-open {
            ~ ul {
                display: block;
            }
        }
    }

    // Main navigation
    .main-nav {
        margin: 0 auto;
        z-index: 1000;
        @include media-breakpoint-down(md) {
            padding-right: 0;
            position: relative;
        }
        > ul {
            display: flex;
            flex-direction: row;
            text-align: center;
            padding: 0;
            margin: 0;
            @include media-breakpoint-down(md) {
                display: none;
                background-color: $c-ielts-black;
                position: absolute;
                flex-direction: column;
                right: 0;
                z-index: 20;
                width: auto;
                text-align: left;
            }
            li {
                border-right: rem-calc(1) solid $c-white;
                display: flex;
                flex: 1;
                justify-content: center;
                position: relative;
                @include media-breakpoint-down(md) {
                    border-right-width: 0;
                    border-bottom: rem-calc(1) solid $c-white;
                    flex-direction: column;
                }
                
                &:first-child {
                    border-left: rem-calc(1) solid $c-white;
                    @include media-breakpoint-down(md) {
                        border-left-width: 0;
                    }
                }

                &:last-child {
                    @include media-breakpoint-down(md) {
                        border-bottom-width: 0;
                    }
                }

                &.is-active {
                    > a {
                        background-color: $c-ielts-red;

                        &.has-sub-items {
                            @include media-breakpoint-down(md) {
                                &:after {
                                    content: '\f077';
                                }
                            }
                        }
                    }
                    > .nav-sub-items {
                        display: block;
                    }
                }
            }
            a {
                color: $c-white;
                padding: rem-calc(6) rem-calc(5);
                width: 100%;
                position: relative;
                @include media-breakpoint-down(md) {
                    white-space: nowrap;
                    padding: rem-calc(6) rem-calc(35) rem-calc(6) rem-calc(12);
                }
                // Used for mobile
                &.has-sub-items {
                    @include media-breakpoint-down(md) {
                        &:after {
                            content:'\f078';
                            font-family: FontAwesome;
                            font-size: rem-calc(14);
                            position: absolute;
                            top: 50%;
                            right: rem-calc(10);
                            width: rem-calc(14);
                            height: rem-calc(20);
                            display: block;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
    
        .nav-sub-items {
            display: none;
            position: absolute;
            top: 100%;
            background-color: $c-ielts-black;;
            z-index: 1000;
            padding: 0;
            margin: 0;
            border-top: rem-calc(3) solid #b9091d;
            min-width: 100%;
            width: auto;
            left: 0;
            text-align: left;
            @include media-breakpoint-down(md) {
                position: relative;
            }
            > li {
                border: 0;
                border-bottom: rem-calc(1) solid $c-white;

                &:first-child {
                    border: 0;
                    border-bottom: rem-calc(1) solid $c-white;
                }

                &:last-child {
                    border-bottom: 0;
                }

                a {
                    padding: rem-calc(6) rem-calc(12);
                    white-space: nowrap;
                    @include media-breakpoint-down(md) {
                        padding-left: rem-calc(30);
                    }

                    &:hover,
                    &:focus {
                        background-color: $c-ielts-red;
                    }
                }
            }
        }
    }
}