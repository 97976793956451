// alpha

@mixin alpha-attribute($attribute, $color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  #{$attribute}: $solid-color;
  #{$attribute}: $color;
}

@mixin search-button() {
    background: transparent;
    border-radius: 0;
    position: absolute;
    right: 0px;
    color: $c-ielts-red;
}

@mixin mobile-search-button($background) {
    background-color: $background;
    color: $white;
    border-radius: 0;
    right: 0;
}

// box grad
@mixin box--grad {
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC42NSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.95) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.95))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 100%); /* IE10+ */
  background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-8 */
}

//box shadow
@mixin box-shadow( $horiz : .5em , $vert : .5em , $blur : 0px , $spread : 0px , $color : #000000 ){
  -webkit-box-shadow: $horiz $vert $blur $spread $color;
  -moz-box-shadow: $horiz $vert $blur $spread $color;
  box-shadow: $horiz $vert $blur $spread $color;
}

//rounded corners
@mixin rounded_corners ($radius) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}
//rotate
@mixin rotate ($deg) {
-webkit-transform: rotate(#{$deg}deg);
  -moz-transform:rotate(#{$deg}deg);
  -ms-transform: rotate(#{$deg}deg);
  -o-transform: rotate(#{$deg}deg);
  transform: rotate(#{$deg}deg);
}

@mixin background($imgpath,$position:0 0,$repeat: no-repeat, $color: $colorTransparent) {
  background: {
    image: url($imgpath);
    position: $position;
    repeat: $repeat;
    color: $colorTransparent;
  }
}

// Clearfix
@mixin clearfix {
  &:before {
    content: "";
    display: table;
    clear: both;
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Vertically center
@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

//Returns font size in rems and px
@mixin font-size($sizeValue: 18) {
  font-size: $sizeValue + px;
  font-size: ($sizeValue/10) + rem;
}

//Returns font size in rems and px
@mixin line-height($sizeValue: 22) {
  line-height: $sizeValue + px;
  line-height: ($sizeValue/10) + rem;
}

//maintain aspect ratio
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
//maintain aspect ratio
@mixin animate() {
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

//Cross browser opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
@mixin border-hover() {
    @include clearfix();
    position: relative;
    display:block;
    &:after {
      position: absolute;
      bottom: 0;
      width: 0;
      height: 2px;
      background: $color-brand-red;
      -webkit-transition: width 325ms ease;
      transition: width 325ms ease;
      display: block;
    }
    &:hover {
      &:after {
        width: 100%;
      }
    }
}

@mixin btn-icon($standardIcon, $hoverIcon, $disabledIcon) {
  &:before {
        content: '';
        position: absolute;
        display: block;
        background: url($standardIcon) no-repeat;
        top: 50%;
        transform: translateY(-50%);
        transition: background .3s;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50px;
      top: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background-color: #BD161C;
    }

    &.btn-disabled {
        &:hover {
            &:before {
              background: url($disabledIcon) no-repeat;
            }
        }
    }
    &:hover {
        &:before {
            background: url($hoverIcon) no-repeat;
        }
    }
}

// rem-calc() calculating from px to rem
@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}



@mixin hoverMarkerBorder() {
  &:before,
  &:after {
      background-color: $c-ielts-red;
      content: '';
      position: absolute;
      height: rem-calc(10);
      width: 90%;
      left: 50%;
      transform: translateX(-50%);
      // z-index: -1;
  }
  &:before {
      top: rem-calc(-10);
      border-radius: rem-calc(6) rem-calc(6) 0 0;
  }

  &:after {
      bottom: rem-calc(-10);
      border-radius: 0 0 rem-calc(6) rem-calc(6);
  }
}


@mixin hoverMarkerSpan() {
  span {
    &:before,
    &:after {
        color: $c-white;
        font-family:FontAwesome;
        position: absolute;
        line-height: rem-calc(24);
        z-index: 1;
    }
    &:before {
        content: '';
        top: rem-calc(-5);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: rem-calc(15) rem-calc(15) 0 rem-calc(15);
        border-color: $c-ielts-red transparent transparent transparent;
        left: 50%;
        transform: translateX(-50%);
    }
    &:after {
        background-color: $c-ielts-red;
        content: '\f104 \f105';
        height: rem-calc(26);
        font-size: rem-calc(20);
        width: 40%;
        left: 50%;
        transform: translateX(-50%);
        bottom: rem-calc(-20);
        border-radius: rem-calc(5);
        text-align: center;
        padding: 0 rem-calc(10);
        letter-spacing: rem-calc(41);

        @include media-breakpoint-down(lg) {
          letter-spacing: rem-calc(32);
        }
        @include media-breakpoint-down(md) {
          letter-spacing: rem-calc(14);
        }
    }
  }
}