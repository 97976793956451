.ot-breadcrumb-wrapper,
 {
    .ot-breadcrumb-list,
    .steps-wrapper-list {
        counter-reset: section;  
        list-style-type: none;
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0 0 rem-calc(30);

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        li {
            font-size: rem-calc(14);
            background-color: #f0f0f0;
            padding: rem-calc(6) rem-calc(12);
            color: $c-ielts-black;
            font-weight: bold;
            position: relative;
            padding-left: rem-calc(30);
            @include media-breakpoint-down(sm) {
                padding-left: rem-calc(12);
            }

            &:before {
                counter-increment: section;  
                content: counter(section) '.';
                color: $c-ielts-black;
            }

            span {
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    z-index: 1;
                }
                &:before {
                    border-width: rem-calc(20) 0 rem-calc(20) rem-calc(20);
                    border-color: transparent transparent transparent $c-white;
                    right: rem-calc(-20);
                    top: rem-calc(-4);
                }
                &:after {
                    border-width: rem-calc(16) 0 rem-calc(16) rem-calc(16);
                    border-color: transparent transparent transparent #f0f0f0;
                    right: rem-calc(-16);
                    top: 0;
                }

                @include media-breakpoint-down(sm) {
                    &:before,
                    &:after {
                        display: none;
                    }
                }
            }

            &.is-active {
                background-color: $c-ielts-red;
                color: $c-white;

                &:before {
                    color: $c-white;
                }
                span {
                    &:after {
                        border-color: transparent transparent transparent $c-ielts-red;
                    }
                }
            }

            &:first-child {
                padding-left: rem-calc(12);
            }

            &:last-child {
                span {
                    &:before,
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}

.step-title {
    font-size: rem-calc(32);
    line-height: rem-calc(40);
    color: $c-ielts-red;
}

.steps-wrapper {
    &--list {
        margin-left: 0;
        padding-left: 0;
        li {
            display: inline-block;
            &:first-of-type {
                a {
                    &:after {
                        border: 0;
                    }
                }                
            }
            &:last-of-type {
                a {
                    &:before {
                        border: 0;
                    }
                }
            }
            a {
                font-size: rem-calc(13);
                padding: rem-calc(5) rem-calc(15);
                background: #e8e8e1;
                border: 1px #ccc;
                border-width: 1px 0;
                position: relative;
                margin-bottom: rem-calc(5);
                display: inline-block;
                margin-right: 3px;

                
             
                &:before {
                    content: "";
                    position: absolute;
                    right: -6px;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-top: 15px solid #fff;
                    border-left: 6px solid #e8e8e1;
                    border-bottom: 14px solid #fff;
                    z-index: 7;
                }
                &:after {
                    content: "";
                    position: absolute;
                    left: -1px;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-top: 15px solid #e8e8e1;
                    border-left: 6px solid #fff;
                    border-bottom: 14px solid #e8e8e1;
                    z-index: 6;
                }
               
                &.inactive {
                    pointer-events: none;
                    background-color: #ccc;
                    &:before {
                        border-left-color: #ccc;
                    }
                    &::after {
                        border-top-color: #ccc;
                        border-bottom-color: #ccc;
                    }
                }

                &.is-active {
                    background: $c-ielts-red;
                    color: $c-white;
                    &:before {
                        border-left-color: $c-ielts-red;
                    }
                    &::after {
                        border-top-color: $c-ielts-red;
                        border-bottom-color: $c-ielts-red;
                    }
                }
            }
            
        }        
    }
}