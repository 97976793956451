.accordion {
	margin-bottom: rem-calc(80);
	&__title {
		position: relative;
		@include clearfix();
		margin: 0 0 rem-calc(2);
		padding: 0 45px 0 0;
        background-color: $c-ielts-black; 
		box-shadow: 0 1px 0 0 #e7e7e8;

		h2 {
			display: inline-block;
			padding-left: rem-calc(20);
			padding: 20px 0 20px 20px;
			margin: 0;
			font-size: rem-calc(16);
			line-height: rem-calc(24);
			font-weight: bold;
			letter-spacing: .4px;
			color: $c-white!important;
			border-bottom: none;
		}

		&:before, &:after {
			position: absolute;
			background: $c-white;
			-webkit-transition: 400ms all ease-out;
			transition: 300ms all ease-out;
			top: rem-calc(30);
			right: rem-calc(20);
			height: rem-calc(2);
			width: rem-calc(10);
		}
		&:before {
			transform: rotate(45deg);
			right: rem-calc(26);
		}
		&:after {
			transform: rotate(-45deg);
		}
		&.active {
			border: 0;
			background-color: $c-ielts-red;
			&:before {
				transform: rotate(-45deg);
			}
			&:after {
				transform: rotate(45deg);
			}
		}
		&:hover {
			cursor: pointer;
		}
	}
	&__content {
		// border: solid $c-kennedy-accordion-border;
		border-width: 0px 1px 1px 1px;
		display: none;
		padding: rem-calc(20);
		> img {
			max-width: 100%;
			height: auto;
			display: block;
		}
		.rte ~ .btn {
 			~ h3 {
				padding-top: 10px;
				margin-bottom: 0;
				@include media-breakpoint-down(sm) {
					padding-top: 20px;
				}
			}
		}
		.rte ~ .btn {
			~ .rte {
				//padding-top: 10px;
				@include media-breakpoint-down(sm) {
					padding-top: 20px;
				}
			}
		}
		.rte > p {
			margin: 0 0 rem-calc(20);
			&:last-child{
				margin-bottom: 0;
			}
		}
		.rte {
			margin-bottom: 0;
			padding: 0;
		}

		.accordion__footer {
			margin-top: rem-calc(30);
		}

		.btn {
			display: inline-block;
			&:hover {
				text-decoration: none;
			}
		}
		.social-media {
			float: right;
			a {
				// color: $c-kennedys-social-grey;
				transition: color 200ms linear;
				&:first-child {
					margin-right: rem-calc(5);
				}				
				&:hover {
					// color: $c-kennedy-btn-hover-black;
					text-decoration: none;
				}
			}
		}
		.icon-text {
			margin-top: 20px;
			margin-bottom: 20px;
			&--item {
				&.message {
					h2 {
						@include font-size(40);
						@include line-height(40);
					}
				}
				&_title {
					@include font-size(40);
					@include line-height(40);
				}
			}
		}
	}
}