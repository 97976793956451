@font-face {
	font-family: 'PFCentroSlabPro_Regular';
	src: url('../fonts/PFCentroSlabPro/PFCentroSlabPro_Regular.eot');
	src: url('../fonts/PFCentroSlabPro/TPFCentroSlabPro_Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PFCentroSlabPro/PFCentroSlabPro_Regular.woff') format('woff'),
		url('../fonts/PFCentroSlabPro/PFCentroSlabPro_Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'PFCentroSlabPro_Medium';
	src: url('../fonts/PFCentroSlabPro/PFCentroSlabPro_Medium.eot');
	src: url('../fonts/PFCentroSlabPro/PFCentroSlabPro_Medium?#iefix') format('embedded-opentype'),
		url('../fonts/PFCentroSlabPro/PFCentroSlabPro_Medium.woff') format('woff'),
		url('../fonts/PFCentroSlabPro/PFCentroSlabPro_Medium.ttf') format('truetype');
	font-weight: medium;
	font-style: normal;
}
