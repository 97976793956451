.pagination {
    justify-content: center;
    margin: rem-calc(30) 0 rem-calc(20);

    ul {
        margin: 0;
        padding: 0;
        li {
            display: inline-block; 
            font-size: rem-calc(16);
            padding: 0 rem-calc(5);
            
            a {
                color: $c-ielts-red;
                font-family: $standardFont;
                
                &:hover,
                &:focus {
                    color: $c-ielts-black;
                }
            }
            
            &.is-hidden {
                display: none;
            }
            &.page-prev,
            &.page-next {
                a{
                    position: relative;
                    &:before {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        display: block;
                        color: $c-ielts-red;
                        font-family: FontAwesome;
                        transition: all .2s ease-in-out;
                    }

                    &:hover,
                    &:focus {
                        &:before {
                            color: $c-ielts-black;
                        }
                    }
                }
            }

            &.page-prev {
                a {
                    padding-left: 30px;
                    &:before {
                        content: '\f053 \f053';
                        left: 0;
                    }
                }
            }

            &.page-next {
                a {
                    padding-right: 30px;
                    &:before {
                        content: '\f054 \f054';
                        right: 0;
                    }
                }
            }

            &.current {
                a {
                    color: $c-ielts-black;
                
                    &:hover,
                    &:focus {
                        color: $c-ielts-red;
                    }
                }
            }
        }
    }
}