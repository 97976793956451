.hero-statement {
    border-bottom: rem-calc(2) solid $c-ielts-border-grey;
    margin-bottom: rem-calc(25);
    padding: rem-calc(30) 0 rem-calc(15);
    
    display: flex;
    flex-direction: row;
    align-items: center;

    h1, h2 {
        padding: 0;
        margin: 0;
    }

    a {
        color: $c-ielts-red;

        &:hover,
        &:focus {
            color: $c-ielts-black;
        }
    }

    &__heading {
        border-right: rem-calc(2) solid $c-ielts-border-grey;
        padding-right: rem-calc(30);
        margin-right: rem-calc(30);
        @include media-breakpoint-down(sm) {
            border-right-width: 0;
            padding-right: 0;
            margin-right: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;

        &__heading {
            border-right-width: 0;
            padding-right: 0;
            margin-right: 0;
            margin-bottom: rem-calc(20);
        }
    }

        // h1 {
        //     border-right: 2px solid $c-ielts-border-grey;
        //     margin-right: 30px;
        // }
        // .col-9 {
        //     text-align: right;
        // }
        // &.search-row {
        //     .text {
        //         text-align: right;
        //     }
        //     border-bottom: none;
        //     margin-bottom: 0px;
        //     padding-bottom: 0px;
        //     .search-button {
        //         @include search-button;
        //         position: relative;
        //         right: 50px;
        //     }
        // }
    // }
    // @include media-breakpoint-down(lg) {
    //     .row {
    //         h1 {
    //             padding-right: 30px;
    //             margin-right: 0;
    //         }
    //     }
    // }
    // @include media-breakpoint-down(md) {
    //     .row {
    //         .col-4 {
    //             flex: 0 0 26.3333%;
    //             max-width: 27%;
    //         }
    //     }
    // }
    // @include media-breakpoint-down(sm) {
    //     .row {
    //         h1, h2 {
    //             font-size: rem-calc(20);
    //             padding-right: 0;
    //         }
    //         .col-4 {
    //             flex: 0 0 26.3333%;
    //             max-width: 33.33333%;
    //         }
    //         .col-8 {
    //             padding: 0;
    //             flex: 1;
    //             max-width: 100%;
    //         }
    //         &.search-row {
    //             .col-6 {
    //                 align-self: flex-start !important;
    //             }
    //         }
    //     }
    // }
    // @include media-breakpoint-down(xs) {
    //     .search-row {
    //         .form-inline {
    //             width: 85%;
    //             .search-button {
    //                 top: 0px;
    //             }
    //         }
    //     }
    // }
}
