.video-playlist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: rem-calc(20) 0; 
    &__highlight {
        background-color: $c-ielts-purple;
        padding: rem-calc(20);
        width: 20%;

        @include media-breakpoint-down(lg) {
            width: 25%;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        
        .player-title {
            border-bottom: rem-calc(1) solid $c-white;
            color: $c-white;
            padding-bottom: rem-calc(15);
        }
    }

    &__container {
        flex: 2;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
}