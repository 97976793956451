.header {
    margin-top: rem-calc(15);
    display: flex;
    flex-direction: column;
    align-content: center;

    > .container {
        @include media-breakpoint-up(lg) {
            max-width: 100%;
            margin: 0;
            padding: 0;
        }
        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: row;
        }

        .header-container {
            @include media-breakpoint-down(md) {
                max-width: 100%;
                margin: 0;
                padding-left: 0;
            }
        }
    }

    @include media-breakpoint-down(md) {
        flex-direction: row;
    }

    .header-container {
        display: flex;
        flex-direction: row;
        @include media-breakpoint-down(md) {
            padding-right: 0;
        }
    }
    .main-logo {
        display: flex;
        flex: 1;

        a {
            display: block;
        }
        img {
            @include media-breakpoint-down(sm) {
                width: rem-calc(120);
            }
        }
    }
    .top-links {
        display: flex;
        justify-content: center;
        align-items: center;

        .top-links-nav {
            display: flex;
            flex-direction: row;
            padding: 0;
            margin: 0;
        }
        
        .nav-item {
            margin: 0;
            border-right: rem-calc(1) solid $c-ielts-black;
            font-size: rem-calc(12);
            display: flex;
            align-items: center;

            @include media-breakpoint-down(sm) {
                display: none;
            }

            &:last-child {
                border-right: 0;
                @include media-breakpoint-down(sm) {
                    display: block;
                }
            }
            
            .nav-link {
                padding: 0;
                margin: 0 rem-calc(12);
            }

            .lang-toggle {
                position: relative;
                img {
                    width: 60%;
                }

                &:after {
                    content: '\f078';
                    position: absolute;
                    font-family: FontAwesome;
                    width: rem-calc(12);
                    height: rem-calc(18);
                    display: block;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}
