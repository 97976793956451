// +-------------------------------------------------------------------------+
// | BASIC                                                                   |
// +-------------------------------------------------------------------------+

$c-white: #FFFFFF;
$c-off-white: #F0F0F0;
$c-black: #0a0a0a;
$c-body: #4a4a4a;


// +-------------------------------------------------------------------------+
// | Flat UI                                                                 |
// +-------------------------------------------------------------------------+
$c-ielts-black: #333333 !default;
$c-ielts-red: #dc0b21 !default;
$c-ielts-border-red: #b9091d !default;
$c-ielts-dark-grey: #cdcdcd !default;
$c-ielts-bg-grey: #f1f2ed !default;
$c-ielts-border-grey: #cecece !default;
$c-ielts-purple: #661357 !default;
$c-ielts-grey: #ccc;
$c-ielts-grey2: #f0f0f0;