.quote {
    margin: rem-calc(10) 0;
    p {
        border-top: 2px solid $c-ielts-purple;
        border-bottom: 2px solid $c-ielts-purple;
        display: inline-block;
        color: $c-ielts-purple;
        padding: 15px 0;
        .fa {
            background: $c-ielts-red;
            border-radius: 50%;
            margin-right: 10px;
            color: $white;
            padding: 8px;
            width: 38px;
            &::before {
                position: relative;
                left: 1px;
            }
        }
    }
    .blockquote-footer {
        text-align: right;
    }
    &.blockquote-il {
        display: inline-block;
        width: 35%;
    }

    @include media-breakpoint-down(sm) {
        &.blockquote-il {
            width: 100%;
            display: block;
        }
    }
}
