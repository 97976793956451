.ot-feature-col-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 0 0 25%;
    flex-wrap: wrap;
    
    @include media-breakpoint-down(sm) {
        flex-direction: column;
        flex: 0 0 100%;
    }

    [class^="col-md-"] {
        @include media-breakpoint-down(lg) { 
            margin-bottom: rem-calc(30);
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: rem-calc(20);
        }
    }
    .feature-item {
        background-color: $c-ielts-purple;
        height: 100%;
        width: 24%;
        
        h2, p, span {
            color: $c-white;
        }
        p {
            font-size: rem-calc(14);
        }

        span.btn {
            padding-left: 0;
            .fa {
                color: $c-white;
                position: relative;
                top: rem-calc(2);
                transition: color .1s ease-in;
            }
        }

        a {
            display: block;
            padding: rem-calc(12) rem-calc(18);
            
            &:hover {
                span.btn {
                    color: $c-ielts-red;
                    .fa {
                        color: $c-ielts-red;
                        
                    }
                }
            }
        }
    }
}