.book-a-test {
    padding-top: rem-calc(30);
    padding-bottom: rem-calc(30);

    .column {
        margin-bottom: 0;
        &:first-child {
            @include media-breakpoint-down(sm) {
                margin-bottom: rem-calc(25);
            }
        }
    }

    .address-title {
        font-weight: bold;
        margin-bottom: 0;
    }

    address {
        ul {
            margin: 0;
            padding: 0;
        }

        li {
            span {
                font-weight: bold;
                width: rem-calc(25);
                display: inline-block;
            }
        }

        a {
            color: $c-ielts-red;
        }
    }

    .fee {
        font-weight: bold;

        span {
            font-size: rem-calc(18);
        }
    }
}