.ot-content-tabs--styled {
    .tabs {
        ul {
            border-bottom-width: 0;
        }
        li {
            margin-bottom: rem-calc(5);
            a {
                background-color: $c-ielts-purple;
                color: $c-white;
                border: rem-calc(1) solid $c-ielts-purple;

                &:hover {
                    background-color: $c-white;
                    color: $c-ielts-purple;
                }
                
                &.is-active {
                    background-color: $c-white;
                    color: $c-ielts-purple;
                }
            }
        }
    }

    .item {
        &:before {
            background-color: $c-ielts-purple;
            color: $c-white;
            border: rem-calc(1) solid $c-ielts-purple;
            margin-bottom: rem-calc(1);
        }

        &:after {
            content: '\f078';
            font-family: FontAwesome;
            position: absolute;
            top: rem-calc(6);
            right: rem-calc(6);
            width: rem-calc(20);
            height: rem-calc(18);
            display: block;
            color: $c-white;
        }
        &.is-active {
            &:before {
                background-color: $c-white;
                color: $c-ielts-purple;
                margin-bottom: rem-calc(12);
            }

            &:after {
                color: $c-ielts-purple;
            }
        }
    }

    &.mobile-accordion {
        .tabs {
            ul {
                flex-direction: column;
                border-bottom-width: 0;

                li {
                    margin-bottom: rem-calc(5);
                    a {
                        background-color: $c-ielts-purple;
                        color: $c-white;
                        border: rem-calc(1) solid $c-ielts-purple;
        
                        &:hover {
                            background-color: $c-white;
                            color: $c-ielts-purple;
                        }
                        
                        &.is-active {
                            background-color: $c-white;
                            color: $c-ielts-purple;
                        }
                    }
                }
            }
        }
        .item {
            &:before {
                background-color: $c-ielts-purple;
            }

            &.is-active {
                &:before {
                    background-color: $c-white;
                    color: $c-ielts-purple;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;
        .tabs {
            margin-right: rem-calc(20);
            ul {
                border-bottom-width: 0;
                flex-direction: column;
            }
    
            li {
                border-right-width: 0;
                margin-bottom: rem-calc(2);
                
                a {
                    font-size: rem-calc(16);
                    &:hover {
                        background-color: $c-white;
                        color: $c-ielts-purple;
                    }
                    
                    &.is-active {
                        &:after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: rem-calc(8) 0 rem-calc(8) rem-calc(8);
                            border-color: transparent transparent transparent $c-ielts-purple;
                            right: rem-calc(-8);
                        }
                    }
                }
            }
        }
    
        .item {
            width: 0;

            &:before,
            &:after {
                display: none;
            }
    
            &.is-active {
                width: 100%;
            }
        }
    }
}