.organisational-search {
    margin-bottom: 30px;
    .form-inline {
        flex: 1;
    }

    a.accordion__title {
        background: transparent;
    }

    &__form {
        margin-right: rem-calc(20);
        position: relative;
        width: 40%;
        @include media-breakpoint-down(sm) {
            margin-bottom: rem-calc(20);
            width: 100%;
        }

        .form-control {
            width: 100%;
        }

        .search-button {
            background: transparent;
            border-radius: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .form-row {
        margin: 0;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }

    .form-group {
        flex: 1;
        margin-right: rem-calc(20);
        @include media-breakpoint-down(sm) {
            margin-right: 0;
            margin-bottom: rem-calc(20);
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .custom-select {
        border-color: $c-ielts-black;
        width: 100%;
    }

    .accordion {
        margin-bottom: 0;
        h3 {
            margin-bottom: rem-calc(20);
        }
    
        &__title {
            background-color: $c-white;
            color: $c-ielts-red;
            box-shadow: none;
            &:before,
            &:after {
                display: none;
            }
            .fa {
                padding: 0 rem-calc(5);
                transition: all .2s linear;
            }
            &.active {
                .fa {
                    transform: rotate(180deg);
                }
            }
        }
        &__content {
            display: none;
            padding-left: 0;
            padding-right: 0;
            width: 100%;
        }
    }

    &__advanced {
        &-type {
            border-top: rem-calc(1) solid $c-ielts-border-grey;
            border-bottom: rem-calc(1) solid $c-ielts-border-grey;
            margin-top: rem-calc(20);
            padding: rem-calc(20) 0;

            .form-row {
                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                }
            }

            .form-check {
                flex: 1;
                align-items: left;
                justify-content: left;
            }

            label {
                display: block;
                font-weight: bold;
            }

            span {
                display: block;
                font-weight: normal;
                margin-left: rem-calc(22);
                @include media-breakpoint-down(sm) {
                    margin-left: 0;
                }
            }
        }

        &-location {
            border-bottom: rem-calc(1) solid $c-ielts-border-grey;
            padding: rem-calc(20) 0;
            margin-bottom: rem-calc(20);
        }
    }

    &__results {
        margin-top: rem-calc(30);
        h3 {
            margin-bottom: rem-calc(20);
        }

        &-filter {
            display: flex;
            margin-bottom: rem-calc(20);

            h3 {
                margin: rem-calc(10) rem-calc(20) 0 0;
                @include media-breakpoint-down(sm) {
                    margin-bottom: rem-calc(20);
                }
            }

            .form-row {
                width: 100%;
            }
        }

        .result-item-header {
            display: flex;
            flex-direction: row;
            background-color: #f1f1ed;
            font-family: 'PFCentroSlabPro_Medium';
            font-size: rem-calc(17);
            padding: rem-calc(15) rem-calc(10);

            @include media-breakpoint-down(sm) {
                display: none;
            }

            .header-text {
                flex: 1;
                margin-right: rem-calc(10);

                &:last-child {
                    margin-right: 0;
                }
            }

            .filter-arrow {
                &:after {
                    content:'\f078';
                    font-family: FontAwesome;
                    color: $c-ielts-red;
                    font-size: rem-calc(12);
                    text-align: center;
                    position: relative;
                    height: rem-calc(20);
                    width: rem-calc(20);
                    display: block;
                    border: rem-calc(1) solid $c-ielts-border-grey;
                    display: inline-block;
                    top: rem-calc(0);
                    margin-left: rem-calc(10);
                }

                &.up {
                    &:after {
                        content:'\f077';
                    }
                }
            }
        }

        .result-item {
            .item-text {
                &[class*=" icon-"] {
                    font-weight: bold;
                    &:before {
                        content:'';
                        position: relative;
                        height: rem-calc(30);
                        width: rem-calc(30);
                        display: inline-block;
                        margin-bottom: rem-calc(-10);
                        margin-right: rem-calc(10);
                    }
                } 
                
                &.icon-education {
                    &:before {
                        background: url('../img/icons/icon-education.png') no-repeat center center;
                        background-size: contain;
                    }
                }
                &.icon-government {
                    &:before {
                        background: url('../img/icons/icon-government.jpg') no-repeat center center;
                        background-size: contain;
                    }
                }
                &.icon-employer {
                    &:before {
                        background: url('../img/icons/icon-employer.png') no-repeat center center;
                        background-size: contain;
                    }
                }
                &.icon-professional-registration {
                    &:before {
                        background: url('../img/icons/icon-professional-registration.jpg') no-repeat center center; 
                        background-size: contain;
                    }
                }

                .band-score {
                    border: rem-calc(1) solid $c-ielts-border-grey;
                    text-align: center;
                    position: relative;
                    height: rem-calc(40);
                    width: rem-calc(50);
                    color: $c-white;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    font-weight: bold;
                    background-color: #8b1a77;

                    &:before {
                        content:'';
                        height: 100%;
                        display: block;
                        height: rem-calc(38);
                        width: rem-calc(48);
                        position: absolute;
                        left: rem-calc(0);
                        top: rem-calc(0);
                        z-index: 0;
                        border: rem-calc(4) solid $c-white;
                        @include media-breakpoint-down(sm) {
                            border-color: $c-ielts-bg-grey;
                        }
                    }
                }
            }
        }
    }
}


.band-score-list {
    margin-bottom: rem-calc(30);
    @include media-breakpoint-down(sm) {
        text-align: center;
    }
    
    &__item {
        text-align: center;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: rem-calc(25);
        &__desc {
            font-weight: bold;
            margin-bottom: 0px;
        }
    }
    .band-score {
        
        position: relative;
        height: rem-calc(130);
        width: rem-calc(130);
        color: $c-white;
        justify-content: center;
        align-items: center;
        display: inline-flex;
        font-size: 60px;
        background-color: #8b1a77;
        margin: 8px;
        &:before {
            content:'';
            border: rem-calc(1) solid $c-ielts-border-grey;
            height: 100%;
            display: block;
            height: rem-calc(146);
            width: rem-calc(146);
            position: absolute;
            left: -8px;
            top: -8px;
            z-index: 0;
            background-color: $c-white;
            z-index: -1;
            @include media-breakpoint-down(sm) {
                border-color: $c-ielts-bg-grey;
            }
        }
    }
}

.mb-60 {
    margin-bottom: rem-calc(60);
}
.mb-30 {
    margin-bottom: rem-calc(30);
}
.mb-15 {
    margin-bottom: rem-calc(15);
}


.btn-default {
    input[type="submit"] {
        background: transparent!important;
    }
}