.document-list {
    display: inline-block;
    margin: rem-calc(20) 0;
    h2 {
        font-size: rem-calc(18);
    }
    ul {
        padding: 0;
        margin: 0;

        li {
            border-bottom: rem-calc(1) solid $c-ielts-dark-grey;
            padding: rem-calc(5) 0;

            &:last-child {
                border-bottom-width: 0;
            }
        }

        a {
            color: $c-ielts-red;
            margin-right: rem-calc(10);

            &:hover,
            &:focus {
                color: $c-ielts-black;
            }

            &:before {
                top: 0;
            }
        }
    }
}