.find-test-location-results {
    &__filter {
        .form-inline {
            flex: 1;
        }
        .form-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between; 
            width: 100%;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }

            h3 {
                margin: rem-calc(10) rem-calc(20) 0 0;
                @include media-breakpoint-down(sm) {
                    margin-bottom: rem-calc(20);
                }
            }

            .form-group {
                flex: 1;
                margin-right: rem-calc(20);

                &:last-child {
                    margin-right: 0;
                }

                @include media-breakpoint-down(sm) {
                    margin-right: 0;
                }
            }
            .custom-select {
                width: 100%;
            }
        }
    }

    .results-table {
        margin-top: rem-calc(30);
        margin-bottom: rem-calc(30);
    }
}
