::selection {
  background: $c-ielts-red; /* WebKit/Blink Browsers */
  color: #fff;
}
::-moz-selection {
  background: $c-ielts-red; /* Gecko Browsers */
  color: #fff;
}
html, body {
  overflow-x: hidden;
}
html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: 'Lato', sans-serif;
  font-size: rem-calc(16);
  letter-spacing: .5px;
  line-height: 1.5;
  color: $c-ielts-black;
  overflow-x: hidden; 
  position: relative;
}
@mixin sansHeader() {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-style: normal;
  color: $c-ielts-black;
  letter-spacing: -0.5px;
}
h1, h2, h3, h4, h5, h6 {
  @include sansHeader();
}

h1 {
  color: $c-ielts-red;
}
strong {
  font-weight: 700;
}
a {
  @include animate;

  &[target="_blank"] {
    padding-right: rem-calc(25);
    position: relative;
    
    &:after {
      content: '\f08e';
      height: rem-calc(12);
      font-family: FontAwesome;
      display: block;
      position: absolute;
      right: rem-calc(2);
      bottom: rem-calc(8);
      width: rem-calc(12);
    }

    &.btn {
      &:after {
        bottom: rem-calc(15);
      }
    }

    &.btn-default {
      &:after {
        display: none;
      }
    }
  }
}

$standardFont: 'Lato', sans-serif;
$PFCentroSlabProRegular: 'PFCentroSlabPro_Regular';
$PFCentroSlabProMedium: 'PFCentroSlabPro_Medium';